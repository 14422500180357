<!DOCTYPE html>
<html
  lang="en"
  xmlns:th="http://www.thymeleaf.org"
  xmlns:span="http://www.w3.org/1999/xhtml"
>
<head>
  <meta charset="UTF-8" />
  <title>D-Portal</title>
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  <style>
    .modal-container {
      max-width: 910px;
      border: 1px solid #aaa;
    }
    .modal-body {
      overflow: hidden !important;
    }
    .modal-container .content {
      overflow-y: hidden;
    }
    .modal-container .content {
      border: 1px solid #bbb;
    }

    #list {
      min-height: 720px;
    }
  </style>
</head>
<body>
<div class="docs-container off-canvas off-canvas-sidebar-show">
  <div class="docs-navbar">
    <a class="off-canvas-toggle btn btn-link btn-action" href="{{page}}#sidebar"
    ><i class="icon icon-menu"></i
    ></a>
  </div>
  <div class="docs-sidebar off-canvas-sidebar" id="sidebar">
    <div class="docs-brand">
      <a class="docs-logo" href="/home">
        <img src="assets/img/letter-d.png" style="width: 24px;" alt="daatguul">
        <label class="label label-secondary text-bold ml-1">PORTAL</label></a>
    </div>
    <menu-root></menu-root>
  </div>
  <a class="off-canvas-overlay" href="{{page}}#close"></a>
  <div class="off-canvas-content">
    <div class="docs-content" id="content">
      <div id="list">
        <h5 class="s-title">Нэгтгэл<a class="anchor" href="{{page}}#list" aria-hidden="true">#</a>
        </h5>
        <div class="docs-demo">

          <div class="columns">
            <div class="column col-3 col-xs-12">
              <div class="card">
                <div class="card-body">
                  <div class="empty text-center">
                    <div class="empty-icon">
                      <i class="icon icon-check"></i>
                    </div>
                    <p class="empty-title h6">Баталгаажсан</p>
                    <h3 class="tile-title  text-success">{{getStatValue(2).count}}</h3>
                    <h5 class="tile-subtitle">{{getStatValue(2).amount | currency : '₮ '}}</h5>
                    <div class="empty-action">
                      <a class="btn btn-primary btn-sm" href="/home#active" (click)="pstatus=2;getInvoiceReportByOrg()">Харах</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="column col-3 col-xs-12">
              <div class="card">
                <div class="card-body">
                  <div class="empty text-center">
                    <div class="empty-icon">
                      <i class="icon icon-refresh"></i>
                    </div>
                    <p class="empty-title h6">Нэхэмжилсэн</p>
                    <h3 class="tile-title text-error">{{getStatValue(3).count}}</h3>
                    <h5 class="tile-subtitle">{{getStatValue(3).amount | currency : '₮ '}}</h5>
                    <div class="empty-action">
                      <a class="btn btn-primary btn-sm" href="/home#active" (click)="pstatus=3;getInvoiceReportByOrg()">Харах</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="column col-3 col-xs-12">
              <div class="card">
                <div class="card-body">
                  <div class="empty text-center">
                    <div class="empty-icon">
                      <i class="icon icon-refresh"></i>
                    </div>
                    <p class="empty-title h6">Хүлээгдэж буй</p>
                    <h3 class="tile-title text-primary">{{getStatValue(1).count}}</h3>
                    <h5 class="tile-subtitle">{{getStatValue(1).amount | currency : '₮ '}}</h5>
                    <div class="empty-action">
                      <a class="btn btn-primary btn-sm" href="/list">Харах</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="column col-3 col-xs-12">
              <div class="card">
                <div class="card-body">
                  <div class="empty text-center">
                    <div class="empty-icon">
                      <i class="icon icon-people"></i>
                    </div>
                    <p class="empty-title h6">Даатгуулагч / Апп</p>
                    <h3 class="tile-title">{{getUserStatValue('ROLE_USER').count}}</h3>
                    <h5 class="tile-subtitle">1</h5>
                    <div class="empty-action">
                      <a class="btn btn-primary btn-sm" href="/user">Харах</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="column col-3 col-xs-12">
              <div class="card">
                <div class="card-body">
                  <div class="empty text-center">
                    <div class="empty-icon">
                      <i class="icon icon-people"></i>
                    </div>
                    <p class="empty-title h6">Хэрэглэгч / Агент</p>
                    <h3 class="tile-title">{{getUserStatValue('ROLE_AGENT').count}}</h3>
                    <h5 class="tile-subtitle">{{getOrgStatValue('AGENT').count}}</h5>
                    <div class="empty-action">
                      <a class="btn btn-primary btn-sm" href="/org">Харах</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br><br>
          <div class="divider" data-content="Үйлчилгээгээр"></div>
          <div class="columns">
            <div class="column col-3 col-xs-12" *ngFor="let service of services">
              <div class="card">
                <div class="card-body">
                  <div class="empty text-center">
                    <div class="empty-icon">
                      <i class="icon icon-menu"></i>
                    </div>
                    <p class="empty-title h6">{{service.name.replace(' даатгал', '')}}</p>
                    <h3 class="tile-title text-success">{{getServiceStatValue(service.id).count}}</h3>
                    <h5 class="tile-subtitle">{{getServiceStatValue(service.id).amount | currency : '₮ '}}</h5>
                    <div class="empty-action">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>

    </div>
  </div>
</div>

<div class="modal modal-lg" id="active">
  <a href="{{page}}#close"
     class="modal-overlay"
     aria-label="Close"></a>
  <div class="modal-container" style="max-width: {{maxWidth}}px !important; width: 100%; height: {{maxHeight}}px;">
    <div class="modal-header">
      <a
        href="{{page}}#close"
        class="btn btn-clear float-right"
        aria-label="Close"
      ></a>
      <div class="modal-title h5">Тооцооны хүснэгт</div>
    </div>

    <div class="modal-body" style="min-height: 370px;">
      <div class="columns">
        <div class="panel column col-12">
          <div class="panel-header text-center">
            <div class="columns">
              <div class="column col-3">
                <div class="input-group">

                  <select class="form-select select-sm" [(ngModel)]="reportType" (change)="getInvoiceReportByOrg()">
                    <option value="1" [ngValue]="1">Хүснэгт</option>
                    <option value="2" [ngValue]="2">График</option>
                  </select>

                  <input class="form-input input-sm" [(ngModel)]="s1" type="date" placeholder="" style="width: 120px;">
                  <input class="form-input input-sm" [(ngModel)]="s2" type="date" placeholder="" style="width: 120px;">
                  <button class="btn btn-primary input-group-btn btn-sm" (click)="getInvoiceReportByOrg()"><i class="icon icon-search"></i></button>
                </div>
              </div>
              <div class="column col-2" *ngIf="reportType == 2">
                <div class="input-group">
                  <span class="input-group-addon addon-sm">Талбар</span>
                  <select class="form-select select-sm" style="width: 90px;" [(ngModel)]="reportField" (change)="getInvoiceReportByOrg()">
                    <option value="f2">Хураамж</option>
                    <option value="f1">Төлөх</option>
                    <option value="u2">Даатгуулагч</option>
                  </select>
                </div>
              </div>

              <div class="column col-2" *ngIf="t0==1 && reportType == 1">
                <div class="input-group">
                  <span class="input-group-addon addon-sm">ДК</span>
                  <select class="form-select select-sm" style="width: 90px;" [(ngModel)]="selOrgId" (change)="getInvoiceReportByOrg()">
                    <option value="0" *ngIf="orgs.length > 1">Бүгд</option>
                    <option *ngFor="let org of orgs" value="{{org.id}}">{{org.name}}</option>
                  </select>
                </div>
              </div>
              <div class="column col-2" *ngIf="t0==1 && reportType == 1 && profile && profile.role == 'ROLE_ADMIN'">
                <div class="input-group">
                  <span class="input-group-addon addon-sm">АГ</span>
                  <select class="form-select select-sm" style="width: 90px;" [(ngModel)]="selAgentId" (change)="getInvoiceReportByOrg()">
                    <option value="0" *ngIf="agents.length > 1">Бүгд</option>
                    <option *ngFor="let agent of agents" value="{{agent.id}}">{{agent.name}}</option>
                  </select>
                </div>
              </div>
              <div class="column col-2">
                <div class="input-group">
                  <span class="input-group-addon addon-sm">Төрөл</span>
                  <select class="form-select select-sm" style="width: 90px;" [(ngModel)]="selectedServiceId" (change)="getInvoiceReportByOrg()">
                    <option value="0" *ngIf="services.length > 1">Бүгд</option>
                    <option *ngFor="let service of services" value="{{service.id}}">{{typeName(service.name)}}</option>
                  </select>
                </div>
              </div>
              <div class="column col-3">
                <div class="columns">
                  <div class="column col-5" *ngIf="reportType==1">
                    <label class="form-switch text-small">
                      <input type="checkbox" [(ngModel)]="t0" (change)="getInvoiceReportByOrg()">
                      <i class="form-icon"></i><span *ngIf="t0==0" class="ml-2 pl-2">Хураангуй</span><span *ngIf="t0==1" class="ml-2 pl-2">Дэлгэрэнгүй</span>
                    </label>
                  </div>
                  <div class="column col-5" *ngIf="t0==1 && reportType==1">
                    <label class="form-switch text-small">
                      <input type="checkbox" [(ngModel)]="t1" (change)="getInvoiceReportByUser()">
                      <i class="form-icon"></i><span *ngIf="t1==0" class="ml-2 pl-2">Нэгтгэл</span><span *ngIf="t1==1" class="ml-2 pl-2">Задаргаа</span>
                    </label>
                  </div>
                  <div class="column col-2 float-right" *ngIf="reportType==1">
                      <button class="btn bt-sm btn-primary btn-group btn-group-block" style="height: 29px;" *ngIf="t1==1 || t0 == 0" (click)="exportXLS()"><i class="icon icon-download"></i></button>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="panel-body text-small" *ngIf="userTab==1" style="height: 630px;">
              <div  style="border:1px solid #eee;min-height:500px; height: 615px; width: 100%; overflow: auto; ">
                <div *ngIf="rloading"
                     class="loading loading-sm float-left"
                     style="margin-top:20%; margin-left: 49%;"></div>

                <table *ngIf="!rloading && reportType == 1" class="table {{t0==1?'table-scroll':''}} table-striped table-hover text-small table-scroll" style="display: initial;">
                <thead class="text-bold">
                  <tr>
                    <td></td>
                    <td *ngIf="t1==1">Бүртгэсэн</td>
                    <td>ДК</td>
                    <td>Төрөл</td>
                    <td *ngIf="t0==1">Агент</td>
                    <td *ngIf="t0==1">Ажилтан</td>
                    <td *ngIf="t0==1">Бүлэг</td>
                    <td *ngIf="t1==1">РД</td>
                    <td *ngIf="t1==1">Даатгуулагч</td>
                    <td *ngIf="t1==1">Баталгааны дугаар</td>
                    <td class="text-right">Гэрээ</td>
                    <td class="text-right">Үнэлгээ/₮/</td>
                    <td class="text-right">Хураамж/₮/</td>
                    <td class="text-right">Хөнгөлөлт/₮/</td>
                    <td class="text-right">Валют/$/</td>
                    <td class="text-right">Төлөх/₮/</td>
                    <td class="text-right" *ngIf="profile && profile.role=='ROLE_ADMIN' && t0==0">Шимтгэл</td>
                    <td class="text-right" *ngIf="profile && profile.role=='ROLE_ADMIN' && t0==0">Шимтгэл дүн</td>
                    <td class="text-center" *ngIf="t0==1">Өдөр</td>
                    <td class="text-center" *ngIf="t0==1">Эхлэх</td>
                    <td class="text-center" *ngIf="t0==1">Дуусах</td>
                    <td class="text-center" *ngIf="t0==1">Ханш/₮/</td>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let reps of invoiceReps;let i = index;">
                    <td>{{i+1}}</td>
                    <td *ngIf="t1==1">{{reps.createdDate | date:'yyyy-MM-dd'}}</td>
                    <td>{{reps.orgName.replace('даатгал', '')}}</td>
                    <td>{{typeName(reps.serviceName)}}</td>
                    <td *ngIf="t0==1">{{reps.agentName}}</td>
                    <td *ngIf="t0==1">{{reps.userName}}</td>
                    <td *ngIf="t0==1" style="text-overflow-ellipsis: scroll;">{{reps.groupName}}</td>
                    <td *ngIf="t1==1">{{reps.regNo}}</td>
                    <td *ngIf="t1==1" style="text-overflow-ellipsis: scroll;">{{reps.firstName+' '+reps.lastName}}</td>
                    <td *ngIf="t1==1">{{reps.invoiceNo}}</td>
                    <td class="text-right">{{reps.u2}}</td>
                    <td class="text-right">{{reps.coverage| number:'1.2-2'}}</td>
                    <td class="text-right">{{reps.a2 | number:'1.2-2'}}</td>
                    <td class="text-right">{{reps.a2-reps.f2 | number:'1.2-2'}}</td>
                    <td class="text-right">{{reps.d2 | number:'1.2-2'}}</td>
                    <td class="text-right">{{reps.f2 | number:'1.2-2'}}</td>
                    <td class="text-right" *ngIf="profile && profile.role=='ROLE_ADMIN' && t0==0">{{reps.percent}}%</td>
                    <td class="text-right" *ngIf="profile && profile.role=='ROLE_ADMIN' && t0==0">{{reps.f2 * reps.percent / 100 | number:'1.2-2'}}</td>
                    <td class="text-right" *ngIf="t0==1">{{reps.d}}</td>
                    <td class="text-center" *ngIf="t0==1">{{reps.start}}</td>
                    <td class="text-center" *ngIf="t0==1">{{reps.endd}}</td>
                    <td class="text-center" *ngIf="t0==1">{{reps.rate | number:'1.2-2'}}</td>
                  </tr>
                </tbody>
                <tfoot class="text-bold">
                    <tr>
                      <td></td>
                      <td *ngIf="t1==1"></td>
                      <td></td>
                      <td></td>
                      <td *ngIf="t0==1"></td>
                      <td *ngIf="t0==1"></td>
                      <td *ngIf="t0==1"></td>
                      <td *ngIf="t1==1"></td>
                      <td *ngIf="t1==1"></td>
                      <td *ngIf="t1==1"></td>
                      <td class="text-right">{{getSumOf(invoiceReps, 'u2')}}</td>
                      <td class="text-right">{{getSumOf(invoiceReps, 'coverage')| number:'1.2-2'}}</td>
                      <td class="text-right">{{getSumOf(invoiceReps, 'a2') | number:'1.2-2'}}</td>
                      <td class="text-right">{{(getSumOf(invoiceReps, 'a2') - getSumOf(invoiceReps, 'f2')) | number:'1.2-2'}}</td>
                      <td class="text-right">{{getSumOf(invoiceReps, 'd2') | number:'1.2-2'}}</td>
                      <td class="text-right">{{getSumOf(invoiceReps, 'f2') | number:'1.2-2'}}</td>
                      <td class="text-right" *ngIf="profile && profile.role=='ROLE_ADMIN' && t0==0"></td>
                      <td class="text-right" *ngIf="profile && profile.role=='ROLE_ADMIN' && t0==0">{{getSumOfPercent(invoiceReps, 'f2') | number:'1.2-2'}}</td>
                    </tr>
                </tfoot>
              </table>
                <div class="divider"></div>
                <div id="main" style="display: {{reportType == 2 ? 'block;':'none;'}} width: {{maxWidth*0.8}}px;height:450px;"></div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

</body>
</html>

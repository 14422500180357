<!DOCTYPE html>
<html
  lang="en"
  xmlns:th="http://www.thymeleaf.org"
  xmlns:span="http://www.w3.org/1999/xhtml"
>
  <head>
    <meta charset="UTF-8" />
    <title>D-Portal</title>
    <meta name="viewport" content="width=device-width, initial-scale=1" />
  </head>
  <body>
    <div class="docs-container off-canvas off-canvas-sidebar-show">
      <div class="docs-navbar">
        <a class="off-canvas-toggle btn btn-link btn-action" href="{{page}}#sidebar"
          ><i class="icon icon-menu"></i
        ></a>
      </div>
      <div class="docs-sidebar off-canvas-sidebar" id="sidebar">
        <div class="docs-brand">
          <a class="docs-logo" href="/home">
            <img src="assets/img/letter-d.png" style="width: 24px;" alt="daatguul">
            <label class="label label-secondary text-bold ml-1">PORTAL</label></a>
        </div>
        <menu-root></menu-root>
      </div>
      <a class="off-canvas-overlay" href="{{page}}#close"></a>
      <div class="off-canvas-content">
        <div class="docs-content" id="content">
          <h5 class="s-title">
            Нөхөн төлбөр<a class="anchor" href="{{page}}#forms" aria-hidden="true">#</a>
          </h5>

          <div class="docs-demo columns">
            <div class="column col-12 col-sm-3">
              <div class="columns">
              <label  class="chip {{service.id == selServiceId ? 'active':''}} float-left pl-2 pr-2"
                        (click)="selServiceId = (service.id); getRefunds();" *ngFor="let service of services" >
                  {{service.name.replace(' даатгал', '')}} </label>
                <div class="divider"></div>
                <div
                  class="loading loading-sm ml-2 pl-2"
                  *ngIf="loading"></div>

                <table class="table table-striped table-hover text-small" *ngIf="!loading">
                <thead>
                  <th style="width: 30%">
                    <span *ngIf="!loading">#</span>
                  </th>
                  <th class="text-center text-small" *ngFor="let org of orgs">{{org.name.replace(' даатгал', '')}}</th>
                </thead>
                <tbody *ngFor="let type of types">
                  <tr>
                    <td colspan="12" class="text-bold text-small">{{type}}</td>
                  </tr>
                  <tr *ngFor="let attach of getRefundOrg(type); let i = index;">
                    <td class="text-small">{{(i+1)+'. '+attach.docName}}</td>
                    <td class="text-center text-bold" *ngFor="let org of orgs">{{getRefundValue(org.id, attach.docName, type)}}</td>
                  </tr>
                </tbody>
              </table>
              <br><br><br><br><br><br><br><br>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </body>
</html>

<!DOCTYPE html>
<html
  lang="en"
  xmlns:th="http://www.thymeleaf.org"
  xmlns:span="http://www.w3.org/1999/xhtml"
>
  <head>
    <meta charset="UTF-8" />
    <title>qrly.in</title>
    <meta name="viewport" content="width=device-width, initial-scale=1" />
  </head>
  <body>
    <div class="docs-container off-canvas off-canvas-sidebar-show">
      <div class="docs-sidebar off-canvas-sidebar" id="sidebar">
        <div class="docs-brand">
          <a class="docs-logo" href="/home">
            <img src="assets/img/letter-d.png" style="width: 24px;" alt="daatguul">
            <label class="label label-secondary text-bold ml-1">PORTAL</label></a>
        </div>
        <menu-root></menu-root>
      </div>
      <a class="off-canvas-overlay" href="{{page}}#close"></a>
      <div class="off-canvas-content">
        <div class="docs-content" id="content">
          <div  id="forms">

            <h5 class="s-title">
              Байгууллага бүртгэх<a class="anchor" href="{{page}}#forms" aria-hidden="true"
            >#</a
            >
            </h5>
            <br>
            <label class="chip text-small float-left pl-2 pr-2 {{otype == 2 ? 'active':''}}" (click)="getOrgList(2)">Даатгал</label>
            <label class="chip text-small float-left pl-2 pr-2 {{otype == 3 ? 'active':''}}" (click)="getOrgList(3)">Агент</label>

            <table class="table table-striped table-hover text-small">
              <thead class="text-small text-dark">
              <tr>
                <th style="width: 3%;" class="text-right">
                  <span *ngIf="!oloading">#</span>
                  <div  *ngIf="oloading"
                        class="loading loading-sm float-left"
                        style="margin-top: 5px; margin-left: 20px;"></div>
                </th>
                <th style="width: 6%">РД</th>
                <th style="width: 10%">Нэр</th>
                <th style="width: 7%">Утас</th>
                <th style="width: 12%">Имейл 1</th>
                <th style="width: 12%">Имейл 2</th>
                <th style="width: 10%">Вэб</th>
                <th style="width: 10%">Мессенжер</th>
                <th style="width: 16%">Хаяг</th>
<!--                <th style="width: 10%">Бүртгэсэн</th>-->
                <th style="width: 9%;">Үйлдэл</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let org of orgs; let i = index;" style="vertical-align: top;" class="text-break">
                <td class="text-right">
                  {{i+1}}.
                </td>
                <td><span *ngIf="org.status != 2">{{org.regno}}</span>
                  <input type="text" class="form-input input-sm" *ngIf="org.status==2" [(ngModel)]="org.regno" toUppercase/>
                </td>
                <td><span *ngIf="org.status != 2">{{org.name}}</span>
                  <input type="text" class="form-input input-sm" *ngIf="org.status==2" [(ngModel)]="org.name"  toUppercase/>
                </td>
                <td><span *ngIf="org.status != 2">{{org.phone}}</span>
                  <input type="text" class="form-input input-sm" *ngIf="org.status==2" [(ngModel)]="org.phone" />
                </td>
                <td><span *ngIf="org.status != 2">{{org.email}}</span>
                  <input type="text" class="form-input input-sm" *ngIf="org.status==2" [(ngModel)]="org.email"  />
                </td>
                <td><span *ngIf="org.status != 2">{{org.apiMail}}</span>
                  <input type="text" class="form-input input-sm" *ngIf="org.status==2" [(ngModel)]="org.apiMail"  />
                </td>
                <td><span *ngIf="org.status != 2">{{org.web}}</span>
                  <input type="text" class="form-input input-sm" *ngIf="org.status==2" [(ngModel)]="org.web"  />
                </td>
                <td><span *ngIf="org.status != 2">{{org.msgr}}</span>
                  <input type="text" class="form-input input-sm" *ngIf="org.status==2" [(ngModel)]="org.msgr"  />
                </td>
                <td><span *ngIf="org.status != 2">{{org.address}}</span>
                  <input type="text" class="form-input input-sm" *ngIf="org.status==2" [(ngModel)]="org.address"  />
                </td>
<!--                <td>{{org.created_date | dateAsAgo}}</td>-->
                <td>
                  <button class="btn btn-sm btn-primary" (click)="editOrg(org)"><i class="icon {{org.status != 2 ? 'icon-edit':'icon-check'}}"></i></button>
                  <button class="btn btn-sm btn-error ml-2" *ngIf="org.status == 2" (click)="cancelOrg(org)"><i class="icon icon-cross"></i></button>
                  <button class="btn btn-sm btn-error ml-2" (click)="deleteOrg(org)"><i class="icon icon-delete"></i></button>

                </td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <td class="text-center text-bold">+</td>
                <td><input type="text" class="form-input input-sm" [(ngModel)]="org.regno" placeholder="Регистр" toUppercase/></td>
                <td><input type="text" class="form-input input-sm" [(ngModel)]="org.name" placeholder="Нэр" toUppercase/></td>
                <td><input type="phone" class="form-input input-sm" [(ngModel)]="org.phone" placeholder="Утас" toUppercase/></td>
                <td><input type="email" class="form-input input-sm" [(ngModel)]="org.email" placeholder="Цахим шуудан"/></td>
                <td><input type="text" class="form-input input-sm" [(ngModel)]="org.web" placeholder="Веб хуудас"/></td>
                <td><input type="text" class="form-input input-sm" [(ngModel)]="org.msgr" placeholder="Мессенжер"/></td>
                <td><input type="text" class="form-input input-sm" [(ngModel)]="org.address" placeholder="Хаяг"/></td>
                <td>
                  <button class="btn btn-sm btn-primary float-left" (click)="registerOrg()"><i class="icon icon-check"></i></button>
                  <div *ngIf="gloading">
                    <div
                      class="loading loading-sm float-left"
                      style="margin: 5px; margin-left: 20px;"></div>
                  </div>
                </td>
              </tr>
              </tfoot>
            </table>

          </div>

        </div>
      </div>
    </div>
  </body>
</html>

import {NgModule, Input, CUSTOM_ELEMENTS_SCHEMA, enableProdMode} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule} from "@angular/common";
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MenuComponent } from './menu.component';
import { CreateComponent } from './create.component';
import { RefundComponent } from './refund.component';
import {ListComponent} from './list.component';
import { ActiveComponent } from './active.component';
import { InActiveComponent } from './inactive.component';
import { RouterModule } from '@angular/router';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import {HomeComponent} from "./home.component";
import {LoginComponent} from "./login.component";
import {UpperCaseInputDirective} from "./upper.app.component";
import {UserComponent} from "./user.component";
import {DateAsAgoPipe} from "./date.ago";
import {OrgComponent} from "./org.component";
import {ProfileComponent} from "./profile.component";
import {ResetComponent} from "./reset.component";
import {CallbackPipe} from "./CallbackPipe";
import {InvoiceComponent} from "./invoice.component";
import { SafeUrlPipe } from './safe-url.pipe';

enableProdMode();

@NgModule({
    declarations: [
        SafeUrlPipe,
        HomeComponent,
        LoginComponent,
        AppComponent,
        MenuComponent,
        CreateComponent,
        ListComponent,
        RefundComponent,
        ActiveComponent,
        InActiveComponent,
        InvoiceComponent,
        UserComponent,
        OrgComponent,
        ProfileComponent,
        ResetComponent,
        UpperCaseInputDirective,
        DateAsAgoPipe,
        CallbackPipe
    ],
  imports: [
    PdfViewerModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule.forRoot([
      {path: '', component: LoginComponent},
      {path: 'home', component: HomeComponent},
      {path: 'create', component: CreateComponent},
      {path: 'list', component: ListComponent},
      {path: 'active', component: ActiveComponent},
      {path: 'inactive', component: InActiveComponent},
      {path: 'refund', component: RefundComponent},
      {path: 'invoice', component: InvoiceComponent},
      {path: 'user', component: UserComponent},
      {path: 'org', component: OrgComponent},
      {path: 'me', component: ProfileComponent},
      {path: 'reset', component: ResetComponent}
    ]),
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
    // ToastrModule added
  ],
    exports: [
        AppComponent,
        ListComponent,
        CallbackPipe
    ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule {}

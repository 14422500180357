import { Component } from '@angular/core';
import {AppComponent} from "./app.component";

@Component({
  selector: 'menu-root',
  templateUrl: './menu.component.html',
})
export class MenuComponent extends AppComponent {
  ngOnInit() {
    this.page = '';
    this.getProfile();

    this.route.url.subscribe((params) => {
      this.page = (params[0].path);
    });
  }
}

<div class="docs-nav collapse">
  <label class="label label-rounded columns column col-12" *ngIf="profile">
    <figure class="avatar bg-secondary">
      <i class="icon icon-people m-2 float-left text-primary"></i>
      <i class="avatar-presence online"></i>
    </figure>&nbsp;
    <span class="tile-subtitle text-small text-primary float-left text-bold m-1 ml-2 pt-1">{{profile.lastname.substring(0,1)+'.'+profile.firstname}}</span>
  </label>
  <label class="label label-rounded columns column col-12" *ngIf="!profile">
    <figure class="avatar bg-secondary">
      <i class="icon icon-time m-2 float-left text-primary"></i>
      <i class="avatar-presence away"></i>
    </figure>&nbsp;
    <span class="tile-subtitle text-small text-primary float-left text-bold m-1 ml-2 pt-1">Хэрэглэгч</span>
  </label>
  <br>
  <div class="accordion-container text-small">
    <div *ngIf="logged()" class="accordion">
      <input
        id="accordion-elements"
        type="checkbox"
        name="docs-accordion-checkbox"
        hidden=""
        checked="checked"
      />
      <label class="accordion-header c-hand" for="accordion-elements">Үйлчилгээ</label>
      <div class="accordion-body">
        <ul class="menu menu-nav">
          <li class="menu-item {{page == 'home' ? 'label label-rounded text-bold columns column col-12':''}}"><a href="/home">Нүүр хуудас</a></li>
          <li class="menu-item {{page == 'user' ? 'label label-rounded text-bold columns column col-12':''}}"><a href="/user">Даатгуулагч</a></li>
          <li class="menu-item {{page == 'refund' ? 'label label-rounded text-bold columns column col-12':''}}"><a href="/refund">Нөхөн төлбөр</a></li>
          <li class="menu-item {{page == 'list' ? 'label label-rounded text-bold columns column col-12':''}}"><a href="/list">Хүлээгдэж буй</a></li>
          <li class="menu-item {{page == 'invoice' ? 'label label-rounded text-bold columns column col-12':''}}"><a href="/invoice">Нэхэмжилсэн</a></li>
          <li class="menu-item {{page == 'active' ? 'label label-rounded text-bold columns column col-12':''}}"><a href="/active">Баталгаажсан</a></li>
          <li class="menu-item {{page == 'inactive' ? 'label label-rounded text-bold columns column col-12':''}}"><a href="/inactive">Цуцласан</a></li>
        </ul>
      </div>
    </div>
    <div *ngIf="logged()" class="accordion">
      <input
        id="accordion-components"
        type="checkbox"
        name="docs-accordion-checkbox"
        checked="checked"
        hidden=""
      />
      <label class="accordion-header c-hand" for="accordion-components"
        >Тохиргоо</label
      >
      <div class="accordion-body">
        <ul class="menu menu-nav">
          <li class="menu-item {{page == 'me' ? 'label label-rounded columns column col-10':''}}">
            <a href="/me">Тохиргоо</a>
          </li>
          <li *ngIf="profile && profile.role == 'ROLE_ADMIN'" class="menu-item {{page == 'org' ? 'label label-rounded text-bold columns column col-12':''}}">
            <a href="/org">Байгууллага</a>
          </li>
          <li *ngIf="profile && profile.role == 'ROLE_ADMIN'" class="menu-item {{page == 'create' ? 'label label-rounded text-bold columns column col-12':''}}">
            <a href="/create">Хэрэглэгчид</a>
          </li>
          <li class="menu-item {{page == '' ? 'label label-rounded text-bold columns column col-12':''}}">
            <a
              href="/"
              (click)="logout()">Гарах</a>
          </li>
        </ul>
      </div>
    </div>

  </div>
</div>

<div style="position:fixed; bottom:10px; left: 25px; padding: 20px; white-space: pre-wrap; width: 240px;" *ngIf="profile && profile.role == 'ROLE_AGENT'">
  <div class="float-left">
    <i class="icon icon-location text-primary"></i>&nbsp;
  </div>
  <div class="float-left">
  <div class="tile-title text-gray">{{profile.orgDto.name}}</div>
    <span class="tile-subtitle text-gray text-small">Агент</span>
  </div>
</div>

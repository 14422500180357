<!DOCTYPE html>
<html
  lang="en"
  xmlns:th="http://www.thymeleaf.org"
  xmlns:span="http://www.w3.org/1999/xhtml"
>
  <head>
    <meta charset="UTF-8" />
    <title>D-Portal</title>
    <meta name="viewport" content="width=device-width, initial-scale=1" />
  </head>
  <body>
    <div class="docs-container off-canvas off-canvas-sidebar-show">
      <div class="docs-navbar">
        <a class="off-canvas-toggle btn btn-link btn-action" href="{{page}}#sidebar"
          ><i class="icon icon-menu"></i
        ></a>
      </div>
      <div class="docs-sidebar off-canvas-sidebar" id="sidebar">
        <div class="docs-brand">
          <a class="docs-logo" href="/home">
            <img src="assets/img/letter-d.png" style="width: 24px;" alt="daatguul">
            <label class="label label-secondary text-bold ml-1">PORTAL</label></a>
        </div>
        <menu-root></menu-root>
      </div>
      <a class="off-canvas-overlay" href="{{page}}#close"></a>
      <div class="off-canvas-content">
        <div class="docs-content" id="content">
          <h5 class="s-title">
            Системийн тохиргоо<a class="anchor" href="{{page}}#forms" aria-hidden="true">#</a>
          </h5>

          <div class="docs-demo columns">
            <div class="column col-3 col-sm-3">
              <h6 class="s-title">
                Бүртгэл шинэчлэх<a class="anchor" href="{{page}}#forms" aria-hidden="true">#</a>
              </h6>

              <form class="form-horizontal" action="{{page}}#forms">
                <div class="form-group">
                  <label class="form-label label-sm" for="email">Хэрэглэгчийн нэр</label>
                  <input [ngModelOptions]="{ standalone: true }" class="form-input input-sm" type="text" id="email" #email="ngModel" [(ngModel)]="profile.email" disabled autocomplete="off" placeholder="Хэрэглэгчийн нэр">
                </div>
                <div class="form-group">
                  <label class="form-label label-sm" for="phone">Утас</label>
                  <input [ngModelOptions]="{ standalone: true }"  class="form-input input-sm"
                         #password="ngModel" type="phone" id="phone" [maxlength]="8" [minlength]="8"
                         [(ngModel)]="profile.phone" autocomplete="off" placeholder="Утас">
                </div>
                <div class="form-group">
                  <label class="form-label label-sm" for="rd">Регистр дугаар</label>
                  <input [ngModelOptions]="{ standalone: true }"  class="form-input input-sm"
                         #password="ngModel" type="text" id="rd"
                         [(ngModel)]="profile.registerno" autocomplete="off" placeholder="Регистр дугаар" toUppercase>
                </div>
                <div class="form-group">
                  <label class="form-label label-sm" for="lastname">Эцэг/эх нэр</label>
                  <input [ngModelOptions]="{ standalone: true }"  class="form-input input-sm"
                         #password="ngModel" type="text" id="lastname"
                         [(ngModel)]="profile.lastname" autocomplete="off" placeholder="Эцэг/эх нэр" toUppercase>
                </div>
                <div class="form-group">
                  <label class="form-label label-sm" for="firstname">Нэр</label>
                  <input [ngModelOptions]="{ standalone: true }"  class="form-input input-sm"
                         #password="ngModel" type="text" id="firstname"
                         [(ngModel)]="profile.firstname" autocomplete="off" placeholder="Нэр" toUppercase>
                </div>
                <div class="form-group">
                </div>
                <div class="form-group">
                  <button
                    class="btn btn-primary btn-sm float-left"
                    type="submit"
                    (click)="confirmUser()">
                    Хадгалах
                  </button>
                  &nbsp;&nbsp;
                  <a href="/reset" class="text-small btn btn-sm btn-link float-right">Нууц үг солих</a>

                  <div
                    style="margin-left: 20px;"
                    class="loading loading-sm"
                    *ngIf="login"></div>
                </div>
              </form>
            </div>
            <div class="column col-1"></div>
            <div class="column col-4">

              <h6 class="s-title">
                Хөнгөлөлт тохиргоо<a class="anchor" href="{{page}}#forms" aria-hidden="true">#</a>
              </h6>
              <div class="columns">
                <div class="column col-6 input-group" *ngIf="profile.role == 'ROLE_ADMIN'">
                  <span class="input-group-addon addon-sm">Компани</span>
                  <select class="form-select select-sm" (change)="getDiscount()" [(ngModel)]="selOrgId">
                    <option *ngFor="let org of orgs; let i = index;" [ngValue]="org.id">{{org.name}}</option>
                  </select>
                </div>
                <div class="column col-6 input-group" *ngIf="profile.role == 'ROLE_ADMIN'">
                  <span class="input-group-addon addon-sm">Төрөл</span>
                  <select class="form-select select-sm" (change)="getDiscount()" [(ngModel)]="selServiceId">
                    <option *ngFor="let service of services; let i = index;" [ngValue]="service.id">{{service.name}}</option>
                  </select>
                </div>
              </div>

              <table class="table table-striped table-hover text-small">
                <thead>
                  <th style="width: 5%">
                    <span *ngIf="!loading">#</span>
                    <div
                      class="loading loading-sm"
                      *ngIf="loading"></div>
                  </th>
                  <th style="width: 25%" class="text-center">Бага утга</th>
                  <th style="width: 25%" class="text-center">Их утга</th>
                  <th style="width: 25%" class="text-center">Хөнгөлөлт</th>
                  <th style="width: 20%">Үйлдэл</th>
                </thead>
                <tbody>
                  <tr *ngFor="let discount of discounts; let i = index;">
                    <td class="text-right">{{i+1}}</td>
                    <td class="text-bold text-center">{{discount.countStart}}</td>
                    <td class="text-bold text-center">{{discount.countEnd}}</td>
                    <td class="text-bold text-center">{{discount.percent}}%</td>
                    <td>
                      <button class="btn btn-sm btn-error" (click)="deleteDiscount(discount)"><i class="icon icon-delete"></i></button>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td></td>
                    <td><input type="number" class="form-input input-sm text-center" [(ngModel)]="discount.countStart" placeholder="Бага утга"/></td>
                    <td><input type="number" class="form-input input-sm text-center" [(ngModel)]="discount.countEnd" placeholder="Их утга"/></td>
                    <td><input type="number" class="form-input input-sm text-center" [(ngModel)]="discount.percent" placeholder="Хувь"/></td>
                    <td>
                      <button class="btn btn-sm btn-primary float-left" (click)="saveDiscount()"><i class="icon icon-check"></i></button>
                      <div *ngIf="gloading">
                        <div
                          class="loading loading-sm float-left"
                          style="margin: 5px; margin-left: 20px;"></div>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

  </body>
</html>

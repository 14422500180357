import {Component, OnInit, ViewChild} from '@angular/core';
import { AppComponent } from './app.component';
import { ActivatedRoute } from '@angular/router';
import {HttpClient, HttpEventType, HttpHeaders, HttpResponse} from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {Observable} from "rxjs";
import {UserComponent} from "./user.component";
import {CreateComponent} from "./create.component";

@Component({
  selector: 'create-root',
  templateUrl: './org.component.html',
  styleUrls: ['./app.component.css'],
})
export class OrgComponent extends UserComponent implements OnInit {
  public form: any;
  public oloading: any;
  public otype: any = 2;
  public org: any;
  override ngOnInit() {
    this.form = new FormGroup({
    });

    this.page = 'org';

    this.initOrg();
    this.getOrgList(this.otype);
  }

  initOrg() {
    this.org = {
      email: '',
      phone: '',
      name: '',
      regno: '',
      web: '',
      address: '',
      msgr: '',
      type: this.otype
    }
  }

  editOrg(org: any) {
    if (org.status == 2) {
      this.confirmOrg(org);
      return;
    }
    org.status = 2;
  }

  cancelOrg(org: any) {
    org.status = 1;
  }

  confirmOrg(org: any) {
    this.oloading = true;
    const url = 'p/org/update';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    })
    this.http.post(url, org,{headers: headers}).subscribe((res) => {
      this.oloading = false;
      this.getOrgList(this.otype);
    }, error => {
      this.oloading = false;
      this.toastr.error(error.statusText, 'Алдаа !');
    });
  }

  getOrgList(otype:any) {
    this.oloading = true;
    this.otype = otype;

    this.orgs = [];
    const url = 'p/orgList/'+otype;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+this.token
    });

    this.http.get(url, {headers: headers}).subscribe((res) => {
      this.orgs = res;
      this.oloading = false;
    }, error => {
      this.oloading = false;
      this.toastr.error(error.statusText, 'Алдаа !');
    });
  }

  registerOrg() {
    this.oloading = true;
    const url = 'p/org/save';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    })
    this.org.type = this.otype;
    this.http.post(url, this.org,{headers: headers}).subscribe((res) => {
      this.oloading = false;
      if (this.org.id == -1) {
        this.toastr.error('Бүртгэгдсэн байна !', 'Алдаа !');
      }
      this.initOrg();
      this.getOrgList(this.otype);
    }, error => {
      this.oloading = false;
      this.toastr.error(error.statusText, 'Алдаа !');
    });
  }

  deleteOrg(org: any) {
    if (confirm('Устгах уу ?')) {
      this.oloading = true;
      const url = 'p/org/delete/' + org.id;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get(url, {headers: headers}).subscribe((res) => {
        this.getOrgList(this.otype);
      }, error => {
        this.oloading = false;
        this.toastr.error(error.statusText, 'Алдаа !');
      });
    }
  }
}

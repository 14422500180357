<!DOCTYPE html>
<html
  lang="en"
  xmlns:th="http://www.thymeleaf.org"
  xmlns:span="http://www.w3.org/1999/xhtml"
>
  <head>
    <meta charset="UTF-8" />
    <title>D-Portal</title>
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <style>
      .modal-container {
        max-width: 895px;
        border: 1px solid #aaa;
      }

      .modal-container .content {
        border: 1px solid #eee;
      }
      #list {
        min-height: 720px;
      }
    </style>
  </head>
  <body>
    <div class="docs-container off-canvas off-canvas-sidebar-show mt-2">
      <a class="off-canvas-overlay mt-2 mb-2" href="{{page}}#close"></a>
      <div class="off-canvas-content mt-2">
        <div class="docs-content mt-2" id="content">
          <div class="docs-brand mt-2 ml-2" style="position: inherit !important;">
            <br>
            <a class="docs-logo" href="/home">
              <img src="assets/img/letter-d.png" alt="daatguul">
              <label class="label label-secondary text-large text-bold ml-2">PORTAL</label>
            </a>
          </div>
          <div class="docs-demo columns ml-2 mt-2">
            <div class="column col-4 col-xs-11">
              <h5 class="s-title mt-2">
                Системд нэвтрэх<a class="anchor" href="{{page}}#forms" aria-hidden="true">#</a>
              </h5>
              <form class="form-horizontal" action="{{page}}#forms">
                <div class="form-group">
                  <label class="form-label label-sm" for="username">Хэрэглэгчийн нэр</label>
                  <input [ngModelOptions]="{ standalone: true }" class="form-input" type="text" id="username" #email="ngModel" [(ngModel)]="user.email" autocomplete="off" placeholder="Хэрэглэгчийн нэр">
                </div>
                <div class="form-group">
                  <label class="form-label label-sm" for="password">Нууц үг</label>
                  <input [ngModelOptions]="{ standalone: true }"  class="form-input"
                         #password="ngModel" type="password" id="password"
                         [(ngModel)]="user.password" autocomplete="off" placeholder="Нууц үг"
                        (submit)="getToken()">
                </div>
                <div class="form-group">
                  <a href="{{page}}#" class="text-small" (click)="resetPassword()">Нууц үг сэргээх</a>
                </div>
                <div class="form-group">
                </div>
                <div class="form-group">
                    <button
                      class="btn btn-primary float-left"
                      type="submit"
                      [disabled]="login"
                      (click)="getToken()">
                      Нэвтрэх
                    </button>
                    <div
                      style="margin-left: 20px;"
                      class="loading loading-sm"
                      *ngIf="login"></div>
                </div>
              </form>
            </div>
          </div>

        </div>
      </div>
    </div>
  </body>
</html>

import { Component } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { AppComponent } from './app.component';
import {HomeComponent} from "./home.component";

@Component({
  selector: 'login-root',
  templateUrl: './login.component.html',
  styleUrls: ['./app.component.css'],
})
export class LoginComponent extends AppComponent {
  ngOnInit() {
    this.page = '';
    this.token = localStorage.getItem("token");
    if (this.token) {
      const url = 'p/orgList/1';
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })
      this.http.get(url, {headers: headers}).subscribe((res) => {
        location.href = "/list";
      }, error => {
        this.logout();
      });
    }
  }

  resetPassword() {
    if (this.user.email.length == 0) {
      this.toastr.error('Цахим шуудан оруулаагүй байна !', 'Ok');
      return;
    }
    if (confirm('Цахим шуудан луу шинэ нууц үгийг илгээх үү ?')) {
          const url = 'p/v1/auth/reset';
          this.loading = true;
          this.http.post(url, {
            email: this.user.email
          }).subscribe((res) => {
            this.toastr.error('Нууц үгийг таны цахим шууданд илгээссэн !', 'Ok');
            this.loading = false;
          }, error => {
            this.loading = false;
            this.toastr.error(error.statusText, 'Алдаа !');
          });
    }
  }
}

<!DOCTYPE html>
<html
  lang="en"
  xmlns:th="http://www.thymeleaf.org"
  xmlns:span="http://www.w3.org/1999/xhtml"
>
  <head>
    <meta charset="UTF-8" />
    <title>D-Portal</title>
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <style>
      #list {
        min-height: 730px;
      }
    </style>
  </head>
  <body>
    <div class="docs-container off-canvas off-canvas-sidebar-show">
      <div class="docs-navbar">
        <a class="off-canvas-toggle btn btn-link btn-action" href="{{page}}#sidebar"
          ><i class="icon icon-menu"></i
        ></a>
      </div>
      <div class="docs-sidebar off-canvas-sidebar" id="sidebar">
        <div class="docs-brand">
          <a class="docs-logo" href="/home">
              <img src="assets/img/letter-d.png" style="width: 24px;" alt="daatguul">
              <label class="label label-secondary text-bold ml-1">PORTAL</label></a>
        </div>
        <menu-root></menu-root>
      </div>
      <a class="off-canvas-overlay" href="{{page}}#close"></a>
      <div class="off-canvas-content">
        <div class="docs-content" id="content">
          <div id="list">
            <h5 class="s-title">Хүлээгдэж буй<a class="anchor" href="{{page}}#list" aria-hidden="true">#</a></h5>
            <div class="docs-demo columns pt-0">
              <div class="column col-12">
                <div class="filter">
                  <input *ngFor="let org of orgs; let i = index;" type="radio" id="tag-{{i}}" class="filter-tag" name="filter-radio" hidden checked="{{i == selOrgId}}">
                  <div class="columns">
                    <div class="column col-9 col-xs-12">
                      <div class="filter-nav">

                        <button class="btn btn-sm btn-primary" (click)="listModeChange(listMode==1?2:1)"><i class="icon {{listMode == 1 ? 'icon-menu':'icon-apps'}}"></i></button>
                        &nbsp;
                        <label class="chip p-2 {{selOrgId == 0 ? 'active':''}}" (click)="setOrgId(0)">&nbsp;Бүгд&nbsp;</label>

                        <label class="chip p-2 {{selOrgId == org.id ? 'active':''}}"  *ngFor="let org of orgs; let i = index;" (click)="setOrgId(org.id)">&nbsp;{{org.name.replace(' даатгал', '')}}&nbsp;</label>
                      </div>
                    </div>

                    <div class="column col-3 col-xs-12" *ngIf="profile && profile.role == 'ROLE_ADMIN'">
                      <div class="input-group mt-2"><span class="input-group-addon addon-sm">Агент</span>
                        <select class="form-select select-sm" [(ngModel)]="selAgentId" (change)="getInvoices(pageNum)">
                          <option value="0">Бүгд</option>
                          <option *ngFor="let agent of agents" value="{{agent.id}}">{{agent.name}}</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="filter-body columns" *ngIf="listMode == 1">
                    <div class="column col-1 m-2" *ngIf="invoiceLoading">
                      <div
                        class="loading loading-sm float-left">
                      </div>
                    </div>

                    <div class="clearfix"></div>
                    <div class="clearfix"></div>

                    <div  class="column col-3 col-xs-6 filter-item" *ngFor="let invoice of invoices.invoiceDtoList" [attr.data-tag]="'tag-'+invoice.orgId">
                        <div class="card s-rounded" (click)="viewInvoice(invoice)">
                          <div class="card-header">
                            <div class="card-title">
                              <label class="label label-secondary label-rounded">
                                <i class="icon icon-people" style="font-size:10px;"></i>&nbsp;
                                <span class="text-bold" style="font-size:12px;">{{invoice.unit}} хүн</span>
                              </label>&nbsp;
                              <label class="label label-secondary label-rounded">
                                <i class="icon icon-time" style="font-size:10px;"></i>&nbsp;
                                <span class="text-bold" style="font-size:12px;">{{(invoice.endDate - invoice.startDate) / (24 * 3600 * 1000)}} өдөр</span>
                              </label>
                            </div>
                          </div>
                          <div class="card-body">
                            <div class="card-title text-bold text-small">{{invoice.orgName}}</div>
                            <div class="card-title text-gray text-small">{{invoice.serviceName}}</div>
                            <div class="card-subtitle text-bold text-large">{{invoice.finalAmount | currency:'₮'}}</div>
                            <div class="card-title text-gray text-small float-left">{{invoice.createdDate | date:'yyyy-mm-dd HH:mm'}}</div>
                          </div>
                          <div class="card-footer pt-2">
                            <button class="btn btn-error btn-sm ml-1" (click)="deleteInvoice(invoice)"><i class="icon icon-delete"></i></button>
                            <a href="{{page}}#{{modal}}" class="btn btn-primary ml-2 btn-sm" (click)="viewInvoice(invoice)"><i class="icon icon-apps"></i></a>
                          </div>
                        </div>
                      </div>

                  </div>

                  <div class="filter-body" *ngIf="listMode == 2">
                    <div class="column col-1 m-2" *ngIf="invoiceLoading">
                      <div
                        class="loading loading-sm float-left">
                      </div>
                    </div>
                    <table class="table table-scroll table-striped table-hover text-small" *ngIf="!invoiceLoading">
                      <thead class="text-small text-dark">
                      <tr>
                        <th style="width: 3%;" class="text-center">
                          <span>#</span>
                        </th>
                        <th style="width: 3%;">Үйлдэл</th>
                        <th style="width: 10%">Компани</th>
                        <th style="width: 19%">Групп</th>
                        <th style="width: 8%">Тоо/өдөр</th>
                        <th style="width: 12%">Даатгал</th>
                        <th style="width: 12%" class="text-right">Хурааамж</th>
                        <th style="width: 12%" class="text-right">Валют(Ханш)</th>
                        <th style="width: 15%" *ngIf="viewMode == 1">Агент</th>
                        <th style="width: 9%">Бүртгэсэн</th>
                      </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let invoice of invoices.invoiceDtoList; let i=index;">
                          <td class="text-right">{{(pageNum*10)+i+1}}.</td>
                          <td>
                            <a href="{{page}}#{{modal}}" class="btn btn-primary btn-sm" (click)="viewInvoice(invoice)"><i class="icon icon-apps"></i></a>
                            &nbsp;
                            <button class="btn btn-sm btn-error" (click)="deleteInvoice(invoice)"><i class="icon icon-delete"></i></button>
                          </td>
                          <td>{{invoice.orgName}}</td>
                          <td>{{invoice.groupId == 0 && invoice.serviceId == 1 ? '-':invoice.groupName}}
                            <span *ngIf="invoice.discountValue > 0">({{invoice.discountValue}}%)</span>
                          </td>
                          <td>{{invoice.unit}}/{{invoice.days}}</td>
                          <td>{{serviceShortName(invoice.serviceName)}}</td>
                          <td class="text-right">
                            <label class="label label-rounded {{invoice.finalAmount != invoice.amount ? 'label-warning':'label-secondary'}}">{{invoice.finalAmount | currency : '₮ '}}</label>
                          </td>
                          <td class="text-right">
                            <b>{{invoice.amountUSD | currency : '$ '}}</b> ({{invoice.rate | currency : '₮ '}})
                          </td>
                          <td *ngIf="viewMode == 1">{{invoice.agentName}}</td>
                          <td>{{invoice.createdDate | date:'yyyy-MM-dd HH:mm'}}</td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="6"></td>
                          <td class="text-right"><label class="label label-rounded label-primary text-bold">
                            {{invoices.amounts | currency : '₮ '}}</label></td>
                          <td></td>
                          <td colspan="3" class="text-right text-gray">Өнөөдрийн МБ ханш : <span class="text-bold">{{rate.value | currency:'₮ '}}</span></td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            <select class="form-select select-sm" style="width: 50px;" (change)="getInvoices(pageNum)" [(ngModel)]="pageNum">
                              <option *ngFor="let item of pages" value="{{item.page-1}}">{{item.page}}</option>
                            </select>
                          </td>
                          <td colspan="6">
                            <select class="form-select select-sm" style="width: 120px;" [(ngModel)]="viewMode">
                              <option value="1">Дэлгэрэнгүй</option>
                              <option value="2">Хураангуй</option>
                            </select>
                          </td>
                          <td class="text-bold text-right" colspan="2">Нийт : {{invoices.rows}} мөр</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="modal modal-lg" id="{{modal}}">
            <a
              href="{{page}}#close"
              class="modal-overlay"
              aria-label="Close"
            ></a>
            <div class="modal-container">
              <div class="modal-header p-0">
                <a
                  href="{{page}}#close"
                  class="btn btn-clear float-right mt-2"
                  aria-label="Close"
                ></a>
                <div class="modal-title h5 mt-2">Даатгалын бүртгэл</div>
              </div>
              <div class="modal-body p-0">
                <div class="columns">
                  <div class="column col-12 col-xs-12">
                    <div class="filter-nav">
                      <div class="input-group mt-2" *ngIf="listMode == 1" >
                        <span class="input-group-addon addon-sm" *ngIf="!gloading">Төрөл
                        </span>

                        <div class="input-group-addon addon-sm" *ngIf="gloading">
                          <div
                            class="loading float-left"
                            style="margin: 5px; margin-left: 15px;">
                          </div>
                        </div>

                        <select class="form-select select-sm" id="selectedServiceIds" [(ngModel)]="selectedServiceId" (change)="createInvoice(selectedServiceId)">
                          <option *ngFor="let service of services" value="{{service.id}}">{{service.name.replace(' даатгал', '')}}</option>
                        </select>

                        <div class="input-group input-group-btn" *ngIf="!this.loading && !this.payLoading && !paylinks.qr">
                          <button class="form-input btn btn-sm" (click)="prevPage()"> << </button>
                          <button class="form-input btn btn-primary btn-sm" (click)="nextPage()"> >> </button>
                        </div>
                      </div>

                      <div *ngIf="listMode == 2">
                        <div class="float-right input-group input-group-btn" *ngIf="!this.loading && !this.payLoading && !paylinks.qr">
                          <button class="form-input btn btn-sm" (click)="prevPage()"> << </button>
                          <button class="form-input btn btn-primary btn-sm" (click)="nextPage()"> >> </button>
                        </div>

                        <div *ngIf="listMode == 2">
                          <label  class="chip {{service.id == newInvoice.serviceId ? 'active':''}} float-left pl-2 pr-2"
                               (click)="createInvoice(service.id); prod = service.prod" *ngFor="let service of services" >
                          {{service.name.replace(' даатгал', '')}} <span class="title label-sm" *ngIf="service.id == newInvoice.serviceId && prod == 0"> (туршилт)</span> </label>

                          <div *ngIf="gloading" class="float-left ml-2">
                            <div
                              class="loading float-left"
                              style="margin: 5px; margin-left: 15px;">
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>


                  </div>
                </div>

                <div class="columns">
                  <div *ngIf="pageId == 1 && (listMode == 2 || (listMode == 1 && tabIndex == 1))" class="column col-{{selService.wizard}} col-xs-12">
                    <div class="card" *ngIf="selService.id == 1" style="height: {{modalHeight}}px;">
                      <div class="card-header">
                        <ul class="tab tab-block">
                          <li class="tab-item text-small {{selRegion == 'EU' ? 'active':''}}">
                            <a href="{{page}}#{{modal}}" (click)="selRegion='EU'">Шенген</a>
                          </li>
                          <li class="tab-item text-small {{selRegion == 'UCJ' ? 'active':''}}">
                            <a href="{{page}}#{{modal}}" (click)="selRegion='UCJ'">АНУ,Канад,Япон</a>
                          </li>
                          <li class="tab-item text-small {{selRegion == 'AZI' ? 'active':''}}">
                            <a href="{{page}}#{{modal}}" (click)="selRegion='AZI'">Ази</a>
                          </li>
                          <li class="tab-item text-small {{selRegion == 'WW' ? 'active':''}}">
                            <a href="{{page}}#{{modal}}" (click)="selRegion='WW'">Бусад</a>
                          </li>
                        </ul>
                      </div>
                      <div class="card-body">
                        <div class="input-group"><span class="input-group-addon addon-sm">Хайх утга</span>
                          <input class="form-input input-sm" [(ngModel)]="searchCountry" type="text" placeholder=""/>
                        </div>

                        <div style="margin-top: 10px; overflow-y: hidden; max-height: 500px;">
                          <span *ngFor="let country of countries">
                            <label class="chip float-left mb-2" *ngIf="country.region == selRegion && (!searchCountry || (searchCountry && searchCountry.length > 0 && country.name.toLowerCase().startsWith(searchCountry.toLowerCase()))) && !isselectedCountry(country)">{{country.name}}
                              <a href="{{page}}#{{modal}}" (click)="selCountries(country)" class="btn btn-clear btn-add" aria-label="" role="button"></a>
                            </label>
                          </span>
                        </div>
                      </div>
                      <div class="card-footer">
                        <span *ngFor="let country of newInvoice.countries">
                          <span class="chip bg-primary">
                            {{country.name}}
                            <a href="{{page}}#{{modal}}" (click)="removeCountries(country)" class="btn btn-clear" aria-label="" role="button"></a>
                          </span>
                        </span>

                        <div class="float-right" *ngIf="listMode == 1">
                          <button class="btn btn-sm {{tabIndex == 1 ? 'btn-primary':''}}" (click)="tabIndex = 1;"> 1 </button>
                          <button class="btn btn-sm ml-1 {{tabIndex == 2 ? 'btn-primary':''}}" (click)="tabIndex = 2;"> 2 </button>
                        </div>
                      </div>
                    </div>

                    <div class="card" *ngIf="selService.id == 2" style="height: 680px;">
                      <div class="card-body">
                        <div class="divider" data-content="Хөрөнгийн мэдээлэл"></div>
                        <div class="columns">
                          <div class="column col-6 input-group">
                            <span class="input-group-addon addon-sm" style="width: 95px;">Гэрчилгээ:</span>
                            <input class="form-input input-sm" type="text" value="" toUppercase placeholder="YXXXXXXXXXX" [(ngModel)]="propNo">
                            <button class="btn btn-primary input-group-btn btn-sm" style="width: 30px;" (click)="searchProperty()"><i class="icon icon-arrow-right"></i></button>
                          </div>
                          <div class="column col-6 input-group">
                            <span class="input-group-addon addon-sm" style="width: 110px;">Ашиглалтанд:</span>
                            <input class="form-input input-sm" type="date" value="" [(ngModel)]="properties[0].propYear">
                          </div>
                          <div class="column col-6 input-group">
                            <span class="input-group-addon addon-sm" style="width: 100px;">Тайлбай m2:</span>
                            <input class="form-input input-sm" type="number" value="" [(ngModel)]="properties[0].size">
                          </div>
                          <div class="column col-6 input-group">
                            <span class="input-group-addon addon-sm" style="width: 70px;">Төрөл:</span>
                            <select class="form-select input-group select-sm" [(ngModel)]="properties[0].cat">
                                <option value="1" [ngValue]="1">Орон сууц</option>
                                <option value="2" [ngValue]="2">Барилга байгууламж</option>
                                <option value="3" [ngValue]="3">Хашаа байшин</option>
                                <option value="4" [ngValue]="4">Тусгай объект</option>
                            </select>
                          </div>
                          <div class="column col-6 input-group">
                            <span class="input-group-addon addon-sm" style="width: 110px;">Аймаг/дүүрэг:</span>
<!--                            <input class="form-input input-sm" type="text" value="" [(ngModel)]="properties[0].off">-->
                            <select (change)="getRefrences(1, properties[0].off)" class="form-select input-group select-sm" [(ngModel)]="properties[0].off">
                              <option
                                *ngFor="let aimag of refs[0]"
                                [ngValue]="aimag.code">
                                {{ aimag.name }}
                              </option>
                            </select>
                          </div>
                          <div class="column col-6 input-group">
                            <span class="input-group-addon addon-sm" style="width: 100px;">Сум/хороо:</span>
                            <select class="form-select input-group select-sm" style="width: 100%;" [(ngModel)]="properties[0].sub">
                              <option
                                *ngFor="let sum of refs[1]"
                                [ngValue]="sum.code"
                              >
                                {{ sum.name }}
                              </option>
                            </select>
                          </div>
                          <div class="column col-12 input-group" *ngIf="properties[0].cat == '1' || properties[0].cat == '3'">
                            <span class="input-group-addon addon-sm" style="width: 140px;">Хотхон, хороолол:</span>
                            <input class="form-input input-sm" type="text" value="" [(ngModel)]="properties[0].town">
                          </div>
                          <div class="column col-6 input-group" *ngIf="properties[0].cat == '1' || properties[0].cat == '3'">
                            <span class="input-group-addon addon-sm" style="width: 120px;">Байр, гудам №:</span>
                            <input class="form-input input-sm" type="text" value="" [(ngModel)]="properties[0].apart">
                          </div>
                          <div class="column col-6 input-group" *ngIf="properties[0].cat == '1' || properties[0].cat == '3'">
                            <span class="input-group-addon addon-sm" style="width: 110px;">Хаалганы №:</span>
                            <input class="form-input input-sm" type="text" value="" [(ngModel)]="properties[0].number">
                          </div>
                        </div>

                        <div class="divider" data-content="Даатгуулагчын мэдээлэл"></div>

                        <div class="columns" *ngFor="let owner of owners">
                          <div class="column col-6 input-group">
                            <span class="input-group-addon addon-sm" style="width: 80px;">РД*:</span>
                            <input class="form-input input-sm" type="text" value="" [(ngModel)]="owner.rd" toUppercase/>
                          </div>
                          <div class="column col-6 input-group">
                            <span class="input-group-addon addon-sm" style="width: 80px;">Овог*:</span>
                            <input class="form-input input-sm" type="text" value="" [(ngModel)]="owner.ln" toUppercase/>
                          </div>
                          <div class="column col-6 input-group">
                            <span class="input-group-addon addon-sm" style="width: 80px;">Нэр*:</span>
                            <input class="form-input input-sm" type="text" value="" [(ngModel)]="owner.fn" toUppercase/>
                          </div>
                          <div class="column col-6 input-group">
                            <span class="input-group-addon addon-sm" style="width: 80px;">Утас*:</span>
                            <input class="form-input input-sm" type="text" value="" [(ngModel)]="owner.ph" toUppercase/>
                          </div>
                          <div class="column col-6 input-group">
                            <span class="input-group-addon addon-sm" style="width: 80px;">Имэйл:</span>
                            <input class="form-input input-sm" type="text" value="" [(ngModel)]="owner.em" toUppercase/>
                          </div>
                          <div class="column col-6 input-group">
                            <span class="input-group-addon addon-sm" style="width: 90px;">Эзэмшигч:</span>
                            <select class="form-select input-group select-sm" [(ngModel)]="owner.ownerType">
                              <option value="1" [ngValue]="1">Тийм</option>
                              <option value="2" [ngValue]="2">Үгүй</option>
                            </select>
                          </div>

                        </div>

                      </div>
                      <div class="card-footer">

                      </div>
                    </div>

                    <div class="card" *ngIf="selService.id == 3" style="height: {{modalHeight}}px;">
                      <div class="card-header">
                          <ul class="tab tab-block">
                            <li class="tab-item text-small {{selItemType == 'A1' ? 'active':''}}">
                              <a href="{{page}}#{{modal}}" (click)="selItemType='A1'; resetRegion();">Тээврийн хэрэгсэл</a>
                            </li>
                            <li class="tab-item text-small {{selItemType == 'A2' ? 'active':''}}">
                              <a href="{{page}}#{{modal}}" (click)="selItemType='A2'; resetRegion();">Хүнд даацын</a>
                            </li>
                            <li class="tab-item text-small {{selItemType == 'A3' ? 'active':''}}">
                              <a href="{{page}}#{{modal}}" (click)="selItemType='A3'; resetRegion();">Машин механизм</a>
                            </li>
                          </ul>
                      </div>
                      <div class="card-body" style="overflow-y: scroll; scrollbar-width: none;">
                        <div class="columns">
                          <div class="column col-12" *ngIf="this.selItemType == 'A1'">
                            <div class="text-small">
                              <b>Замын хөдөлгөөнд оролцох үеийн эрсдэл</b><br>
                              -Мөргөлдөх, шүргэлдэх, мөргүүлэх, шүргүүлэх, онхолдох<br>
                              -Аливаа биет зүйл, хүн, мал мөргөх, шүргэх, шүргүүлэх, мөргүүлэх<br>
                              -Элдэв биетэд өртөх, цохиулах<br>
                              <b>Байгалийн эрсдэл</b><br>
                              -Цасан болон шороон шуурга<br>
                              -Аадар бороо, мөндөр, үер<br>
                              -Газар хөдлөлт, Аянга, цахилгаан, хөрсний нуралт, гулгалт, цөмрөлт<br>
                              <b>Зогсоолын эрсдэл</b><br>
                              -Зогсоол талбайд байх үедээ мөргүүлэх, шүргүүлэх<br>
                              -Элдэв биетэд цохиулах, дээрээс юм унах,<br>
                              -Сантехникийн гэмтлээс үүссэн гэмтэл, барилгын нуралт, цөмрөлт<br>
                              - Гуравдагч этгээдийн санаатай санамсаргүй үйлдэл<br>
                              <b>Жолоо шилжүүлэх эрсдэл</b><br>
                              -Зөвшөөрөгдсөн этгээд жолоодох үеийн эрсдэл<br>
                              <b>Галын эрсдэл</b><br>
                              -Гал, түймэр<br>
                              -Дэлбэрэлт<br>
                              <b>Хулгай дээрмийн эрсдэл</b><br>
                              -Бүхэлд нь хулгайд алдах<br>
                            </div>
                          </div>
                          <div class="column col-12" *ngIf="this.selItemType == 'A2'">
                            <div class="text-small">
                              <b>Авто замын тээвэрлэлтэнд ашиглах тусгай зориулалтын бүх төрлийн тээврийн хэрэгсэл буюу (D, Е) ангиллын болон 5.000 кг-аас их даацтай (C) ангиллын чиргүүлтэй бүрэлдэхүүн (хүнд даацын ачааны автомашин, хагас чиргүүл, чиргүүл)</b>
                              <br><br>
                              <b>Ажил үүргээ гүйцэтгэж байх үеийн эрсдэл</b><br>
                              -Зам тээврийн хөдөлгөөнд оролцох осол<br>
                              -Ажлын талбайд ажил үүргээ гүйцэтгэж байх үеийн эрсдэл /хүнд даацын тээврийн хэрэгслийг ашиглаж байх үед гарах онхолдох, мөргөлдөх, биет зүйл мөргөх/<br>
                              -Байгалийн гамшиг<br>
                              -Гал түймэр, дэлбэрэлт, тэсрэлт<br>
                              <b>Автограж, зогсоол, талбайд хадгалагдах үеийн эрсдэл</b><br>
                              -Гадны бүх төрлийн эх үүсвэрээс үүдэлтэй гал түймэр<br>
                              -Тэсрэлт дэлбэрэлт<br>
                              -Зогсоол талбай гаражид байх үеийн барилгын нуралт, элдэв зүйл дээрээс унах<br>
                              -Байгалийн эрсдэл<br>
                              <b>Гуравдагч этгээдийн САНААТАЙ, САНАМСАРГҮЙ үйлдэл</b><br>
                              <b>ХУЛГАЙН ЭРСДЭЛ</b><br>
                            </div>
                          </div>
                          <div class="column col-12" *ngIf="this.selItemType == 'A3'">
                            <div class="text-small">
                              <b>(М) ангилалын өөрөө явдаг машин механизм, тэдгээрийн чиргүүл буюу ажлын тоног төхөөрөмж</b><br><br>
                              <b>Ажил үүргээ гүйцэтгэж байх үеийн эрсдэл</b><br>
                              -Зам тээврийн хөдөлгөөнд оролцох осол<br>
                              -Ажлын талбайд ажиллаж байх үеийн эрсдэл /онхолдох, мөргөлдөх, шүргэлцэх, биет зүйл мөргөх, шарвах гулсах, чулуу шороо үсрэх, элдэв биет зүйлд цохиулах/<br>
                              -Байгалийн гамшиг<br>
                              -Гал түймэр, дэлбэрэлт, тэсрэлт<br>
                              <b>Автограж, зогсоол, талбайд хадгалагдах үеийн эрсдэл</b><br>
                              -Гадны бүх төрлийн эх үүсвэрээс үүдэлтэй гал түймэр<br>
                              -Тэсрэлт дэлбэрэлт<br>
                              -Зогсоол талбай гаражид байх үеийн барилгын нуралт, элдэв зүйл дээрээс унах<br>
                              -Байгалийн эрсдэл<br>
                              <b>Гуравдагч этгээдийн САНААТАЙ, САНАМСАРГҮЙ үйлдэл</b><br>
                              <b>ХУЛГАЙН ЭРСДЭЛ</b><br>
                              АЧИЖ ЗӨӨВӨРЛӨХ ҮЕИЙН ЭРСДЭЛ<br>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer">
                        <div class="float-right" *ngIf="listMode == 1">
                          <button class="btn btn-sm {{tabIndex == 1 ? 'btn-primary':''}}" (click)="tabIndex = 1;"> 1 </button>
                          <button class="btn btn-sm ml-1 {{tabIndex == 2 ? 'btn-primary':''}}" (click)="tabIndex = 2;"> 2 </button>
                        </div>
                      </div>
                    </div>

                    <div class="card" *ngIf="selService.id == 4" style="height: 680px;">
                      <div class="card-header">
                        <ul class="tab tab-block">
                          <li class="tab-item text-small {{selItemType == 'J1' ? 'active':''}}">
                            <a href="{{page}}#{{modal}}" (click)="selItemType='J1'; resetRegion();">Иргэн</a>
                          </li>
                          <li class="tab-item text-small {{selItemType == 'J2' ? 'active':''}}">
                            <a href="{{page}}#{{modal}}" (click)="selItemType='J2'; resetRegion();">Байгууллага</a>
                          </li>
                          <li class="tab-item text-small {{selItemType == 'J3' ? 'active':''}}">
                            <a href="{{page}}#{{modal}}" (click)="selItemType='J3'; resetRegion();">Мэргэшсэн С,Д</a>
                          </li>
                        </ul>
                      </div>
                      <div class="card-body">
                        <div class="columns">
                          <div class="column col-12">
                            <div class="text-small">
                              <b>Тээврийн хэрэгслийн ангилал  Даатгалын үнэлгээ буюу нэг удаа олгох нөхөн төлбөрийн дээд хэмжээ</b>
                              <br><br>
                              - “A” ангиллын тээврийн хэрэгсэл <b>5 сая</b> хүртэл<br>
                              - “B” ангиллын тээврийн хэрэгсэл <b>5 сая</b> хүртэл<br>
                              - “C” ангиллын тээврийн хэрэгсэл <b>10 сая</b> хүртэл<br>
                              - “D” ангиллын тээврийн хэрэгсэл <b>10 сая</b> хүртэл<br>
                              - “Mеханизм” ангиллын тээврийн хэрэгсэл <b>5 сая</b> хүртэл<br>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer">

                      </div>
                    </div>


                    <div class="card" *ngIf="selService.id == 3" style="height: {{modalHeight}}px;">
                      <div class="card-header">
                        <ul class="tab tab-block">
                          <li class="tab-item text-small {{selItemType == 'A1' ? 'active':''}}">
                            <a href="{{page}}#{{modal}}" (click)="selItemType='A1'; resetRegion();">Тээврийн хэрэгсэл</a>
                          </li>
                          <li class="tab-item text-small {{selItemType == 'A2' ? 'active':''}}">
                            <a href="{{page}}#{{modal}}" (click)="selItemType='A2'; resetRegion();">Хүнд даацын</a>
                          </li>
                          <li class="tab-item text-small {{selItemType == 'A3' ? 'active':''}}">
                            <a href="{{page}}#{{modal}}" (click)="selItemType='A3'; resetRegion();">Машин механизм</a>
                          </li>
                        </ul>
                      </div>
                      <div class="card-body" style="overflow-y: scroll; scrollbar-width: none;">
                        <div class="columns">
                          <div class="column col-12" *ngIf="this.selItemType == 'A1'">
                            <div class="text-small">
                              <b>Замын хөдөлгөөнд оролцох үеийн эрсдэл</b><br>
                              -Мөргөлдөх, шүргэлдэх, мөргүүлэх, шүргүүлэх, онхолдох<br>
                              -Аливаа биет зүйл, хүн, мал мөргөх, шүргэх, шүргүүлэх, мөргүүлэх<br>
                              -Элдэв биетэд өртөх, цохиулах<br>
                              <b>Байгалийн эрсдэл</b><br>
                              -Цасан болон шороон шуурга<br>
                              -Аадар бороо, мөндөр, үер<br>
                              -Газар хөдлөлт, Аянга, цахилгаан, хөрсний нуралт, гулгалт, цөмрөлт<br>
                              <b>Зогсоолын эрсдэл</b><br>
                              -Зогсоол талбайд байх үедээ мөргүүлэх, шүргүүлэх<br>
                              -Элдэв биетэд цохиулах, дээрээс юм унах,<br>
                              -Сантехникийн гэмтлээс үүссэн гэмтэл, барилгын нуралт, цөмрөлт<br>
                              - Гуравдагч этгээдийн санаатай санамсаргүй үйлдэл<br>
                              <b>Жолоо шилжүүлэх эрсдэл</b><br>
                              -Зөвшөөрөгдсөн этгээд жолоодох үеийн эрсдэл<br>
                              <b>Галын эрсдэл</b><br>
                              -Гал, түймэр<br>
                              -Дэлбэрэлт<br>
                              <b>Хулгай дээрмийн эрсдэл</b><br>
                              -Бүхэлд нь хулгайд алдах<br>
                            </div>
                          </div>
                          <div class="column col-12" *ngIf="this.selItemType == 'A2'">
                            <div class="text-small">
                              <b>Авто замын тээвэрлэлтэнд ашиглах тусгай зориулалтын бүх төрлийн тээврийн хэрэгсэл буюу (D, Е) ангиллын болон 5.000 кг-аас их даацтай (C) ангиллын чиргүүлтэй бүрэлдэхүүн (хүнд даацын ачааны автомашин, хагас чиргүүл, чиргүүл)</b>
                              <br><br>
                              <b>Ажил үүргээ гүйцэтгэж байх үеийн эрсдэл</b><br>
                              -Зам тээврийн хөдөлгөөнд оролцох осол<br>
                              -Ажлын талбайд ажил үүргээ гүйцэтгэж байх үеийн эрсдэл /хүнд даацын тээврийн хэрэгслийг ашиглаж байх үед гарах онхолдох, мөргөлдөх, биет зүйл мөргөх/<br>
                              -Байгалийн гамшиг<br>
                              -Гал түймэр, дэлбэрэлт, тэсрэлт<br>
                              <b>Автограж, зогсоол, талбайд хадгалагдах үеийн эрсдэл</b><br>
                              -Гадны бүх төрлийн эх үүсвэрээс үүдэлтэй гал түймэр<br>
                              -Тэсрэлт дэлбэрэлт<br>
                              -Зогсоол талбай гаражид байх үеийн барилгын нуралт, элдэв зүйл дээрээс унах<br>
                              -Байгалийн эрсдэл<br>
                              <b>Гуравдагч этгээдийн САНААТАЙ, САНАМСАРГҮЙ үйлдэл</b><br>
                              <b>ХУЛГАЙН ЭРСДЭЛ</b><br>
                            </div>
                          </div>
                          <div class="column col-12" *ngIf="this.selItemType == 'A3'">
                            <div class="text-small">
                              <b>(М) ангилалын өөрөө явдаг машин механизм, тэдгээрийн чиргүүл буюу ажлын тоног төхөөрөмж</b><br><br>
                              <b>Ажил үүргээ гүйцэтгэж байх үеийн эрсдэл</b><br>
                              -Зам тээврийн хөдөлгөөнд оролцох осол<br>
                              -Ажлын талбайд ажиллаж байх үеийн эрсдэл /онхолдох, мөргөлдөх, шүргэлцэх, биет зүйл мөргөх, шарвах гулсах, чулуу шороо үсрэх, элдэв биет зүйлд цохиулах/<br>
                              -Байгалийн гамшиг<br>
                              -Гал түймэр, дэлбэрэлт, тэсрэлт<br>
                              <b>Автограж, зогсоол, талбайд хадгалагдах үеийн эрсдэл</b><br>
                              -Гадны бүх төрлийн эх үүсвэрээс үүдэлтэй гал түймэр<br>
                              -Тэсрэлт дэлбэрэлт<br>
                              -Зогсоол талбай гаражид байх үеийн барилгын нуралт, элдэв зүйл дээрээс унах<br>
                              -Байгалийн эрсдэл<br>
                              <b>Гуравдагч этгээдийн САНААТАЙ, САНАМСАРГҮЙ үйлдэл</b><br>
                              <b>ХУЛГАЙН ЭРСДЭЛ</b><br>
                              АЧИЖ ЗӨӨВӨРЛӨХ ҮЕИЙН ЭРСДЭЛ<br>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer">
                        <div class="float-right" *ngIf="listMode == 1">
                          <button class="btn btn-sm {{tabIndex == 1 ? 'btn-primary':''}}" (click)="tabIndex = 1;"> 1 </button>
                          <button class="btn btn-sm ml-1 {{tabIndex == 2 ? 'btn-primary':''}}" (click)="tabIndex = 2;"> 2 </button>
                        </div>
                      </div>
                    </div>

                    <div class="card" *ngIf="selService.id == 5" style="height: {{modalHeight}}px;">
                      <div class="card-header">
                        <ul class="tab tab-block">
                          <li class="tab-item text-small {{selItemType == 'A1' ? 'active':''}}">
                            <a href="{{page}}#{{modal}}" (click)="selItemType='A1'; resetRegion();">Дотоод аялагч</a>
                          </li>
                          <li class="tab-item text-small {{selItemType == 'A2' ? 'active':''}}">
                            <a href="{{page}}#{{modal}}" (click)="selItemType='A2'; resetRegion();">Жуулчин</a>
                          </li>
                        </ul>
                      </div>
                      <div class="card-body" style="overflow-y: scroll; scrollbar-width: none;">
                        <div class="columns">
                          <div class="column col-12" *ngIf="this.selItemType == 'A1'">
                            <div class="text-small">
                              <b>Байгалийн эрсдэл</b><br>
                              -Үерийн аюул, аянга цахилгаан, хүчтэй салхи, шороон болон цасан шуурга, мөндөр, ой хээрийн түймэр, газар хөдлөлт, хөрсний нуралт, гулгалт, цөмрөлт<br>
                              <b>Зам тээврийн осол</b><br>
                              -Тээврийн хэрэгслээр зорчиж байх үед гарсан зам тээврийн осол, аваар<br>
                              <b>Мал амьтаны нөлөөллөөр үүсэх эрсдэл</b><br>
                              -Мал амьтанд хазуулах, өшиглүүлэх, мөргүүлэх<br>
                              <b>Гуравдагч этгээдийн нөлөөллөөр үүсэх эрсдэл</b><br>
                              -Бусдад хорлогдох, гэмт халтлагад өртөх, зодуулах, хутга мэс зэрэг зэвсэгт өртөх<br>
                              <b>Ахуйн эрсдэл</b><br>
                              -Унаж бэртэх, элдэв зүйлд цохигдох, дарагдах, зүсэгдэх, хавчуулагдах, гал түймэрт өртөх, түлэгдэх, хорт хийнд хордох<br>
                            </div>
                          </div>
                          <div class="column col-12" *ngIf="this.selItemType == 'A2'">
                            <div class="text-small">
                              <b>Даатгуулагчийг Монгол улсын нутаг дэвсгэрт аялах үед учрах гэнэтийн аюул осол</b>
                              -Тайлбар : Гэнэтийн осолд гэдэг гэнэтийн буюу урьдчилан тооцоолох боломжгүй гадны хүчин зүйлийн нөлөөллөөр бие махбодод бэртэл, гэмтэл учрах үйл явцыг хэлнэ. Үүнд аливааа өвчин эмгэг, өвчлөл хамаарахгүй<br>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer">
                        <div class="float-right" *ngIf="listMode == 1">
                          <button class="btn btn-sm {{tabIndex == 1 ? 'btn-primary':''}}" (click)="tabIndex = 1;"> 1 </button>
                          <button class="btn btn-sm ml-1 {{tabIndex == 2 ? 'btn-primary':''}}" (click)="tabIndex = 2;"> 2 </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="pageId == 1 && (listMode == 2 || (listMode == 1 && tabIndex == 2))" class="column col-{{12-selService.wizard}} col-xs-12">
                    <div class="card" style="height: {{modalHeight}}px;">
                      <div class="card-body" style="overflow-y: scroll; scrollbar-width: none;">
                        <div class="columns">
                          <div class="column col-12">
                            <div class="divider" style="margin-top: 5px;" *ngIf="selService.assetList.length > 0" data-content="Даатгуулагч"></div>
                            <div class="form-group text-small">
                              <label class="form-radio float-left mr-2" *ngFor="let assets of selService.assetList">
                                <input type="radio" name="visaType" value="{{assets.id}}" [(ngModel)]="newInvoice.visaType" (change)="assetSelect(assets)">
                                <i class="form-icon"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{assets.name}}
                              </label>
                            </div>
                          </div>

                          <div *ngIf="selService.id != 3">
                            <div class="column col-12" *ngFor="let package of selService.packageList">
                              <div class="divider" style="margin-top: 5px;" [attr.data-content]="package.name"></div>
                                <div class="columns" *ngIf="selService.id != 3">
                                    <div *ngFor="let protect of proctectFilter(package.protectList)" class="column col-{{12/(selectedServiceId == 3 || selectedServiceId == 2 || selectedServiceId == 4 || selectedServiceId == 5 ? 1 : (package.protectList.length > 3 ? 2 : package.protectList.length))}}">
                                        <div class="card">
                                          <div class="card-body" style="max-height: 125px; height: 108px; min-height: {{protect.height}}px;">
                                            <a href="{{page}}#{{modal}}" (click)="protectSelect(package.protectList, protect)" class="text-dark">
                                              <div class="columns">
                                                <div class="column col-10 p-0">
                                                  <div class="text-small">{{protect.descr}}</div>
                                                </div>
                                                <div class="column col-2 p-0">
                                                  <figure class="avatar avatar-sm bg-secondary float-right">
                                                    <img src="assets/img/shield_blue.png" *ngIf="protect.state == 1"/>
                                                  </figure>
                                                </div>
                                              </div>
                                            </a>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </div>

                          <div class="column col-12" *ngIf="selService.id == 3">
                            <div class="divider" style="margin-top: 5px;" data-content="Эрсдлүүдээс сонгоно уу"></div>
                            <div class="columns" style="height: 590px; overflow-y: scroll;">
                              <div *ngFor="let combine of combineFilter(serviceCombined); let i = index;" class="column col-12">
                                  <div class="card">
                                    <div class="card-body" style="max-height: 180px; min-height: 65px;">
                                      <a href="{{page}}#{{modal}}" class="text-dark" (click)="selectCombine(combine)">
                                        <div class="text-small float-left" style="width: 90%; padding-right: 20px;">
                                          {{i+1}}. {{listCombines(combine.combineId)}}
                                        </div>

                                        <figure class="avatar avatar-sm bg-secondary float-right">
                                          <img src="assets/img/shield_blue.png" *ngIf="combine.state == 1"/>
                                        </figure>
                                      </a>
                                    </div>
                                  </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer" *ngIf="listMode == 1">
                        <div class="float-right">
                          <button class="btn btn-sm {{tabIndex == 1 ? 'btn-primary':''}}" (click)="tabIndex = 1;"> 1 </button>
                          <button class="btn btn-sm ml-1 {{tabIndex == 2 ? 'btn-primary':''}}" (click)="tabIndex = 2;"> 2 </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="pageId == 2 && selService.id == 1 && (listMode == 2 || (listMode == 1 && tabIndex == 1))" class="column col-4 col-xs-12">
                    <div class="card" style="min-height: {{modalHeight}}px;">
                      <div class="card-body">
                        <span class="tile-subtitle text-bold text-small">Насны ангилал</span>
                        <div class="columns">

                          <div class="column col-12 input-group">
                            <span class="input-group-addon addon-sm" style="width:120px;">Бүлэг сонгох</span>
                            <select class="form-select select-sm" style="max-width: 100%;" (change)="groupSelect()" [(ngModel)]="newInvoice.groupId">
                              <option [ngValue]="0">Үгүй</option>
                              <option *ngFor="let group of groups; let i = index;" [ngValue]="group.id">{{group.name}}</option>
                            </select>
                          </div>

                          <div class="column col-12 input-group">
                            <span class="input-group-addon addon-sm" style="width:120px;">0-1 нас</span>
                            <button class="btn btn-primary input-group-btn btn-sm" style="width: 30px;" (click)="popAges(age1)">&nbsp;-&nbsp;</button>
                            <input disabled class="form-input input-sm text-center" id="child_ticker" type="text" value="{{age1.length+''}}" toUppercase placeholder="0">
                            <button class="btn btn-primary input-group-btn btn-sm" style="width: 30px;" (click)="pushAges(age1, 1)">&nbsp;+&nbsp;&nbsp;</button>
                          </div>
                          <div class="column col-12 input-group">
                            <span class="input-group-addon addon-sm" style="width:120px;">2-15 нас</span>
                            <button class="btn btn-primary input-group-btn btn-sm" style="width: 30px;" (click)="popAges(age2)">&nbsp;-&nbsp;</button>
                            <input disabled class="form-input input-sm text-center" id="person_ticker1" type="text" value="{{age2.length+''}}" toUppercase placeholder="0">
                            <button class="btn btn-primary input-group-btn btn-sm" style="width: 30px;" (click)="pushAges(age2, 2)">&nbsp;+&nbsp;</button>
                          </div>
                          <div class="column col-12 input-group">
                            <span class="input-group-addon addon-sm" style="width:120px;">16-60 нас</span>
                            <button class="btn btn-primary input-group-btn btn-sm" style="width: 30px;" (click)="popAges(age3)">&nbsp;-&nbsp;</button>
                            <input disabled class="form-input input-sm text-center" id="person_ticker2" type="text" value="{{age3.length+''}}" toUppercase placeholder="0">
                            <button class="btn btn-primary input-group-btn btn-sm" style="width: 30px;" (click)="pushAges(age3, 3)">&nbsp;+&nbsp;</button>
                          </div>
                          <div class="column col-12 input-group">
                            <span class="input-group-addon addon-sm" style="width:120px;">61-69 нас</span>
                            <button class="btn btn-primary input-group-btn btn-sm" style="width: 30px;" (click)="popAges(age4)">&nbsp;-&nbsp;</button>
                            <input disabled class="form-input input-sm text-center" id="person_ticker3" type="text" value="{{age4.length+''}}" toUppercase placeholder="0">
                            <button class="btn btn-primary input-group-btn btn-sm" style="width: 30px;" (click)="pushAges(age4, 4)">&nbsp;+&nbsp;</button>
                          </div>
                          <div class="column col-12 input-group">
                            <span class="input-group-addon addon-sm" style="width:120px;">70-79 нас</span>
                            <button class="btn btn-primary input-group-btn btn-sm" style="width: 30px;" (click)="popAges(age5)">&nbsp;-&nbsp;</button>
                            <input disabled class="form-input input-sm text-center" id="old_ticker" type="text" value="{{age5.length+''}}" toUppercase placeholder="0">
                            <button class="btn btn-primary input-group-btn btn-sm" style="width: 30px;" (click)="pushAges(age5, 5)">&nbsp;+&nbsp;</button>
                          </div>
                          <div class="column col-12 input-group">
                            <span class="input-group-addon addon-sm" style="width:120px;">80-c дээш</span>
                            <button class="btn btn-primary input-group-btn btn-sm" style="width: 30px;" (click)="popAges(age12)">&nbsp;-&nbsp;</button>
                            <input disabled class="form-input input-sm text-center" id="old12_ticker" type="text" value="{{age12.length+''}}" toUppercase placeholder="0">
                            <button class="btn btn-primary input-group-btn btn-sm" style="width: 30px;" (click)="pushAges(age12, 12)">&nbsp;+&nbsp;</button>
                          </div>
                        </div>

                        <span class="tile-subtitle text-bold text-small">Зорчих хугацаа</span>
                        <div class="columns">
                          <div class="column col-12 input-group">
                            <span class="input-group-addon addon-sm" style="width: 120px;">Төрөл сонгох</span>
                            <select class="form-select select-sm" [(ngModel)]="newInvoice.daysType" (change)="rangeChange()">
                              <option [ngValue]="3">Нэг удаагийн</option>
                              <option [ngValue]="4">Бүтэн жилийн</option>
                            </select>
                          </div>
                        </div>

                        <div class="columns">
                          <div class="column col-12 input-group">
                            <span class="input-group-addon addon-sm" style="width: 120px;">Эхлэх өдөр</span>
                            <input type="date" class="form-input input-sm" [(ngModel)]="newInvoice.startDate" (change)="companies=[]; groupSelect(); checkInvoice();" />
                          </div>
                          <div class="column col-12 input-group">
                            <span class="input-group-addon addon-sm" style="width: 120px;">Дуусах өдөр</span>
                            <input type="date" class="form-input input-sm" [(ngModel)]="newInvoice.endDate" (change)="companies=[]; groupSelect(); checkInvoice();"/>
                          </div>
                        </div>

                        <span class="tile-title text-bold text-small text-primary">{{diffDays()}} өдөр</span>
                        <div class="divider"></div>
                        <button class="btn btn-primary btn-sm" (click)="checkInvoice()">Тооцоолол харах</button>
                        <div class="float-right" *ngIf="listMode == 1">
                          <button class="btn btn-sm" (click)="tabIndex = 1;"> 1 </button>
                          <button class="btn btn-sm ml-1" (click)="tabIndex = 2;"> 2 </button>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div *ngIf="pageId == 2 && selService.id == 2" class="column col-6">
                    <div class="card">
                      <div class="card-body" style="min-height: 680px;">
                        <div class="divider" data-content="Даатгалын мэдээлэл"></div>

                        <div class="columns">
                          <div class="column col-6 input-group">
                            <span class="input-group-addon addon-sm" style="width:80px;">Төрөл:</span>
                            <select class="form-select select-sm" [(ngModel)]="properties[0].type">
                              <option [ngValue]="3">Бетонон орон сууц</option>
                              <option [ngValue]="4">Бетонон оффис</option>
                              <option [ngValue]="1">Бетонон үйлдвэрийн барилга</option>
                              <option [ngValue]="2">Сэндвичэн үйлвэрийн барилга</option>
                              <option [ngValue]="5">Холимог хашаа байшин</option>
                              <option [ngValue]="6">Бетонон хаус</option>
                              <option [ngValue]="7">Холимог хаус</option>
                              <option [ngValue]="8">Модон хаус</option>
                            </select>
                          </div>

                          <div class="column col-6 input-group">
                            <span class="input-group-addon addon-sm" style="width:80px;">Байршил:</span>
                            <select class="form-select select-sm" [(ngModel)]="properties[0].location">
                              <option [ngValue]="1" value="1">Сайн</option>
                              <option [ngValue]="2" value="2">Дунд</option>
                              <option [ngValue]="3" value="3">Муу</option>
                            </select>
                          </div>

                          <div class="column col-6 input-group">
                            <span class="input-group-addon addon-sm" style="width:80px;">Чанар:</span>
                            <select class="form-select select-sm" [(ngModel)]="properties[0].quality">
                              <option [ngValue]="1" value="1">Сайн</option>
                              <option [ngValue]="2" value="2">Дунд</option>
                              <option [ngValue]="3" value="3">Муу</option>
                            </select>
                          </div>

                          <div class="column col-6 input-group">
                            <span class="input-group-addon addon-sm" style="width:80px;">Аюулгүй:</span>
                            <select class="form-select select-sm" [(ngModel)]="properties[0].safety">
                              <option [ngValue]="1" value="1">Сайн</option>
                              <option [ngValue]="2" value="2">Дунд</option>
                              <option [ngValue]="3" value="3">Муу</option>
                            </select>
                          </div>

                          <div class="column col-6 input-group">
                            <span class="input-group-addon addon-sm" style="width:80px;">Эхлэх:</span>
                            <input class="form-input input-sm" type="date" value="" toUppercase [(ngModel)]="newInvoice.startDate">
                          </div>

                          <div class="column col-6 input-group">
                            <span class="input-group-addon addon-sm" style="width:80px;">Хугацаа:</span>
                            <select class="form-select select-sm" [(ngModel)]="newInvoice.days">
                              <option [ngValue]="365" value="365">1 жил</option>
                              <option [ngValue]="30" value="30">1 сар</option>
                              <option [ngValue]="60" value="60">2 сар</option>
                              <option [ngValue]="90" value="90">3 сар</option>
                              <option [ngValue]="120" value="120">4 сар</option>
                              <option [ngValue]="150" value="150">5 сар</option>
                              <option [ngValue]="180" value="180">6 сар</option>
                              <option [ngValue]="210" value="210">7 сар</option>
                              <option [ngValue]="240" value="240">8 сар</option>
                              <option [ngValue]="270" value="270">9 сар</option>
                              <option [ngValue]="300" value="300">10 сар</option>
                              <option [ngValue]="330" value="330">11 сар</option>
                            </select>
                          </div>

                          <div class="column col-12">
                            <div class="input-group">
                              <input class="form-input input-group-addon addon-sm input-sm" type="file"
                                     multiple (change)="selectImageFiles($event)">
                              <button class="btn btn-primary input-group-btn" (click)="uploadPics()">Илгээх</button>
                            </div>

                            <div class="divider text-center" data-content="ЗУРАГУУД"></div>
                            <span *ngFor="let img of fileInfos" class="chip">
                              <img class="avatar avatar-lg" src="p/img/{{img.url}}" (click)="openImage(img)"/>
                              <a (click)="deleteImage(img)" class="btn btn-clear" aria-label="Устгах" role="button"></a>
                            </span>
                          </div>

                          <div class="column col-6 input-group">
                            <span class="input-group-addon addon-sm" style="width:100px;">Даатгуулагч</span>
                            <select class="form-select select-sm" [(ngModel)]="newInvoice.visaType">
                              <option [ngValue]="0" value="0">Хариуцахгүй</option>
                              <option [ngValue]="5" value="5">5 хувь</option>
                              <option [ngValue]="10" value="10">10 хувь</option>
                              <option [ngValue]="20" value="20">20 хувь</option>
                            </select>
                          </div>

                          <div class="column col-6 input-group">
                            <span class="input-group-addon addon-sm" style="width:80px;">Үнэлгээ</span>
                            <input class="form-input input-sm" type="number" value="" [(ngModel)]="properties[0].amount">
                          </div>
                        </div>

                        <div class="divider"></div>
                        <button class="btn btn-primary btn-sm" (click)="checkInvoice()">Тооцоолол харах</button>
                      </div>

                    </div>
                  </div>

                  <div *ngIf="pageId == 2 && selService.id == 3 && (listMode == 2 || (listMode == 1 && tabIndex == 1))" class="column col-7 col-xs-12">
                    <div class="card" style="height: {{modalHeight}}px;">
                      <div class="card-body" style="overflow-y:scroll; scrollbar-width: none;">
                        <div class="divider" data-content="Тээврийн хэрэгслийн мэдэээлэл"></div>
                        <div class="columns">
                          <div class="column col-5 col-xs-12 input-group">
                            <span class="input-group-addon addon-sm" style="width: 80px;">Улсын №:</span>
                            <input class="form-input input-sm" type="text" value="" toUppercase placeholder="0000УБА" [(ngModel)]="propNo">
                            <button class="btn btn-primary input-group-btn btn-sm" style="width: 30px;" (click)="searchProperty()"><i class="icon icon-arrow-right"></i></button>
                          </div>
                          <div class="column col-7 col-xs-12 input-group">
                            <span class="input-group-addon addon-sm" style="width: 90px;">Арлын №:</span>
<!--                            <span class="form-input input-sm text-ellipsis">{{properties[0].vin}}</span>-->
                            <input class="form-input input-sm" type="text" value="" toUppercase placeholder="" [(ngModel)]="properties[0].vin">
                            <button class="btn btn-primary input-group-btn btn-sm" style="width: 30px;" (click)="searchProperty1()"><i class="icon icon-arrow-right"></i></button>
                          </div>
                          <div class="column col-4 col-xs-12 input-group">
                            <span class="input-group-addon addon-sm" style="width: 80px;">Ангилал:</span>
                            <span class="form-input input-sm">{{properties[0].cat}}</span>
                          </div>
                          <div class="column col-8  col-xs-12 input-group">
                            <span class="input-group-addon addon-sm" style="width: 105px;">Марк, модел:</span>
                            <span class="form-input input-sm">{{properties[0].mark+"/"+properties[0].model}}</span>
                          </div>
                          <div class="column col-3 col-xs-12  input-group">
                            <span class="input-group-addon addon-sm" style="width: 50px;">Жин:</span>
                            <input class="form-input input-sm" type="text" [(ngModel)]="properties[0].weight"/>
                          </div>
                          <div class="column col-5 col-xs-12  input-group">
                            <span class="input-group-addon addon-sm" style="width: 120px;">Хөд.багтаамж :</span>
                            <input class="form-input input-sm" [(ngModel)]="properties[0].size"/>
                          </div>
                          <div class="column col-4 col-xs-12  input-group">
                            <span class="input-group-addon addon-sm" style="width: 50px;">Өнгө:</span>
                            <select class="form-select select-sm" [(ngModel)]="properties[0].color">
                                <option value="Хар">Хар</option>
                                <option value="Саарал">Саарал</option>
                            </select>
                          </div>
                          <div class="column col-6 col-xs-12  input-group">
                            <span class="input-group-addon addon-sm" style="width: 140px;">Үйлдвэрлэсэн он:</span>
                            <input class="form-input input-sm" type="text" [(ngModel)]="properties[0].propYear"/>
                          </div>
                          <div class="column col-6 col-xs-12  input-group">
                            <span class="input-group-addon addon-sm" style="width: 120px;">Орж ирсэн:</span>
                            <input class="form-input input-sm" type="text" [(ngModel)]="properties[0].mnYear"/>
                          </div>
                          <div class="column col-6 col-xs-12  input-group">
                            <span class="input-group-addon addon-sm" style="width: 85px;">Эзэмшил:</span>
                            <select class="form-select select-sm" [(ngModel)]="properties[0].ownerType">
                              <option value="Хувь хүн">Хувь хүн</option>
                              <option value="Байгууллага">Байгууллага</option>
                              <option value="ББС байгууллага">ББС байгууллага</option>
                            </select>
                          </div>
                          <div class="column col-3 col-xs-12 input-group">
                            <span class="input-group-addon addon-sm" style="width: 75px;">Чиргүүл:</span>
                            <input class="form-input input-sm" type="text" value="" [(ngModel)]="properties[0].extend">
                          </div>
                          <div class="column col-3 col-xs-12  input-group">
                            <span class="input-group-addon addon-sm" style="width: 70px;">Суудал:</span>
                            <input class="form-input input-sm" type="text" value="" [(ngModel)]="properties[0].capacity">
                          </div>
                        </div>

                        <div class="divider" data-content="Даатгалын мэдээлэл оруулах"></div>
                        <div class="columns">
                          <div class="column col-6 col-xs-12  input-group">
                            <span class="input-group-addon addon-sm" style="width: 100px;">Эхлэх:</span>
                            <input class="form-input input-sm" type="date" value="" toUppercase [(ngModel)]="newInvoice.startDate">
                          </div>
                          <div class="column col-6 col-xs-12  input-group">
                            <span class="input-group-addon addon-sm" style="width: 100px;">Хугацаа:</span>
                            <select class="form-select input-group select-sm" [(ngModel)]="newInvoice.days">
                              <option value="365">1 жил</option>
                              <option value="90" [ngValue]="90">3 сар</option>
                              <option value="120" [ngValue]="120">4 сар</option>
                              <option value="150" [ngValue]="150">5 сар</option>
                              <option value="180">6 сар</option>
                              <option value="210">7 сар</option>
                              <option value="240">8 сар</option>
                              <option value="270">9 сар</option>
                              <option value="300">10 сар</option>
                              <option value="330">11 сар</option>
                            </select>
                          </div>
                          <div class="column col-12 col-xs-12  input-group">
                            <span class="input-group-addon addon-sm" style="width: 220px;">Үнэлгээ <b>({{properties[0].amount | currency : '₮ '}})</b>: </span>
                            <input class="form-input input-sm" type="number" value="" toUppercase [(ngModel)]="properties[0].amount">
                            <button class="btn btn-primary input-group-btn btn-sm" style="width: 100px;" (click)="checkInvoice()">Тооцоолол</button>
                          </div>
                          <div class="column col-12 col-xs-12 ">
                            <span class="text-error text-small text-italic text-">
                              * Хэрэв даатгалын үнэлгээ нь зах зээлийн үнээс бага байвал зах зээлийн үнэд даатгалын үнэлгээний
                              эзлэх хувиар нөхөн төлбөрийг хувь тэнцүүлэн тооцно.
                            </span>

                            <div class="form-group mt-2 pt-2" *ngIf="profile && profile.role == 'ROLE_ADMIN'">
                              <label class="form-switch">
                                <input type="checkbox" [(ngModel)]="isBundle">
                                <i class="form-icon"></i><span class="ml-2 pl-2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Жолоочын хариуцлагын даатгалыг хамтад нь хийнэ.</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer">
                        <div class="float-right" *ngIf="listMode == 1">
                          <button class="btn btn-sm {{tabIndex == 1 ? 'btn-primary':''}}" (click)="tabIndex = 1;"> 1 </button>
                          <button class="btn btn-sm ml-1 {{tabIndex == 2 ? 'btn-primary':''}}" (click)="tabIndex = 2;"> 2 </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="pageId == 2 && selService.id == 4" class="column col-7">
                    <div class="card" style="overflow-y: scroll;height: 690px;">
                      <div class="card-body">
                        <div class="divider" data-content="Тээврийн хэрэгслийн мэдэээлэл"></div>
                        <div class="columns">
                          <div class="column col-12" *ngIf="selItemType == 'J2'">
                            <div class="input-group">
                              <input class="form-input input-group-addon addon-sm input-sm" type="file" (change)="selectXlsFile($event)">
                              <button class="btn btn-primary input-group-btn" (click)="uploadXls()">Оруулах</button>
                              <button class="btn btn-error input-group-btn" (click)="clearXls()" *ngIf="plates.length > 0">Цэвэрлэх</button>
                            </div>
                            <div class="divider" data-content="Файлаас эсвэл нэгээр оруулах"></div>
                            <span *ngIf="plates.length > 0" class="text-small">{{plates.length}} тээврийн хэрэгсэл</span>
                            <br>
                            <span *ngFor="let plate of plates" (click)="propNo = plate; searchProperty();">
                              <span class="chip {{propertyIsChecked(plate) ? 'bg-primary':''}}">
                                {{plate}}
                              </span>
                            </span>

                          </div>

                          <div class="column col-5 input-group">
                            <span class="input-group-addon addon-sm" style="width: 80px;">Улсын №:</span>
                            <input class="form-input input-sm" type="text" value="" disabled="{{plates.length != 0}}" toUppercase placeholder="0000УБА" [(ngModel)]="propNo">
                            <button class="btn btn-primary input-group-btn btn-sm" style="width: 30px;" (click)="searchProperty()"><i class="icon icon-arrow-right"></i></button>
                          </div>
                          <div class="column col-7 input-group">
                            <span class="input-group-addon addon-sm" style="width: 90px;">Арлын №:</span>
                            <span class="form-input input-sm text-ellipsis">{{propertyIt(propNo).vin}}</span>
                          </div>
                          <div class="column col-4 input-group">
                            <span class="input-group-addon addon-sm" style="width: 80px;">Ангилал:</span>
                            <span class="form-input input-sm">{{propertyIt(propNo).cat}}</span>
                          </div>
                          <div class="column col-8 input-group">
                            <span class="input-group-addon addon-sm" style="width: 105px;">Марк, модел:</span>
                            <span class="form-input input-sm">{{propertyIt(propNo).mark+"/"+propertyIt(propNo).model}}</span>
                          </div>
                          <div class="column col-3 input-group">
                            <span class="input-group-addon addon-sm" style="width: 50px;">Жин:</span>
                            <span class="form-input input-sm">{{propertyIt(propNo).weight}}</span>
                          </div>
                          <div class="column col-5 input-group">
                            <span class="input-group-addon addon-sm" style="width: 120px;">Хөд.багтаамж :</span>
                            <span class="form-input input-sm">{{propertyIt(propNo).size}}</span>
                          </div>
                          <div class="column col-4 input-group">
                            <span class="input-group-addon addon-sm" style="width: 50px;">Өнгө:</span>
                            <span class="form-input input-sm">{{propertyIt(propNo).color}}</span>
                          </div>
                          <div class="column col-6 input-group">
                            <span class="input-group-addon addon-sm" style="width: 140px;">Үйлдвэрлэсэн он:</span>
                            <span class="form-input input-sm">{{propertyIt(propNo).propYear}}</span>
                          </div>
                          <div class="column col-6 input-group">
                            <span class="input-group-addon addon-sm" style="width: 120px;">Орж ирсэн:</span>
                            <span class="form-input input-sm">{{propertyIt(propNo).mnYear}}</span>
                          </div>
                          <div class="column col-6 input-group">
                            <span class="input-group-addon addon-sm" style="width: 85px;">Эзэмшил:</span>
                            <span class="form-input input-sm">{{propertyIt(propNo).ownerType}}</span>
                          </div>
                          <div class="column col-3 input-group">
                            <span class="input-group-addon addon-sm" style="width: 75px;">Чиргүүл:</span>
                            <input class="form-input input-sm" type="text" value="" [(ngModel)]="propertyIt(propNo).extend">
                          </div>
                          <div class="column col-3 input-group">
                            <span class="input-group-addon addon-sm" style="width: 70px;">Суудал:</span>
                            <span class="form-input input-sm">{{propertyIt(propNo).capacity}}</span>
                          </div>
                        </div>

                        <div class="divider" *ngIf="selItemType == 'J1' || selItemType == 'J3'" data-content="Даатгуулагчийн мэдээлэл"></div>
                        <div class="columns" *ngIf="selItemType == 'J1' || selItemType == 'J3'">
                          <div class="columns column col-12 ml-2" *ngFor="let owner of owners; let i = index;">
                            <div class="column col-6 input-group">
                              <span class="input-group-addon addon-sm" style="width: 120px;">Регистр №*:</span>
                              <input class="form-input input-sm" type="text" value="" toUppercase [(ngModel)]="owner.rd" (change)="findUser(owner, 1)">
                            </div>
                            <div class="column col-6 input-group" *ngIf="properties[0].ownerType == 'Хувь хүн'">
                              <span class="input-group-addon addon-sm" style="width: 120px;">Үнэмлэх №:</span>
                              <input class="form-input input-sm" type="text" value="" toUppercase [(ngModel)]="owner.dl">
                            </div>
                            <div class="column col-6 input-group" *ngIf="properties[0].ownerType == 'Хувь хүн'">
                              <span class="input-group-addon addon-sm" style="width: 120px;">Овог*:</span>
                              <input class="form-input input-sm" type="text" value="" toUppercase [(ngModel)]="owner.ln">
                            </div>
                            <div class="column col-6 input-group">
                              <span class="input-group-addon addon-sm" style="width: 120px;">Нэр*:</span>
                              <input class="form-input input-sm" type="text" value="" toUppercase [(ngModel)]="owner.fn">
                            </div>
                            <div class="column col-6 input-group">
                              <span class="input-group-addon addon-sm" style="width: 120px;">Утас*:</span>
                              <input class="form-input input-sm" type="text" value="" toUppercase [(ngModel)]="owner.ph">
                            </div>
                            <div class="column col-6 input-group">
                              <span class="input-group-addon addon-sm" style="width: 120px;">Имейл:</span>
                              <input class="form-input input-sm" type="text" value="" toUppercase [(ngModel)]="owner.em">
                            </div>
                            <div class="column col-7 input-group" *ngIf="properties[0].ownerType == 'Хувь хүн'">
                              <span class="input-group-addon addon-sm" style="width: 120px;">Туршлага /жил/:</span>
                              <input class="form-input input-sm" type="number" value="" [(ngModel)]="owner.tr">
                              <select class="form-select select-sm" style="width: 80px;" value="1" [(ngModel)]="owner.ownerType">
                                  <option value="1">Үндсэн</option>
                                  <option value="2">Хамтран</option>
                                  <option value="3">Эрх хязгаарлагдсан</option>
                              </select>
                            </div>
<!--                            <div class="column col-5 input-group">-->
<!--                              <button class="btn btn-error btn-sm" *ngIf="i > 0" (click)="removeAjdOwner(owner)"><i class="icon icon-minus"></i></button>-->
<!--                            </div>-->
<!--                            <div class="column col-12">-->
<!--                              <div class="divider"></div>-->
<!--                            </div>-->
                          </div>

<!--                          <div class="column col-6 ml-2">-->
<!--                            <button class="btn btn-primary btn-sm ml-2 mt-0" (click)="addAjdOwner()"><i class="icon icon-plus"></i></button>-->
<!--                          </div>-->
                        </div>

                        <div class="divider" *ngIf="selItemType == 'J2'" data-content="Байгууллагын мэдээлэл"></div>
                        <div class="columns" *ngIf="selItemType == 'J2'">
                          <div class="column col-6 input-group">
                            <span class="input-group-addon addon-sm" style="width: 100px;">Регистр №:</span>
                            <input class="form-input input-sm" type="text" value="" toUppercase [(ngModel)]="owners[0].rd">
                          </div>
                          <div class="column col-6 input-group">
                            <span class="input-group-addon addon-sm" style="width: 100px;">Нэр:</span>
                            <input class="form-input input-sm" type="text" value="" toUppercase [(ngModel)]="owners[0].fn">
                          </div>
                          <div class="column col-12 input-group">
                            <span class="input-group-addon addon-sm" style="width: 100px;">Хаяг:</span>
                            <input class="form-input input-sm" type="text" value="" toUppercase [(ngModel)]="owners[0].ln">
                          </div>
                          <div class="column col-6 input-group">
                            <span class="input-group-addon addon-sm" style="width: 100px;">Утас:</span>
                            <input class="form-input input-sm" type="text" value="" toUppercase [(ngModel)]="owners[0].ph">
                          </div>
                          <div class="column col-6 input-group">
                            <span class="input-group-addon addon-sm" style="width: 100px;">Имейл:</span>
                            <input class="form-input input-sm" type="text" value="" toUppercase [(ngModel)]="owners[0].em">
                          </div>
                        </div>

                        <div class="divider" data-content="Даатгалын мэдээлэл оруулах"></div>
                        <div class="columns">
                          <div class="column col-6 input-group">
                            <span class="input-group-addon addon-sm" style="width: 100px;">Эхлэх:</span>
                            <input class="form-input input-sm" type="date" value="" toUppercase [(ngModel)]="newInvoice.startDate">
                          </div>
                          <div class="column col-6 input-group">
                            <span class="input-group-addon addon-sm" style="width: 100px;">Хугацаа:</span>
                            <select class="form-select input-group select-sm" [(ngModel)]="newInvoice.days">
                              <option value="365" [ngValue]="365">1 жил</option>
                            </select>
                          </div>
                          <div class="column col-6 input-group">
                            <span class="input-group-addon addon-sm" style="width: 150px;">Даатгалын компани:</span>
                            <select class="form-select input-group select-sm" [(ngModel)]="newInvoice.ajdId">
                              <option value="{{c.id}}" [ngValue]="c.id" *ngFor="let c of ajds">{{c.name}}</option>
                            </select>
                          </div>
                          <div class="column col-6">
                            <button class="btn btn-primary input-group-btn btn-sm" style="width: 150px;" (click)="checkInvoice()">Тооцоолол харах</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="pageId == 2 && selService.id == 5 && (listMode == 2 || (listMode == 1 && tabIndex == 1))" class="column col-7 col-xs-12">
                    <div class="card" style="height: {{modalHeight}}px;">
                      <div class="card-body" style="overflow-y:scroll; scrollbar-width: none;">
                        <div class="divider" data-content="Тээврийн хэрэгслийн мэдэээлэл"></div>
                        <div class="columns">
                          <div class="column col-6 col-xs-12 input-group">
                            <span class="input-group-addon addon-sm" style="width: 120px;">{{selItemType == 'A2'?'Пасспорт №:':'Регистр №:'}}</span>
                            <input class="form-input input-sm" type="text" value="" toUppercase placeholder="" [(ngModel)]="localVisitor.passNo">
                          </div>
                          <div class="column col-6 col-xs-12 input-group">
                            <span class="input-group-addon addon-sm" style="width: 70px;">Овог:</span>
                            <input class="form-input input-sm" type="text" value="" toUppercase placeholder="" [(ngModel)]="localVisitor.firstName">
                          </div>
                          <div class="column col-6 col-xs-12 input-group">
                            <span class="input-group-addon addon-sm" style="width: 80px;">Нэр:</span>
                            <input class="form-input input-sm" type="text" value="" toUppercase placeholder="" [(ngModel)]="localVisitor.lastName">
                          </div>
                          <div class="column col-6  col-xs-12 input-group">
                            <span class="input-group-addon addon-sm" style="width: 120px;">Төрсөн огноо:</span>
                            <input class="form-input input-sm" type="date" value="" toUppercase placeholder="" [(ngModel)]="localVisitor.birthDate">
                          </div>
                          <div class="column col-6 col-xs-12  input-group" *ngIf="selItemType == 'A2'">
                            <span class="input-group-addon addon-sm" style="width: 140px;">Аль улсын иргэн:</span>
                            <select class="form-select select-sm" [(ngModel)]="localVisitor.country">
                              <option *ngFor="let c of countries" value="{{c.name}}">{{c.name}}</option>
                            </select>
                          </div>
                          <div class="column col-6 col-xs-12  input-group" *ngIf="selItemType == 'A2'">
                            <span class="input-group-addon addon-sm" style="width: 120px;">Визны төрөл :</span>
                            <select class="form-select select-sm" [(ngModel)]="localVisitor.visaType">
                              <option value="K1">K1</option>
                              <option value="K2">K2</option>
                            </select>
                          </div>
                          <div class="column col-6 col-xs-12  input-group" *ngIf="selItemType == 'A2'">
                            <span class="input-group-addon addon-sm" style="width: 130px;">Визний хугацаа:</span>
                            <select class="form-select select-sm" [(ngModel)]="localVisitor.visaLength">
                              <option value="60">60</option>
                              <option value="30">30</option>
                            </select>
                          </div>
                          <div class="column col-5 col-xs-12  input-group">
                            <span class="input-group-addon addon-sm" style="width: 120px;">Утасны дугаар:</span>
                            <input class="form-input input-sm" type="number" [(ngModel)]="localVisitor.phoneNo"/>
                          </div>
                          <div class="column col-7 col-xs-12  input-group" *ngIf="selItemType == 'A2'">
                            <span class="input-group-addon addon-sm" style="width: 260px;">Хамт аялах Монгол хүний дугаар:</span>
                            <input class="form-input input-sm" type="number" [(ngModel)]="localVisitor.phoneNoMng"/>
                          </div>

                          <div class="column col-5 col-xs-12 input-group">
                            <span class="input-group-addon addon-sm" style="width: 150px;">Машины дугаар:</span>
                            <input class="form-input input-sm" type="text" value="" placeholder="0000УБА" [(ngModel)]="localVisitor.carNo">
                          </div>
                          <div class="column col-4 col-xs-12  input-group" *ngIf="selItemType == 'A1'">
                            <span class="input-group-addon addon-sm" style="width: 120px;">Хэдүүлээ явах:</span>
                            <select class="form-select select-sm" [(ngModel)]="localVisitor.groupCount">
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                            </select>
                          </div>
                          <div class="column col-8 col-xs-12 input-group" *ngIf="selItemType == 'A1'">
                            <span class="input-group-addon addon-sm" style="width: 80px;">Хаяг:</span>
                            <input class="form-input input-sm" type="text" value="" [(ngModel)]="localVisitor.address">
                          </div>

                          <div class="column col-12 col-xs-12">
                            <div class="divider" data-content="Аялах аймагууд"></div>
                            <span *ngFor="let aimag of aimags">
                              <label class="chip float-left mb-2" *ngIf="!isselectedCountry(aimag)">{{aimag.name}}
                                <a href="{{page}}#{{modal}}" (click)="selAimags(aimag)" class="btn btn-clear btn-add" aria-label="" role="button"></a>
                              </label>
                            </span>
                          </div>

                          <div class="column col-12 col-xs-12">
                            <span *ngFor="let country of newInvoice.countries">
                              <span class="chip bg-primary">
                                {{country.name}}
                                <a href="{{page}}#{{modal}}" (click)="removeCountries(country)" class="btn btn-clear" aria-label="" role="button"></a>
                              </span>
                            </span>
                          </div>

                        </div>

                        <div class="divider" data-content="Даатгалын мэдээлэл оруулах"></div>
                        <div class="columns">
                          <div class="column col-8 col-xs-12  input-group">
                            <span class="input-group-addon addon-sm" style="width: 170px;">Хамтгаалалтын дүн:</span>
                            <select class="form-select input-group select-sm" [(ngModel)]="localVisitor.coverage">
                              <option value="5000000" [ngValue]="5000000" *ngIf="selItemType == 'A1'">5 сая ({{(5000000 / rate.value) | currency:'USD'}})</option>
                              <option value="10000000" [ngValue]="10000000" selected>10 сая ({{(10000000 / rate.value) | currency:'USD'}})</option>
                              <option value="20000000" [ngValue]="15000000" *ngIf="selItemType == 'A2'">15 сая ({{(15000000 / rate.value) | currency:'USD'}})</option>
                              <option value="20000000" [ngValue]="20000000">20 сая ({{(20000000 / rate.value) | currency:'USD'}})</option>
                              <option value="30000000" [ngValue]="30000000">30 сая ({{(30000000 / rate.value) | currency:'USD'}})</option>
                            </select>
                          </div>
                          <div class="column col-5 col-xs-12  input-group">
                            <span class="input-group-addon addon-sm" style="width: 100px;">Эхлэх:</span>
                            <input class="form-input input-sm" type="date" value="" toUppercase [(ngModel)]="newInvoice.startDate">
                          </div>
                          <div class="column col-5 col-xs-12  input-group">
                            <span class="input-group-addon addon-sm" style="width: 100px;">Дуусах:</span>
                            <input class="form-input input-sm" type="date" value="" toUppercase [(ngModel)]="newInvoice.endDate">
                          </div>
                          <div class="column col-1 col-xs-12  input-group">
                            <span class="input-group-addon addon-sm">{{diffDays()}} өдөр</span>
                          </div>
                          <div class="column col-12 col-xs-12  input-group">
                            <button class="btn btn-primary input-group-btn btn-sm" style="width: 150px;" (click)="checkInvoice()">Тооцоолол</button>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer">
                        <div class="float-right" *ngIf="listMode == 1">
                          <button class="btn btn-sm {{tabIndex == 1 ? 'btn-primary':''}}" (click)="tabIndex = 1;"> 1 </button>
                          <button class="btn btn-sm ml-1 {{tabIndex == 2 ? 'btn-primary':''}}" (click)="tabIndex = 2;"> 2 </button>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div *ngIf="pageId == 2 && selService.id == 1 && (listMode == 2 || (listMode == 1 && tabIndex == 2))" class="column col-8 col-xs-12">
                    <div class="card" style="height: {{modalHeight}}px; overflow-y: scroll;">
                      <div class="card-body">
                        <div class="divider" data-content="Даатгалын компаниуд"></div>
                        <div class="column col-3 col-sm-12" *ngIf="loading">
                          <div
                            class="loading float-left"
                            style="margin-left: 15px">

                          </div>
                        </div>
                        <div class="filter">
                          <div class="filter-body columns">
                            <div class="column col-4 col-xs-6 filter-item"  *ngFor="let company of companies">
                              <div class="card" (click)="companySelect(company)" class="{{company.id == newInvoice.orgId ? 'active': ''}}">
                                <div class="card-header text-center">
                                  <figure class="avatar bg-secondary">
                                    <img src="assets/img/{{company.icon}}">
                                  </figure>
                                </div>
                                <div class="card-body">
                                  <div class="tile-title text-center text-small">{{company.name}}</div>
                                  <div class="tile-subtitle text-small text-center text-bold">{{company.total | currency: company.currency+' '}}</div>
                                  <div class="tile-subtitle text-small text-center text-gray" *ngIf="selService.id != 4">{{company.protect.coverage | currency:company.protect.currency+' '}}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer" *ngIf="listMode == 1">
                        <div class="float-right">
                          <button class="btn btn-sm" (click)="tabIndex = 1;"> 1 </button>
                          <button class="btn btn-sm ml-1" (click)="tabIndex = 2;"> 2 </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="pageId == 2 && selService.id == 2" class="column col-6">
                    <div class="card">
                      <div class="card-body" style="height: 680px; overflow-y: scroll;">
                        <div class="divider" data-content="Даатгалын компаниуд"></div>
                        <div class="column col-3 col-sm-12" *ngIf="loading">
                          <div
                            class="loading float-left"
                            style="margin-left: 15px">

                          </div>
                        </div>
                        <div class="filter">
                          <div class="filter-body columns">
                            <div class="column col-6 filter-item"  *ngFor="let company of companies">
                              <div class="card" (click)="companySelect(company)" class="{{company.id == newInvoice.orgId ? 'active': ''}}">
                                <div class="card-header text-center">
                                  <figure class="avatar bg-secondary">
                                    <img src="assets/img/{{company.icon}}">
                                  </figure>
                                </div>
                                <div class="card-body">
                                  <div class="tile-title text-center text-small">{{company.name}}</div>
                                  <div class="tile-subtitle text-small text-center text-bold">{{company.total | currency:company.currency+' '}}</div>
                                  <div class="tile-subtitle text-small text-center text-gray">{{company.protect.coverage | currency:company.currency+' '}}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="pageId == 2 && selService.id == 3" class="column col-5 col-xs-12">
                    <div class="card" style="height: {{modalHeight}}px;">
                      <div class="card-body" style="overflow-y: scroll;">
                        <div class="divider" data-content="Даатгалын компаниуд"></div>
                        <div class="column col-3 col-sm-12" *ngIf="loading">
                          <div
                            class="loading float-left"
                            style="margin-left: 15px">

                          </div>
                        </div>
                        <div class="filter">
                          <div class="filter-body columns">
                            <div class="column col-6 filter-item"  *ngFor="let company of companies">
                              <div class="card" style="min-height: 211px;" (click)="companySelect(company)" class="{{company.id == newInvoice.orgId && company.total == newInvoice.amount ? 'active': ''}}">
                                <div class="card-header text-center">
                                  <figure class="avatar bg-secondary">
                                    <img src="assets/img/{{company.icon}}">
                                  </figure>
                                </div>
                                <div class="card-body">
                                  <div class="tile-title text-center text-small">{{company.name.replace(' даатгал','')}}</div>
                                  <div class="tile-subtitle text-small text-center text-bold">{{company.total | currency:company.currency+' '}}</div>
                                  <div class="tile-subtitle text-small text-center text-gray">{{company.protect.coverage | currency:company.currency+' '}}</div>
                                  <div class="tile-subtitle text-small text-center text-warning" *ngIf="(company.region - 1) > 0">{{'Даатгуулагч эрсдэл гарсан үед хохирлын '+((company.region - 1) * 10)+'% хариуцна'}}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer">
                        <div class="float-right" *ngIf="listMode == 1">
                          <button class="btn btn-sm" (click)="tabIndex = 1;"> 1 </button>
                          <button class="btn btn-sm ml-1" (click)="tabIndex = 2;"> 2 </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="pageId == 2 && selService.id == 4" class="column col-5">
                    <div class="card">
                      <div class="card-body" style="height: 570px; overflow-y: scroll;">
                        <div class="divider" data-content="Даатгалын компаниуд"></div>
                        <div class="column col-3 col-sm-12" *ngIf="loading">
                          <div
                            class="loading float-left"
                            style="margin-left: 15px">

                          </div>
                        </div>
                        <div class="filter">
                          <div class="filter-body columns">
                            <div class="column col-6 filter-item"  *ngFor="let company of companies">
                              <div class="card" (click)="companySelect(company)" class="{{company.id == newInvoice.orgId ? 'active': ''}}">
                                <div class="card-header text-center">
                                  <figure class="avatar bg-secondary">
                                    <img src="assets/img/{{company.icon}}">
                                  </figure>
                                </div>
                                <div class="card-body">
                                  <div class="tile-title text-center text-small">{{company.name.replace(' даатгал','')}}</div>
                                  <div class="tile-subtitle text-small text-center text-bold">{{company.total | currency:company.currency+' '}}</div>
                                  <div class="tile-subtitle text-small text-center text-gray">{{company.protect.coverage | currency:company.currency+' '}}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="pageId == 2 && selService.id == 5" class="column col-5 col-xs-12">
                    <div class="card" style="height: {{modalHeight}}px;">
                      <div class="card-body" style="overflow-y: scroll;">
                        <div class="divider" data-content="Даатгалын компаниуд"></div>
                        <div class="column col-3 col-sm-12" *ngIf="loading">
                          <div
                            class="loading float-left"
                            style="margin-left: 15px">

                          </div>
                        </div>
                        <div class="filter">
                          <div class="filter-body columns">
                            <div class="column col-6 filter-item"  *ngFor="let company of companies">
                              <div class="card" style="min-height: 211px;" (click)="companySelect(company)" class="{{company.id == newInvoice.orgId && company.total == newInvoice.amount ? 'active': ''}}">
                                <div class="card-header text-center">
                                  <figure class="avatar bg-secondary">
                                    <img src="assets/img/{{company.icon}}">
                                  </figure>
                                </div>
                                <div class="card-body">
                                  <div class="tile-title text-center text-small">{{company.name.replace(' даатгал','')}}</div>
                                  <div class="tile-subtitle text-small text-center text-bold">{{company.total | currency:company.currency+' '}}</div>
                                  <div class="tile-subtitle text-small text-center text-gray">{{localVisitor.coverage | currency:company.currency+' '}}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer">
                        <div class="float-right" *ngIf="listMode == 1">
                          <button class="btn btn-sm" (click)="tabIndex = 1;"> 1 </button>
                          <button class="btn btn-sm ml-1" (click)="tabIndex = 2;"> 2 </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="pageId == 3 && selService.id == 1 && (listMode == 2 || (listMode == 1 && tabIndex == 1))" class="column col-8 col-xs-12">
                    <div class="card" style="min-height: {{modalHeight}}px; overflow-y:scroll;">
                      <div class="card-body">
                        <span class="tile-subtitle text-bold text-small">Даатгуулагчдын мэдээлэл</span>

                        <div class="divider" *ngIf="age1.length>0" data-content="0-2 нас"></div>
                        <div class="columns" *ngFor="let age of age1; let i = index;" style="margin-bottom: 10px">
                          <div class="column col-12 col-xs-12">
                            <span class="label label-sm text-small text-bold">{{age.usd | currency:'USD'}}</span>
                          </div>
                          <div class="column col-6 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Регистр *</span>
                              <input class="form-input input-sm {{error['rd1'+i]}}" [(ngModel)]="age.rd" type="text" placeholder="" (change)="findUser(age, 1)" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                            </div>
                          </div>
                          <div class="column col-6 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Гадаад ПД *</span>
                              <input class="form-input input-sm" [(ngModel)]="age.ep" type="text" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                            </div>
                          </div>
                          <div class="column col-6 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Эцэг/эх *</span>
                              <input class="form-input input-sm {{error['ln1'+i]}}" [(ngModel)]="age.ln" type="text" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                            </div>
                          </div>
                          <div class="column col-6 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Нэр *</span>
                              <input class="form-input input-sm {{error['fn1'+i]}}" [(ngModel)]="age.fn" type="text" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                            </div>
                          </div>
                          <div class="column col-6 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Имейл</span>
                              <input class="form-input input-sm" [(ngModel)]="age.em" type="text" placeholder="" disabled="{{paylinks.qr != '' && age.status == 1}}">
                            </div>
                          </div>
                          <div class="column col-6 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Утас</span>
                              <input class="form-input input-sm" [(ngModel)]="age.ph" type="phone" placeholder="" style="width: 60%;" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                              <button *ngIf="payresp.invoiceNo" class="form-input btn btn-sm btn-block" style="width: 30px;" (click)="editInvoiceUser(age, i)"><i class="icon {{age.status != 2 ? 'icon-edit':'icon-check'}}"></i></button>
                              <button *ngIf="payresp.invoiceNo" class="form-input btn btn-sm btn-block" style="width: 30px;" (click)="removeInvoiceUser(age)"><i class="icon icon-delete"></i></button>
                              <button *ngIf="payresp.invoiceNo && age.status == 2" class="form-input btn btn-sm btn-block" style="width: 30px;" (click)="age.status = 1"><i class="icon icon-cross"></i></button>
                            </div>
                          </div>
                        </div>

                        <div class="divider" *ngIf="age2.length>0" data-content="3-15 нас"></div>
                        <div class="columns" *ngFor="let age of age2; let i = index;" style="margin-bottom: 10px">
                          <div class="column col-12 col-xs-12">
                            <span class="label label-sm text-small text-bold">{{age.usd | currency:'USD'}}</span>
                          </div>
                          <div class="column col-6 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Регистр *</span>
                              <input class="form-input input-sm {{error['rd2'+i]}}" [(ngModel)]="age.rd" type="text" placeholder="" (change)="findUser(age, 1)" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                            </div>
                          </div>
                          <div class="column col-6 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Гадаад ПД *</span>
                              <input class="form-input input-sm" [(ngModel)]="age.ep" type="text" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                            </div>
                          </div>
                          <div class="column col-6 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Эцэг/эх *</span>
                              <input class="form-input input-sm {{error['ln2'+i]}}" [(ngModel)]="age.ln" type="text" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                            </div>
                          </div>
                          <div class="column col-6 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Нэр *</span>
                              <input class="form-input input-sm {{error['fn2'+i]}}" [(ngModel)]="age.fn" type="text" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                            </div>
                          </div>
                          <div class="column col-6 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Имейл</span>
                              <input class="form-input input-sm" [(ngModel)]="age.em" type="text" placeholder="" disabled="{{paylinks.qr != '' && age.status == 1}}">
                            </div>
                          </div>
                          <div class="column col-6 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Утас</span>
                              <input class="form-input input-sm" [(ngModel)]="age.ph" type="phone" placeholder="" style="width: 60%;" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                              <button *ngIf="payresp.invoiceNo" class="form-input btn btn-sm btn-block" style="width: 30px;" (click)="editInvoiceUser(age, i)"><i class="icon {{age.status != 2 ? 'icon-edit':'icon-check'}}"></i></button>
                              <button *ngIf="payresp.invoiceNo" class="form-input btn btn-sm btn-block" style="width: 30px;" (click)="removeInvoiceUser(age)"><i class="icon icon-delete"></i></button>
                              <button *ngIf="payresp.invoiceNo && age.status == 2" class="form-input btn btn-sm btn-block" style="width: 30px;" (click)="age.status = 1"><i class="icon icon-cross"></i></button>
                            </div>
                          </div>
                        </div>

                        <div class="divider" *ngIf="age3.length>0" data-content="16-60 нас"></div>
                        <div class="columns" *ngFor="let age of age3; let i = index;" style="margin-bottom: 10px">
                          <div class="column col-12 col-xs-12">
                            <span class="label label-sm text-small text-bold">{{age.usd | currency:'USD'}}</span>
                          </div>
                          <div class="column col-6 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Регистр *</span>
                              <input class="form-input input-sm {{error['rd3'+i]}}" [(ngModel)]="age.rd" type="text" (change)="findUser(age, 2)" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                            </div>
                          </div>
                          <div class="column col-6 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Гадаад ПД *</span>
                              <input class="form-input input-sm" [(ngModel)]="age.ep" type="text" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                            </div>
                          </div>
                          <div class="column col-6 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Эцэг/эх *</span>
                              <input class="form-input input-sm {{error['ln3'+i]}}" [(ngModel)]="age.ln" type="text" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                            </div>
                          </div>
                          <div class="column col-6 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Нэр *</span>
                              <input class="form-input input-sm {{error['fn3'+i]}}"  [(ngModel)]="age.fn" type="text" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                            </div>
                          </div>
                          <div class="column col-6 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Имейл</span>
                              <input class="form-input input-sm" [(ngModel)]="age.em" type="text" placeholder="" disabled="{{paylinks.qr != '' && age.status == 1}}">
                            </div>
                          </div>
                          <div class="column col-6 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Утас</span>
                              <input class="form-input input-sm" [(ngModel)]="age.ph" style="width: 60%;" type="phone" style="width: 60%;" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                              <button *ngIf="payresp.invoiceNo && age.id > 0" class="form-input btn btn-sm btn-block" style="width: 30px;" (click)="editInvoiceUser(age, i)"><i class="icon {{age.status != 2?'icon-edit':'icon-check'}}"></i></button>
                              <button *ngIf="payresp.invoiceNo" class="form-input btn btn-sm btn-block" style="width: 30px;" (click)="removeInvoiceUser(age)"><i class="icon icon-delete"></i></button>
                              <button *ngIf="payresp.invoiceNo && age.status == 2" class="form-input btn btn-sm btn-block" style="width: 30px;" (click)="age.status = 1"><i class="icon icon-cross"></i></button>
                            </div>
                          </div>
                          <div class="column col-12">
                            <div class="divider"></div>
                          </div>
                        </div>

                        <div class="divider" *ngIf="age4.length>0" data-content="60-69 нас"></div>
                        <div class="columns" *ngFor="let age of age4; let i = index;" style="margin-bottom: 10px">
                          <div class="column col-12 col-xs-12">
                            <span class="label label-sm text-small text-bold">{{age.usd | currency:'USD'}}</span>
                          </div>
                          <div class="column col-6 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Регистр *</span>
                              <input class="form-input input-sm {{error['rd4'+i]}}" [(ngModel)]="age.rd" type="text" (change)="findUser(age, 2)" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                            </div>
                          </div>
                          <div class="column col-6 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Гадаад ПД *</span>
                              <input class="form-input input-sm" [(ngModel)]="age.ep" type="text" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                            </div>
                          </div>
                          <div class="column col-6 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Эцэг/эх *</span>
                              <input class="form-input input-sm {{error['ln4'+i]}}" [(ngModel)]="age.ln" type="text" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                            </div>
                          </div>
                          <div class="column col-6 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Нэр *</span>
                              <input class="form-input input-sm {{error['fn4'+i]}}"  [(ngModel)]="age.fn" type="text" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                            </div>
                          </div>
                          <div class="column col-6 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Имейл</span>
                              <input class="form-input input-sm" [(ngModel)]="age.em" type="text" placeholder="" disabled="{{paylinks.qr != '' && age.status == 1}}">
                            </div>
                          </div>
                          <div class="column col-6 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Утас</span>
                              <input class="form-input input-sm" [(ngModel)]="age.ph" style="width: 60%;" type="phone" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                              <button *ngIf="payresp.invoiceNo && age.id > 0" class="form-input btn btn-sm btn-block" style="width: 30px;" (click)="editInvoiceUser(age, i)"><i class="icon {{age.status != 2?'icon-edit':'icon-check'}}"></i></button>
                              <button *ngIf="payresp.invoiceNo" class="form-input btn btn-sm btn-block" style="width: 30px;" (click)="removeInvoiceUser(age)"><i class="icon icon-delete"></i></button>
                              <button *ngIf="payresp.invoiceNo && age.status == 2" class="form-input btn btn-sm btn-block" style="width: 30px;" (click)="age.status = 1"><i class="icon icon-cross"></i></button>
                            </div>
                          </div>
                          <div class="column col-12">
                            <div class="divider"></div>
                          </div>
                        </div>

                        <div class="divider" *ngIf="age5.length>0" data-content="70-79"></div>
                        <div class="columns" *ngFor="let age of age5; let i = index;" style="margin-bottom: 10px">
                          <div class="column col-12 col-xs-12">
                            <span class="label label-sm text-small text-bold">{{age.usd | currency:'USD'}}</span>
                          </div>
                          <div class="column col-6 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Регистр *</span>
                              <input class="form-input input-sm {{error['rd5'+i]}}" [(ngModel)]="age.rd" type="text" placeholder="" (change)="findUser(age, 3)" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                            </div>
                          </div>
                          <div class="column col-6 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Гадаад ПД *</span>
                              <input class="form-input input-sm" [(ngModel)]="age.ep" type="text" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                            </div>
                          </div>
                          <div class="column col-6 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Эцэг/эх *</span>
                              <input class="form-input input-sm {{error['ln5'+i]}}" [(ngModel)]="age.ln" type="text" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                            </div>
                          </div>
                          <div class="column col-6 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Нэр *</span>
                              <input class="form-input input-sm {{error['fn5'+i]}}" [(ngModel)]="age.fn" type="text" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                            </div>
                          </div>
                          <div class="column col-6 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Имейл</span>
                              <input class="form-input input-sm" [(ngModel)]="age.em" type="text" placeholder="" disabled="{{paylinks.qr != '' && age.status == 1}}">
                            </div>
                          </div>
                          <div class="column col-6 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Утас</span>
                              <input class="form-input input-sm" [(ngModel)]="age.ph" type="phone" style="width: 60%;"  placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                              <button *ngIf="payresp.invoiceNo" class="form-input btn btn-sm btn-block" style="width: 30px;" (click)="editInvoiceUser(age, i)"><i class="icon {{age.status != 2 ? 'icon-edit':'icon-check'}}"></i></button>
                              <button *ngIf="payresp.invoiceNo" class="form-input btn btn-sm btn-block" style="width: 30px;" (click)="removeInvoiceUser(age)"><i class="icon icon-delete"></i></button>
                              <button *ngIf="payresp.invoiceNo && age.status == 2" class="form-input btn btn-sm btn-block" style="width: 30px;" (click)="age.status = 1"><i class="icon icon-cross"></i></button>
                            </div>
                          </div>
                        </div>

                        <div class="divider" *ngIf="age12.length>0" data-content="80-c дээш"></div>
                        <div class="columns" *ngFor="let age of age12; let i = index;" style="margin-bottom: 10px">
                          <div class="column col-12 col-xs-12">
                            <span class="label label-sm text-small text-bold">{{age.usd | currency:'USD'}}</span>
                          </div>
                          <div class="column col-6 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Регистр *</span>
                              <input class="form-input input-sm {{error['rd12'+i]}}" [(ngModel)]="age.rd" type="text" placeholder="" (change)="findUser(age, 12)" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                            </div>
                          </div>
                          <div class="column col-6 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Гадаад ПД *</span>
                              <input class="form-input input-sm" [(ngModel)]="age.ep" type="text" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                            </div>
                          </div>
                          <div class="column col-6 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Эцэг/эх *</span>
                              <input class="form-input input-sm {{error['ln12'+i]}}" [(ngModel)]="age.ln" type="text" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                            </div>
                          </div>
                          <div class="column col-6 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Нэр *</span>
                              <input class="form-input input-sm {{error['fn12'+i]}}" [(ngModel)]="age.fn" type="text" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                            </div>
                          </div>
                          <div class="column col-6 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Имейл</span>
                              <input class="form-input input-sm" [(ngModel)]="age.em" type="text" placeholder="" disabled="{{paylinks.qr != '' && age.status == 1}}">
                            </div>
                          </div>
                          <div class="column col-6 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Утас</span>
                              <input class="form-input input-sm" [(ngModel)]="age.ph" type="phone" style="width: 60%;"  placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                              <button *ngIf="payresp.invoiceNo" class="form-input btn btn-sm btn-block" style="width: 30px;" (click)="editInvoiceUser(age, i)"><i class="icon {{age.status != 2 ? 'icon-edit':'icon-check'}}"></i></button>
                              <button *ngIf="payresp.invoiceNo" class="form-input btn btn-sm btn-block" style="width: 30px;" (click)="removeInvoiceUser(age)"><i class="icon icon-delete"></i></button>
                              <button *ngIf="payresp.invoiceNo && age.status == 2" class="form-input btn btn-sm btn-block" style="width: 30px;" (click)="age.status = 1"><i class="icon icon-cross"></i></button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer">
                        <div class="float-right" *ngIf="listMode == 1">
                          <button class="btn btn-sm {{tabIndex == 1 ? 'btn-primary':''}}" (click)="tabIndex = 1;"> 1 </button>
                          <button class="btn btn-sm ml-1 {{tabIndex == 2 ? 'btn-primary':''}}" (click)="tabIndex = 2;"> 2 </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="pageId == 3 && selService.id == 1 && (listMode == 2 || (listMode == 1 && tabIndex == 2))" class="column col-4 col-xs-12">
                    <div class="card" style="height: {{modalHeight}}px;">
                      <div class="card-body">
                          <div class="divider" data-content="Төлбөрийн нэхэмжлэх"></div>
                          <img style="width: 180px; margin: 0px;" src="data:image/png;base64, {{paylinks.qr}}" *ngIf="paylinks.qr != '' && paylinks.qr"/>

                          <div class="p-1" *ngIf="paylinks.qr != ''">
                            <div class="tile-title text-small text-bold">{{payresp.orgName}}</div>
                            <div class="tile-title text-small" *ngIf="payresp.startDate">{{payresp.startDate | date:'yyyy-MM-dd'}} / {{payresp.endDate | date:'yyyy-MM-dd'}}</div>
                            <div class="tile-title text-small">{{paylinks.unit}} (хүн) / {{payresp.days}} (өдөр)</div>
                            <div class="tile-title text-small">
                              <span *ngFor="let c of payresp.countries">{{c.name}}, </span>
                            </div>

                            <div class="tile-title text-small">{{paylinks.bankName}} / {{paylinks.accountName}} / {{paylinks.accountNo}}</div>
                            <div class="tile-title text-small text-bold">{{paylinks.payNo}} (гүйлгээний утга)</div>

                            <div class="tile-title text-small">{{paylinks.amountUSD | currency : '$'}} (Ханш : {{paylinks.rate | currency : '₮'}})</div>

                            <div class="tile-title text-bold">{{paylinks.finalAmount | currency : '₮'}}
                            <span *ngIf="paylinks.percent>0">/ {{paylinks.amount | currency : '₮'}} ({{paylinks.percent}}%)</span></div>
                            <div class="tile-title {{payresp.payStatus == 2 ? 'text-success':'text-warning'}} text-small">{{payresp.payStatus == 2 ? 'Төлөгдсөн' : paylinks.msg}}</div>
                            <button class="btn btn-block btn-sm mt-2 col-12" *ngIf="!payLoading1" (click)="downloadInvoice(paylinks.invoiceNo)">Нэхэмжлэх татах</button>
                            <div class="clearfix"></div>
                            <div class="columns">
                              <div class="column col-6 pr-1"><button class="btn btn-primary btn-sm mt-1 col-12" (click)="payCheck()">Төлөлт шалгах</button></div>
                              <div class="column col-6 pl-1"><button class="btn btn-error btn-sm mt-1 col-12" (click)="payPostpaid()">Дараа төлөх</button></div>
                            </div>
                            <div *ngIf="payLoading1">
                              <div class="loading float-left" style="margin: 14px;"></div>
                            </div>
                          </div>
                          <div *ngIf="loading || payLoading">
                            <div class="loading float-left" style="margin: 15px;"></div>
                          </div>
                      </div>
                      <div class="card-footer">
                        <div class="float-right" *ngIf="listMode == 1">
                          <button class="btn btn-sm {{tabIndex == 1 ? 'btn-error':''}}" (click)="tabIndex = 1;"> 1 </button>
                          <button class="btn btn-sm ml-1 {{tabIndex == 2 ? 'btn-error':''}}" (click)="tabIndex = 2;"> 2 </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="pageId == 3 && selService.id == 2" class="column col-8">
                    <div class="card">
                      <div class="card-body" style="height: 680px; overflow-y:scroll;">
                        <div class="divider" *ngIf="newInvoice.properties.length>0" data-content="Хөрөнгийн мэдээлэл"></div>
                        <div class="columns" *ngFor="let prop of newInvoice.properties; let i = index;" style="margin-bottom: 10px">
                          <div class="column col-5">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 110px;">Гэрчилгээ №:</span>
                              <span class="form-input input-sm">{{prop.regno}}</span>
                            </div>
                          </div>
                          <div class="column col-3">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 65px;">Талбай:</span>
                              <span class="form-input input-sm">{{prop.size}} м2</span>
                            </div>
                          </div>
                          <div class="column col-4">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 60px;">Төрөл:</span>
                              <span class="form-input input-sm">{{propertyCat(prop.cat)}}</span>
                            </div>
                          </div>
                          <div class="column col-6">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 130px;">Ашиглалтанд:</span>
                              <span class="form-input input-sm">{{prop.propYear}}</span>
                            </div>
                          </div>
                          <div class="column col-6">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 80px;">Үнэлгээ:</span>
                              <span class="form-input input-sm">{{prop.amount | currency : '₮ '}}</span>
                            </div>
                          </div>
                          <div class="column col-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 60px;">Хаяг:</span>
                              <span class="form-input input-sm">{{prop.address}}</span>
                            </div>
                          </div>
                        </div>

                        <div class="divider" data-content="Эзэмшигчдийн мэдээлэл"></div>
                        <div class="columns" *ngFor="let user of owners; let i = index;">
                          <div class="column col-6">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Регистр №:</span>
                              <input class="form-input input-sm {{error['rd1'+i]}}" [(ngModel)]="user.rd" type="text" placeholder=""  toUppercase disabled="{{paylinks.qr}}">
                            </div>
                          </div>
                          <div class="column col-6">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Эцэг/эх нэр:</span>
                              <input class="form-input input-sm" [(ngModel)]="user.ln" type="text" placeholder="" toUppercase disabled="{{paylinks.qr}}">
                            </div>
                          </div>
                          <div class="column col-6">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Нэр:</span>
                              <input class="form-input input-sm" [(ngModel)]="user.fn" type="text" placeholder="" toUppercase disabled="{{paylinks.qr}}">
                            </div>
                          </div>
                          <div class="column col-6">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Имэйл:</span>
                              <input class="form-input input-sm {{error['em1'+i]}}" [(ngModel)]="user.em" type="email" placeholder="" toUppercase disabled="{{paylinks.qr}}">
                            </div>
                          </div>
                          <div class="column col-6 input-group">
                            <span class="input-group-addon addon-sm" style="width: 80px;">Утас:</span>
                            <input class="form-input input-sm" type="text" value="" [(ngModel)]="user.ph" toUppercase disabled="{{paylinks.qr}}"/>
                          </div>
                          <div class="column col-6 input-group">
                            <span class="input-group-addon addon-sm" style="width: 90px;">Эзэмшигч:</span>
                            <select class="form-select input-group select-sm" [(ngModel)]="user.ownerType" disabled="{{paylinks.qr}}">
                              <option value="1" [ngValue]="1">Тийм</option>
                              <option value="2" [ngValue]="2">Үгүй</option>
                            </select>
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>

                  <div *ngIf="pageId == 3 && selService.id == 2" class="column col-4">
                    <div class="card">
                      <div class="card-body" style="height: 680px;">
                        <div class="divider" data-content="Төлбөрийн нэхэмжлэх"></div>
                        <img style="width: 180px; margin: 0px;" src="data:image/png;base64, {{paylinks.qr}}" *ngIf="paylinks.qr != ''"/>

                        <div class="p-1" *ngIf="paylinks.qr != ''">
                          <div class="tile-title text-small text-bold">{{payresp.orgName}}</div>
                          <div class="tile-title text-small" *ngIf="payresp.startDate">{{payresp.startDate | date:'yyyy-MM-dd'}} / {{payresp.endDate | date:'yyyy-MM-dd'}}</div>
                          <div class="tile-title text-small">{{paylinks.unit}} (хүн) / {{payresp.days}} (өдөр)</div>
                          <div class="tile-title text-small">
                            <span *ngFor="let c of payresp.countries">{{c.name}}, </span>
                          </div>

                          <div class="tile-title text-small">{{paylinks.bankName}} / {{paylinks.accountName}} / {{paylinks.accountNo}}</div>
                          <div class="tile-title text-small text-bold">{{paylinks.payNo}} (гүйлгээний утга)</div>

                          <div class="tile-title text-small">{{paylinks.amountUSD | currency : '$'}} (Ханш : {{paylinks.rate | currency : '₮'}})</div>

                          <div class="tile-title text-bold">{{paylinks.finalAmount | currency : '₮'}}
                            <span *ngIf="paylinks.percent>0">/ {{paylinks.amount | currency : '₮'}} ({{paylinks.percent}}%)</span></div>
                          <div class="tile-title {{payresp.payStatus == 2 ? 'text-success':'text-warning'}} text-small">{{payresp.payStatus == 2 ? 'Төлөгдсөн' : paylinks.msg}}</div>
                          <button class="btn btn-block btn-sm mt-2 col-12" *ngIf="!payLoading1" (click)="downloadInvoice(paylinks.invoiceNo)">Нэхэмжлэх татах</button>
                          <div class="clearfix"></div>
                          <div class="columns">
                            <div class="column col-6 pr-1"><button class="btn btn-primary btn-sm mt-1 col-12" (click)="payCheck()">Төлөлт шалгах</button></div>
                            <div class="column col-6 pl-1"><button class="btn btn-error btn-sm mt-1 col-12" (click)="payPostpaid()">Дараа төлөх</button></div>
                          </div>
                          <div *ngIf="payLoading1">
                            <div class="loading float-left" style="margin: 14px;"></div>
                          </div>
                        </div>
                        <div *ngIf="loading || payLoading">
                          <div class="loading float-left" style="margin: 15px;"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="pageId == 3 && selService.id == 3 && (listMode == 2 || (listMode == 1 && tabIndex == 1))" class="column col-8 col-xs-12">
                    <div class="card" style="height:{{modalHeight}}px;">
                      <div class="card-body" style="overflow-y:scroll;">
                        <div class="divider" *ngIf="newInvoice.properties.length>0" data-content="Тээврийн хэрэгслийн мэдээлэл"></div>
                        <div class="columns" *ngFor="let prop of newInvoice.properties; let i = index;" style="margin-bottom: 10px">
                          <div class="column col-3 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 80px;">Улсын №:</span>
                              <input type="text" class="form-input input-sm" [(ngModel)]="prop.regno"/>
                            </div>
                          </div>
                          <div class="column col-4 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 85px;">Арлын №:</span>
                              <span class="form-input input-sm disabled">{{prop.vin}}</span>
                            </div>
                          </div>
                          <div class="column col-5 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 120px;">Марк/Модел:</span>
                              <span class="form-input input-sm disabled">{{prop.mark}}/{{prop.model}}</span>
                            </div>
                          </div>
                          <div class="column col-4 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 80px;">Үнэлгээ:</span>
                              <span class="form-input input-sm">{{prop.amount | currency : '₮ '}}</span>
                            </div>
                          </div>
                          <div class="column col-3 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 60px;">Эхлэх:</span>
                              <span class="form-input input-sm">{{newInvoice.startDate | date : 'yyyy-MM-dd'}}</span>
                            </div>
                          </div>
                          <div class="column col-3 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 80px;">Хугацаа:</span>
                              <span class="form-input input-sm disabled">{{newInvoice.days}} өдөр</span>
                            </div>
                          </div>
                          <div class="column col-2 col-xs-12">
                            <button class="btn btn-sm btn-primary input-group-btn" (click)="propUpdate(prop)" disabled="{{newInvoice.id > 0}}">Шинэчлэх</button>
                          </div>
                        </div>

                        <div class="divider" data-content="Өмчлөгч, эзэмшигчийн мэдээлэл"></div>
                        <div class="columns">
                          <div class="column col-6">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 150px;">Даатгуулах хэлбэр:</span>
                              <select class="form-select input-group select-sm" [(ngModel)]="insureType" (change)="setInsureType()">
                                <option value="1" [ngValue]="1">Хувь хүн дээрээ</option>
                                <option value="2" [ngValue]="2">Байгууллага дээр</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="columns" *ngFor="let user of owners; let i = index;">
                          <div class="column col-4 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 40px;">РД:</span>
                              <input class="form-input input-sm {{error['rd1'+i]}}" [(ngModel)]="user.rd" (change)="regexRegnoChange(user.rd, i, user)" type="text" placeholder=""  toUppercase disabled="{{newInvoice.id > 0}}">
                            </div>
                          </div>
                          <div class="column col-4 col-xs-12" *ngIf="user.rd.length != 7">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 60px;">Овог:</span>
                              <input class="form-input input-sm {{error['ln1'+i]}}" [(ngModel)]="user.ln" type="text" (change)="regexCyrillChange(user.ln, 'ln1'+i)" placeholder="" toUppercase disabled="{{newInvoice.id > 0}}">
                            </div>
                          </div>
                          <div class="column col-4 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 60px;">Нэр:</span>
                              <input class="form-input input-sm {{error['fn1'+i]}}" [(ngModel)]="user.fn" type="text" (change)="regexCyrillChange(user.fn, 'fn1'+i)" placeholder="" toUppercase disabled="{{newInvoice.id > 0}}">
                            </div>
                          </div>
                          <div class="column col-4 col-xs-12">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 65px;">Имэйл:</span>
                              <input class="form-input input-sm {{error['em1'+i]}}" [(ngModel)]="user.em" type="email" placeholder="" toUppercase>
                            </div>
                          </div>
                          <div class="column col-4 col-xs-12">
                            <div class="input-group">
                            <span class="input-group-addon addon-sm" style="width: 60px;">Утас*:</span>
                            <input class="form-input input-sm" type="number" value="" [(ngModel)]="user.ph"/>
                            </div>
                          </div>
                          <div class="column col-4 input-group col-xs-12">
                            <span class="input-group-addon addon-sm" style="width: 60px;">Төрөл:</span>
                            <select class="form-select input-group select-sm" [(ngModel)]="user.ownerType" disabled>
                              <option value="1" [ngValue]="1" value="1">Эзэмшигч</option>
                              <option value="2" [ngValue]="2" value="2">Өмчлөгч</option>
                            </select>
                          </div>
                          <div class="column col-12 col-xs-12" *ngIf="i == 0 && newInvoice.properties[0].ownerType == 'Хувь хүн'">
                            <label class="form-switch text-small">
                              <input type="checkbox" (change)="setOwnerGetter()">
                              <i class="form-icon"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Эзэмшигч өмчлөгч ижил
                            </label>
                          </div>
                          <div class="column col-12 col-xs-12" *ngIf="errorText != '' && i == owners.length - 1">
                            <span class="text-error text-small text-italic">
                              * {{errorText}}
                            </span>
                          </div>
                        </div>

                        <div class="columns">
                          <div class="column col-12 col-xs-12">
                            <div class="divider text-center" data-content="ЗУРАГУУД"></div>
                            <div class="input-group">
                              <input class="form-input input-group-addon addon-sm input-sm" type="file"
                                     multiple (change)="selectImageFiles($event)">
                              <button class="btn btn-primary input-group-btn" (click)="uploadPics()">Хадгалах</button>
                            </div>


                            <div class="columns">
                              <div class="column col-12 col-xs-12">
                                <div class="filter">
                                  <div class="filter-body columns">
                                    <div class="column col-4 filter-item" *ngFor="let img of fileInfos" >
                                      <div class="card">
                                        <div class="card-image" (click)="openImage(img)"><img class="img-responsive" src="p/img/{{img.url}}" style="height: 120px !important;"></div>
                                        <div class="card-body p-1"><a class="btn btn-sm btn-primary" (click)="deleteImage(img)">Устгах</a></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer">
                        <div class="float-right" *ngIf="listMode == 1">
                          <button class="btn btn-sm" (click)="tabIndex = 1;"> 1 </button>
                          <button class="btn btn-sm ml-1" (click)="tabIndex = 2;"> 2 </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="pageId == 3 && selService.id == 3 && (listMode == 2 || (listMode == 1 && tabIndex == 2))" class="column col-4 col-xs-12">
                    <div class="card" style="height:{{modalHeight}}px;">
                      <div class="card-body">
                        <div class="divider" data-content="Төлбөрийн нэхэмжлэх"></div>
                        <img style="width: 180px; margin: 0px;" src="data:image/png;base64, {{paylinks.qr}}" *ngIf="paylinks.qr != ''"/>

                        <div class="p-1" *ngIf="paylinks.qr != ''">
                          <div class="tile-title text-small text-bold">{{payresp.orgName}}</div>
                          <div class="tile-title text-small" *ngIf="payresp.startDate">{{payresp.startDate | date:'yyyy-MM-dd'}} / {{payresp.endDate | date:'yyyy-MM-dd'}}</div>
                          <div class="tile-title text-small">{{paylinks.unit}} (хүн) / {{payresp.days}} (өдөр)</div>
                          <div class="tile-title text-small">
                            <span *ngFor="let c of payresp.countries">{{c.name}}, </span>
                          </div>

                          <div class="tile-title text-small">{{paylinks.bankName}} / {{paylinks.accountName}} / {{paylinks.accountNo}}</div>
                          <div class="tile-title text-small text-bold">{{paylinks.payNo}} (гүйлгээний утга)</div>

                          <div class="tile-title text-small">{{paylinks.amountUSD | currency : '$'}} (Ханш : {{paylinks.rate | currency : '₮'}})</div>

                          <div class="tile-title text-bold">{{paylinks.finalAmount | currency : '₮'}}
                            <span *ngIf="paylinks.percent>0">/ {{paylinks.amount | currency : '₮'}} ({{paylinks.percent}}%)</span></div>
                          <div class="tile-title {{payresp.payStatus == 2 ? 'text-success':'text-warning'}} text-small">{{payresp.payStatus == 2 ? 'Төлөгдсөн' : paylinks.msg}}</div>
                          <button class="btn btn-block btn-sm mt-2 col-12" *ngIf="!payLoading1" (click)="downloadInvoice(paylinks.invoiceNo)">Нэхэмжлэх татах</button>
                          <div class="clearfix"></div>
                          <div class="columns">
                            <div class="column col-6 pr-1"><button class="btn btn-primary btn-sm mt-1 col-12" (click)="payCheck()">Төлөлт шалгах</button></div>
                            <div class="column col-6 pl-1"><button class="btn btn-error btn-sm mt-1 col-12" (click)="payPostpaid()">Дараа төлөх</button></div>


                            <div class="column col-12 pl-2" *ngIf="isBundle"><button class="btn btn-success btn-sm  col-12" (click)="bundleForward()">Нэмэлт даатгал руу шилжих</button></div>
                          </div>
                          <div *ngIf="payLoading1">
                            <div class="loading float-left" style="margin: 14px;"></div>
                          </div>
                        </div>
                        <div *ngIf="loading || payLoading">
                          <div class="loading float-left" style="margin: 15px;"></div>
                        </div>
                      </div>
                      <div class="card-footer">
                        <div class="float-right" *ngIf="listMode == 1">
                          <button class="btn btn-sm" (click)="tabIndex = 1;"> 1 </button>
                          <button class="btn btn-sm ml-1" (click)="tabIndex = 2;"> 2 </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="pageId == 3 && selService.id == 4" class="column col-8">
                    <div class="card">
                      <div class="card-body" style="height: 680px; overflow-y:scroll;">
                        <div class="divider" data-content="Тээврийн хэрэгслийн мэдээлэл" *ngIf="newInvoice.properties && newInvoice.properties.length > 0"></div>
                        <div class="columns" *ngFor="let prop of newInvoice.properties">
                          <div class="column col-6">
                            <div class="input-group">
                              <span class="input-group-addon addon-sm" style="width: 130px;">Улсын № :</span>
                              <span class="form-input input-sm">{{prop.regno}}</span>
                            </div>
                          </div>
                          <div class="column col-6">
                            <div class="input-group">
                              <span class="input-group-addon addon-sm" style="width: 130px;">Марк, модел:</span>
                              <span class="form-input input-sm">{{prop.mark+'/'+prop.model}}</span>
                            </div>
                          </div>
                          <div class="column col-6">
                            <div class="input-group">
                              <span class="input-group-addon addon-sm" style="width: 130px;">Ангилал :</span>
                              <span class="form-input input-sm">{{prop.cat}}</span>
                            </div>
                          </div>
                          <div class="column col-6">
                            <div class="input-group">
                              <span class="input-group-addon addon-sm" style="width: 130px;">Арлын № :</span>
                              <span class="form-input input-sm">{{prop.vin}}</span>
                            </div>
                          </div>
                          <div class="column col-6">
                            <div class="input-group">
                              <span class="input-group-addon addon-sm" style="width: 130px;">Марк, модел:</span>
                              <span class="form-input input-sm">{{prop.mark+'/'+prop.model}}</span>
                            </div>
                          </div>
                          <div class="column col-6">
                            <div class="input-group">
                              <span class="input-group-addon addon-sm" style="width: 130px;">Өнгө :</span>
                              <span class="form-input input-sm">{{prop.color}}</span>
                            </div>
                          </div>
                          <div class="column col-6">
                            <div class="input-group">
                              <span class="input-group-addon addon-sm" style="width: 130px;">Он :</span>
                              <span class="form-input input-sm">{{prop.propYear}}</span>
                            </div>
                          </div>
                          <div class="column col-6">
                            <div class="input-group">
                              <span class="input-group-addon addon-sm" style="width: 130px;">Орж ирсэн :</span>
                              <span class="form-input input-sm">{{prop.mnYear}}</span>
                            </div>
                          </div>
                        </div>

                        <div class="divider" data-content="Жолоочийн мэдээлэл"></div>

                        <div class="columns" *ngFor="let user of owners; let i = index;" style="margin-bottom: 10px">
                          <div class="column col-6">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 130px;">Регистр № *:</span>
                              <input class="form-input input-sm {{error['rd1'+i]}}" [(ngModel)]="user.rd"  (change)="findUser(user, 1)" type="text" placeholder="" style="width:120px;" toUppercase disabled="{{i == 0}}">
                              <button *ngIf="i>0" class="form-input btn btn-sm btn-error btn-block" style="width: 30px;" (click)="removeOwner(user)"><i class="icon icon-cross"></i></button>
                            </div>
                          </div>
                          <div class="column col-6">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 130px;">Үнэмлэх № *:</span>
                              <input class="form-input input-sm {{error['rd1'+i]}}" [(ngModel)]="user.dl" type="text" placeholder=""  toUppercase disabled="{{i == 0}}">
                            </div>
                          </div>
                          <div class="column col-6">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 130px;">Овог *:</span>
                              <input class="form-input input-sm" [(ngModel)]="user.ln" type="text" placeholder="" toUppercase disabled="{{i == 0}}">
                            </div>
                          </div>
                          <div class="column col-6">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 130px;">Нэр *:</span>
                              <input class="form-input input-sm" [(ngModel)]="user.fn" type="text" placeholder="" toUppercase disabled="{{i == 0}}">
                            </div>
                          </div>
                          <div class="column col-6">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 130px;">Утас *:</span>
                              <input class="form-input input-sm {{error['ph1'+i]}}" [(ngModel)]="user.ph" type="text" placeholder="" toUppercase disabled="{{i == 0}}">
                            </div>
                          </div>
                          <div class="column col-6">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 130px;">Имэйл:</span>
                              <input class="form-input input-sm {{error['em1'+i]}}" [(ngModel)]="user.em" type="email" placeholder="" toUppercase disabled="{{i == 0}}">
                            </div>
                          </div>
                          <div class="column col-8">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 130px;">Туршлага *:</span>
                              <input class="form-input input-sm {{error['tr1'+i]}}" [(ngModel)]="user.tr" type="number" toUppercase disabled="{{i == 0}}">

                              <select class="form-select select-sm" style="width: 80px;" value="1" [(ngModel)]="user.ownerType">
                                <option value="1">Үндсэн</option>
                                <option value="2">Хамтран</option>
                                <option value="3">Эрх хязгаарлагдсан</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <button class="btn btn-sm btn-primary" (click)="pushOwner()" *ngIf="! newInvoice.id">Нэмэх</button>
                        <span class="text-small text-error text-italic ml-2" *ngIf="! newInvoice.id">* Нэмэлтээр хамруулах иргэн</span>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="pageId == 3 && selService.id == 4" class="column col-4">
                    <div class="card">
                      <div class="card-body" style="height: 680px;">
                        <div class="divider" data-content="Төлбөрийн нэхэмжлэх"></div>
                        <img style="width: 180px; margin: 0px;" src="data:image/png;base64, {{paylinks.qr}}" *ngIf="paylinks.qr != ''"/>

                        <div class="p-1" *ngIf="paylinks.qr != ''">
                          <div class="tile-title text-small text-bold">{{payresp.orgName}}</div>
                          <div class="tile-title text-small" *ngIf="payresp.startDate">{{payresp.startDate | date:'yyyy-MM-dd'}} / {{payresp.endDate | date:'yyyy-MM-dd'}}</div>
                          <div class="tile-title text-small">{{paylinks.unit}} (хүн) / {{payresp.days}} (өдөр)</div>
                          <div class="tile-title text-small">
                            <span *ngFor="let c of payresp.countries">{{c.name}}, </span>
                          </div>

                          <div class="tile-title text-small">{{paylinks.bankName}} / {{paylinks.accountName}} / {{paylinks.accountNo}}</div>
                          <div class="tile-title text-small text-bold">{{paylinks.payNo}} (гүйлгээний утга)</div>

                          <div class="tile-title text-small">{{paylinks.amountUSD | currency : '$'}} (Ханш : {{paylinks.rate | currency : '₮'}})</div>

                          <div class="tile-title text-bold">{{paylinks.finalAmount | currency : '₮'}}
                            <span *ngIf="paylinks.percent>0">/ {{paylinks.amount | currency : '₮'}} ({{paylinks.percent}}%)</span></div>
                          <div class="tile-title {{payresp.payStatus == 2 ? 'text-success':'text-warning'}} text-small">{{payresp.payStatus == 2 ? 'Төлөгдсөн' : paylinks.msg}}</div>
                          <button class="btn btn-block btn-sm mt-2 col-12" *ngIf="!payLoading1" (click)="downloadInvoice(paylinks.invoiceNo)">Нэхэмжлэх татах</button>
                          <div class="clearfix"></div>
                          <div class="columns">
                            <div class="column col-6 pr-1"><button class="btn btn-primary btn-sm mt-1 col-12" (click)="payCheck()">Төлөлт шалгах</button></div>
                            <div class="column col-6 pl-1"><button class="btn btn-error btn-sm mt-1 col-12" (click)="payPostpaid()">Дараа төлөх</button></div>
                          </div>
                          <div *ngIf="payLoading1">
                            <div class="loading float-left" style="margin: 14px;"></div>
                          </div>
                        </div>
                        <div *ngIf="loading || payLoading">
                          <div class="loading float-left" style="margin: 15px;"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="pageId == 3 && selService.id == 5" class="column col-8">
                    <div class="card">
                      <div class="card-body" style="height: 600px; overflow-y:scroll;">
                        <div class="divider" data-content="Даатгуулагчийн мэдээлэл"></div>

                        <div class="columns" style="margin-bottom: 10px">
                          <div class="column col-6">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 130px;">{{newInvoice.region == 'A1'?'Регистр №:':'Пасспорт №:'}}</span>
                              <input class="form-input input-sm" [(ngModel)]="newInvoice.local.passNo" type="text" placeholder="" style="width:120px;" toUppercase disabled>
                            </div>
                          </div>
                          <div class="column col-6">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 130px;">Овог :</span>
                              <input class="form-input input-sm" [(ngModel)]="newInvoice.local.lastName" type="text" placeholder="" toUppercase disabled>
                            </div>
                          </div>
                          <div class="column col-6">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 130px;">Нэр :</span>
                              <input class="form-input input-sm" [(ngModel)]="newInvoice.local.firstName" type="text" placeholder="" toUppercase disabled>
                            </div>
                          </div>
                          <div class="column col-6">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 130px;">Утас :</span>
                              <input class="form-input input-sm" [(ngModel)]="newInvoice.local.phoneNo" type="text" placeholder="" toUppercase disabled>
                            </div>
                          </div>
                          <div class="column col-6">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 130px;">Төрсөн огноо:</span>
                              <input class="form-input input-sm" [(ngModel)]="newInvoice.local.birthDate" type="email" placeholder="" toUppercase disabled>
                            </div>
                          </div>
                          <div class="column col-6">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 160px;">Хамгаалалтын дүн:</span>
                              <span class="form-input input-sm">{{newInvoice.local.coverage | currency: 'MNT '}}</span>
                            </div>
                          </div>
                          <div class="column col-6">
                            <div class="input-group"><span class="input-group-addon addon-sm" style="width: 190px;">Даатгалын хугацаа (өдөр):</span>
                              <span class="form-input input-sm">{{newInvoice.days}}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="pageId == 3 && selService.id == 5" class="column col-4">
                    <div class="card">
                      <div class="card-body" style="height: 680px;">
                        <div class="divider" data-content="Төлбөрийн нэхэмжлэх"></div>
                        <img style="width: 180px; margin: 0px;" src="data:image/png;base64, {{paylinks.qr}}" *ngIf="paylinks.qr != ''"/>

                        <div class="p-1" *ngIf="paylinks.qr != ''">
                          <div class="tile-title text-small text-bold">{{payresp.orgName}}</div>
                          <div class="tile-title text-small" *ngIf="payresp.startDate">{{payresp.startDate | date:'yyyy-MM-dd'}} / {{payresp.endDate | date:'yyyy-MM-dd'}}</div>
                          <div class="tile-title text-small">{{paylinks.unit}} (хүн) / {{payresp.days}} (өдөр)</div>
                          <div class="tile-title text-small">
                            <span *ngFor="let c of payresp.countries">{{c.name}}, </span>
                          </div>

                          <div class="tile-title text-small">{{paylinks.bankName}} / {{paylinks.accountName}} / {{paylinks.accountNo}}</div>
                          <div class="tile-title text-small text-bold">{{paylinks.payNo}} (гүйлгээний утга)</div>

                          <div class="tile-title text-small">{{paylinks.amountUSD | currency : '$'}} (Ханш : {{paylinks.rate | currency : '₮'}})</div>

                          <div class="tile-title text-bold">{{paylinks.finalAmount | currency : '₮'}}
                            <span *ngIf="paylinks.percent>0">/ {{paylinks.amount | currency : '₮'}} ({{paylinks.percent}}%)</span></div>
                          <div class="tile-title {{payresp.payStatus == 2 ? 'text-success':'text-warning'}} text-small">{{payresp.payStatus == 2 ? 'Төлөгдсөн' : paylinks.msg}}</div>
                          <button class="btn btn-block btn-sm mt-2 col-12" *ngIf="!payLoading1" (click)="downloadInvoice(paylinks.invoiceNo)">Нэхэмжлэх татах</button>
                          <div class="clearfix"></div>
                          <div class="columns">
                            <div class="column col-6 pr-1"><button class="btn btn-primary btn-sm mt-1 col-12" (click)="payCheck()">Төлөлт шалгах</button></div>
                            <div class="column col-6 pl-1"><button class="btn btn-error btn-sm mt-1 col-12" (click)="payPostpaid()">Дараа төлөх</button></div>
                          </div>
                          <div *ngIf="payLoading1">
                            <div class="loading float-left" style="margin: 14px;"></div>
                          </div>
                        </div>
                        <div *ngIf="loading || payLoading">
                          <div class="loading float-left" style="margin: 15px;"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="pageId == 4 && selService.id == 1" class="column col-12 col-xs-12">
                    <div class="card">
                      <div class="card-body" style="height: 680px;">
                        <div class="divider" data-content="Гэрээ"></div>
                        <div *ngIf="payLoading">
                          <div
                            class="loading float-left"
                            style="margin-left: 20px">

                          </div>
                        </div>

                        <div class="filter">
                          <div class="filter-body columns">
                            <div class="column col-3 filter-item" *ngFor="let member of payresp.users">
                              <div class="card">
                                <div class="card-header text-center">
                                  <figure class="avatar bg-secondary avatar-sm">
                                    <img src="assets/img/gamer.png">
                                  </figure>
                                </div>
                                <div class="card-body">
                                  <div class="tile-title text-center text-small">{{member.regno}}</div>
                                  <div class="tile-subtitle text-small text-center text-bold">{{member.lastname}} {{member.firstname}}</div>
                                  <div class="tile-subtitle text-center text-small"><a class="text-small text-center" href="p/v1/pdf/{{payresp.invoiceNo}}/{{member.id}}" target="_blank">Гэрээг татах</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="tile-icon popover popover-top" id="fixedbutton">
      <a href="{{page}}#{{modal}}" (click)="createInvoice(profile ? profile.orgDto.serviceId : 1)" class="btn-link">
        <figure class="avatar avatar-xl mr-2" style="background: #eee;">
          <lottie-player src="https://assets6.lottiefiles.com/packages/lf20_f6zszxrn.json"  background="transparent"  speed="1" loop autoplay>
          </lottie-player>
        </figure>
      </a>
    </div>
  </body>
</html>

import { Component } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { AppComponent } from './app.component';
import {ListComponent} from "./list.component";

@Component({
  selector: 'inactive-root',
  templateUrl: './inactive.component.html',
})
export class InActiveComponent extends ListComponent {
  public count: any = 0;
  public total: any = 0;
  public sent: any = 0;
  override ngOnInit() {
    this.page = "inactive";
    this.modal = new Date().getTime();
    let yourDate = new Date();
    this.searchDate = yourDate.toISOString().split('T')[0];
    this.pageId = 0;
    this.status = 0;
    this.getProfile();
    this.getOrgs();
    this.getAgents();
    this.getInvoices(this.pageNum);
    this.getCountries();
    this.createInvoice(1);
    this.getServices();
  }

  sentPdfs(p: any) {
    let con = true;
    if (p.pdfSent == 1)
      con = confirm('Дахин илгээхүү !');
    else
      con = confirm('Илгээхүү !');

    if (!con) return;
    this.total = 0;
    this.sent = 0;
    let emails = [];
    for (let i = 0; i < this.age1.length; i++) {
      if (this.age1[i].sent != 1) {
        this.total++;
        emails.push(this.age1[i]);
      }
    }
    for (let i = 0; i < this.age2.length; i++) {
      if (this.age2[i].sent != 1) {
        this.total++;
        emails.push(this.age2[i]);
      }
    }
    for (let i = 0; i < this.age3.length; i++) {
      if (this.age3[i].sent != 1) {
        this.total++;
        emails.push(this.age3[i]);
      }
    }
    this.count = this.total;
    if (this.total > 0) {
      if (confirm(this.total+' ширхэг даатгуулагч руу даатгалын сертификатыг илгээх үү ?')) {
        this.sendPdf(emails, 0);
      }
    } else {
      this.toastr.warning('Имэйл амжилттай илгээгдсэн байна !', 'Анхааруулга');
    }
  }

  sendPdf(ages: any, index: any) {
    if (ages.length <= index) {
      this.loading = false;
      return;
    }
    let em = !ages[index].em ? '-':ages[index].em;
    const url = 'p/v1/email/pdf/'+this.payresp.invoiceNo+'/'+ages[index].id+'/'+em;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+this.token
    })
    this.loading = true;
    this.http.get(url, {headers: headers}).subscribe((res) => {
        ages[index].sent = res;
        this.sendPdf(ages, index+1)
        if (res == 1) this.sent++;
        this.total--;
        if (this.total <= 0) {
          if (this.count == this.sent)
            this.toastr.success('Имэйл илгээгдэж дууслаа ! ('+this.sent+'/'+this.count+')', 'Ok');
          if (this.count != this.sent)
            this.toastr.warning('Имэйл илгээх хүсэлт дууслаа ! ('+this.sent+'/'+this.count+')', 'Анхааруулга');
        }
    }, error => {
      this.toastr.error(error.statusText, 'Алдаа !');
    });
  }

  sendXls(p:any) {
    let con = true;
    if (p.xlsSent == 1)
      con = confirm('Дахин илгээхүү !');
    else
      con = confirm('Илгээхүү !');

    if (!con) return;

    const url = 'p/v1/email/xls/'+this.payresp.invoiceNo;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+this.token
    })
    this.loading = true;
    this.http.get(url, {headers: headers}).subscribe((res) => {
      this.loading = false;
      this.toastr.success('Имэйл илгээх хүсэлт дууслаа ! ', 'Ok');
    }, error => {
      this.toastr.error(error.statusText, 'Алдаа !');
    });
  }

  sendApi(p:any) {
    let con = true;
    con = confirm('Илгээхүү !');

    if (!con) return;

    const url = 'p/v1/apicheck/1/'+this.payresp.invoiceNo;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+this.token
    })
    this.loading = true;
    this.http.get(url, {headers: headers}).subscribe((res) => {
      this.loading = false;
      this.toastr.success('API илгээх хүсэлт дууслаа ! ', 'Ok');
    }, error => {
      this.toastr.error(error.statusText, 'Алдаа !');
    });
  }
}

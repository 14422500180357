import {Component, OnInit, ViewChild} from '@angular/core';
import { AppComponent } from './app.component';
import { ActivatedRoute } from '@angular/router';
import {HttpClient, HttpEventType, HttpHeaders, HttpResponse} from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {Observable} from "rxjs";

@Component({
  selector: 'refund-root',
  templateUrl: './refund.component.html',
  styleUrls: ['./app.component.css'],
})
export class RefundComponent extends AppComponent implements OnInit {
  public selServiceId: any = 1;
  public refunds: any = [];
  public types: any = [];
  public added: any = [];

  public span : any = 1;
  ngOnInit() {
    this.page = '/refund';
    this.getOrgs();
    this.getServices();
    this.loading = true;
    this.selOrgId = 1;
    this.getRefunds();
  }

  getSpan() {
    return this.orgs ? this.orgs.length : 1;
  }

  getRefunds() {
    this.refunds = [];
    const url = 'p/serviceAttachments/'+this.selServiceId;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+this.token
    });

    this.loading = true;
    this.http.get(url, {headers: headers}).subscribe((res) => {
      this.refunds = res;
      this.loading = false;
      this.added = [];
      this.types = [];
      for (let i = 0; i < this.refunds.length; i++) {
        if (!this.added[this.refunds[i].typeName]) {
          this.added[this.refunds[i].typeName] = true;
          this.types.push(this.refunds[i].typeName);
        }
      }
    }, error => {
      this.loading = false;
      this.toastr.error(error.statusText, 'Алдаа !');
    });
  }

  getRefundOrg(typeName: any) {
      let r = [];
      let oId = 1;
      while (r.length == 0) {
        for (let i = 0; i < this.refunds.length; i++)
          if (this.refunds[i].orgId == oId && this.refunds[i].typeName == typeName)
            r.push(this.refunds[i]);
        oId++;
      }

      return r;
  }

  getRefundValue(orgId:any, docName: any, typeName: any) {
    let r = [];
    for (let i = 0; i < this.refunds.length; i++)
      if (this.refunds[i].orgId == orgId && this.refunds[i].docName == docName && this.refunds[i].typeName == typeName)
        return this.refunds[i].status == 0 ? '':'*';

    return '';
  }

}

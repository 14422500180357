import {Component, OnInit, ViewChild} from '@angular/core';
import { AppComponent } from './app.component';
import { ActivatedRoute } from '@angular/router';
import {HttpClient, HttpEventType, HttpHeaders, HttpResponse} from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {Observable} from "rxjs";

@Component({
  selector: 'profile-root',
  templateUrl: './profile.component.html',
  styleUrls: ['./app.component.css'],
})
export class ProfileComponent extends AppComponent implements OnInit {
  public selServiceId: any = 1;
  public discount: any = {};
  public discounts: any = [];
  ngOnInit() {
    this.page = '/profile';
    this.getProfile();
    this.getServices();
    this.discount = {
      countStart: 0,
      countEnd: 0,
      percent: 0,
      orgId: this.selOrgId,
      serviceId: this.selServiceId
    };

    this.profile = {
      email: '',
      phone: '',
      firstname: '',
      lastname: ''
    }

    this.selOrgId = 1;
  }

  override getProfile() {
    if (this.token == '') {
      this.logout();
      return;
    }
    let time = localStorage.getItem('time');
    let curr = new Date().getTime();
    if (time && parseInt(time) > 0) {
      let t = curr - parseInt(time)
      if (t > 10 * 60 * 1000) {
        this.logout();
        return;
      }
    }

    const url = 'p/v1/auth/profile';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    })
    this.http.get(url, {headers: headers}).subscribe((res) => {
      this.profile = res;
      this.getDiscount();
      localStorage.setItem('time', new Date().getTime()+'');
    }, error => {
      this.logout();
      localStorage.setItem('token', '');
      this.token = '';
    });
  }

  override getOrgs() {
    const url = 'p/orgs/2';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    })
    this.http.get(url, {headers: headers}).subscribe((res) => {
      this.orgs = res;
    }, error => {
      // this.logout();
    });
  }

  saveDiscount() {
    this.loading = true;
    const url = 'p/discount/save';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    })
    this.http.post(url, this.discount,{headers: headers}).subscribe((res) => {
      this.loading = false;
      this.discount = {
        countStart: 0,
        countEnd: 0,
        percent: 0,
        orgId: this.selOrgId,
        serviceId: this.selServiceId
      };

      this.getDiscount();
      this.toastr.success('Амжиллттай !', 'Ok');
    }, error => {
      this.loading = false;
      this.toastr.error(error.statusText, 'Алдаа !');
    });
  }

  deleteDiscount(discount: any) {
    if (confirm('Устгах уу !')) {
      this.loading = true;
      const url = 'p/discount/delete/'+discount.id;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })
      this.http.get(url, {headers: headers}).subscribe((res) => {
        this.loading = false;
        this.getDiscount();
      }, error => {
        this.loading = false;
        this.toastr.error(error.statusText, 'Алдаа !');
      });
    }
  }

  getDiscount() {
    if (!this.selServiceId) return;
    if (this.profile.role == 'ROLE_ADMIN') {
      this.getOrgs();
    }

    if (this.profile.role == 'ROLE_AGENT') {
      this.selOrgId = this.profile.org_id;
    }

    this.discount = {
      countStart: 0,
      countEnd: 0,
      percent: 0,
      orgId: this.selOrgId,
      serviceId: this.selServiceId
    };

    this.loading = true;
    const url = 'p/discount/list/'+this.selOrgId+'/'+this.selServiceId;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    })
    this.http.get(url,{headers: headers}).subscribe((res) => {
      this.loading = false;
      this.discounts = res;
    }, error => {
      this.loading = false;
      this.toastr.error(error.statusText, 'Алдаа !');
    });
  }

  confirmUser() {
    this.loading = true;
    const url = 'p/user/update';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    })
    this.http.post(url, this.profile,{headers: headers}).subscribe((res) => {
      this.loading = false;
      this.profile = res;

      this.toastr.success('Амжиллттай !', 'Ok');
    }, error => {
      this.loading = false;
      this.toastr.error(error.statusText, 'Алдаа !');
    });
  }

}

import { Component } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { AppComponent } from './app.component';
import {ListComponent} from "./list.component";
import {ActiveComponent} from "./active.component";

@Component({
  selector: 'invoice-root',
  templateUrl: './invoice.component.html',
})
export class InvoiceComponent extends ActiveComponent {
  public payNo: any = '';
  public payNos: any = [];
  public payloading: any = false;
  public xlsDate: any = new Date().toISOString().slice(0, 10);

  override ngOnInit() {
    this.page = "invoice";
    this.modal = new Date().getTime();
    let yourDate = new Date();
    this.searchDate = yourDate.toISOString().split('T')[0];
    this.pageId = 0;
    this.status = 3;
    this.getProfile();
    this.getOrgs();
    this.getAgents();
    this.getInvoices(this.pageNum);
    this.getCountries();
    this.createInvoice(1);
    this.getServices();
    this.getInvoicesPayNoList();
    this.resp = {
      msg: ''
    };
    this.payments = {
      amount: 0,
      payNo: '',
      invId: 0,
      bank: 0,
      currency: 2,
      tranDate: new Date().toISOString().slice(0, 10),
      type: 2
    };
  }

  savePayment() {
    this.payloading = true;
    const url = 'p/v1/invoice/paymentInfo';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+this.token
    })
    this.http.post(url, this.payments, {headers: headers}).subscribe((res) => {
      this.payments = res;
      this.payloading = false;
      location.href = '/'+this.page+'#close';
      this.getInvoices(this.pageNum);
    }, error => {
    });
  }

  payInvoicePayNo() {
    this.payments = {
      amount: this.invoices.amounts,
      payNo: this.payNo,
      invId: 0,
      bank: 0,
      currency: 1,
      tranDate: new Date().toISOString().slice(0, 10),
      type: 2
    };
  }

  payInvoice(invoice:any) {
    this.payments = {
      amount: invoice.finalAmount,
      payNo: '',
      invId: invoice.id,
      bank: 0,
      currency: 1,
      tranDate: new Date().toISOString().slice(0, 10),
      type: 2
    };
  }

  getInvoicesPayNoList() {
    const url = 'p/v1/invoice/paynos';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+this.token
    })
    this.http.get(url, {headers: headers}).subscribe((res) => {
      this.payNos = res;
    }, error => {
    });
  }

  getInvoicesPayNo(pageNum:any) {
    if (this.payNo == '0') {
      this.getInvoices(pageNum);
      return;
    }
    this.pageNum = pageNum;
    this.invoices = {};
    this.invoiceLoading = true;
    const url = 'p/v1/invoice/all/' + this.status+'/'+this.selOrgId+'/'+this.selAgentId+'/'+this.payNo+'/'+this.pageNum;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+this.token
    })
    this.pages = [];
    this.http.get(url, {headers: headers}).subscribe((res) => {
      this.invoices = res;
      this.firstload = true;
      this.invoiceLoading = false;
      for (let i = 0; i < this.invoices.rows / this.invoices.size; i++)
        this.pages.push({page: i+1});
    }, error => {
      this.invoiceLoading = false;
      this.toastr.error(error.statusText, 'Алдаа !');
    });
  }

  viewAllInvoice() {
      window.open("/p/img/" + this.payNo + ".pdf");
  }

  generateAllInvoice() {
    if (confirm('Багц нэхэмжлэхийг үүсгэх үү ?')) {
      this.loading = true;
      const url = 'p/v1/invoice/payno/' + this.selAgentId;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get(url, {headers: headers}).subscribe((res) => {
        this.resp = res;
        window.open("/p/img/" + this.resp.msg + ".pdf");
      }, error => {
        this.loading = false;
        window.open("/p/img/" + this.resp.msg + ".pdf");
      });
    }
  }

  invoicePhone() {
    if (this.newInvoice.serviceId == 3)
      return this.newInvoice.users[1].phone;

    return this.newInvoice.users[0].phone;
  }
}

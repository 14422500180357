import { Component } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import {AppComponent} from "./app.component";
import * as echarts from 'echarts';

@Component({
  selector: 'home-root',
  templateUrl: './home.component.html'
})
export class HomeComponent extends AppComponent{
  public maxWidth: any = 0;
  public maxHeight: any = 0;
  public stats: any = [];
  public invoiceReps: any = [];

  public userStat: any = [];

  public orgStat: any = [];

  public serviceStat: any = [];
  public userTab: any = 1;

  public pstatus: any;
  public s1: any;
  public s2: any;
  public t0: any = 0;
  public t1: any = 0;
  public rloading: any=false;
  public chartData: any = [];
  public months: any = [];
  public values: any = [];
  public reportType: any = 1;
  public reportField : any = 'f2';
  ngOnInit() {
    this.page = '/home';
    this.getServices();
    this.getStat();
    this.getOrgs();
    this.getAgents();
    this.getServiceStat();
    this.getOrgStat();
    this.getUserStat();
    this.getProfile();
    var date = new Date();
    this.s1 = (date.getFullYear()-1)+'-'+(date.getMonth()<10 ? '0'+(date.getMonth()+1):date.getMonth()+1)+'-01';
    this.s2 = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().substring(0, 10);
    this.maxWidth = screen.width;
    this.maxHeight = screen.height;
  }

  chartFieldName() {
    if (this.reportField == 'f2') return 'хураамж';
    if (this.reportField == 'f1') return 'төлөх';
    if (this.reportField == 'u2') return 'даатгуулагч';

    return '';
  }
  chart() {
    this.rloading = true;
    const url = 'p/v1/invoice/chart/'+this.pstatus+'/'+this.s1+'/'+this.s2+'/'+this.selectedServiceId;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+this.token
    })
    this.http.get(url, {headers: headers}).subscribe((res) => {
      this.rloading = false;
      this.chartData = res;
      this.months = [];
      this.values = [];
      for (let i = 0; i < this.chartData.length; i++) {
        let e = this.chartData[i];
        this.months.push(e.monthName);
        this.values.push({value:e[this.reportField].toFixed(2),
          itemStyle: {barBorderRadius: [20, 20, 0, 0]}});
      }

      var chart = echarts.init(document.getElementById('main'));
      var option = {
        title: {
          text: ''
        },
        tooltip: {},
        legend: {
          data: [this.chartFieldName()]
        },
        xAxis: {
          data: this.months
        },
        yAxis: {},
        series: [
          {
            name: this.chartFieldName(),
            type: 'bar',
            data: this.values,
            smooth: true,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#b6b5ff' },
                { offset: 0.5, color: '#7472db' },
                { offset: 1, color: '#5755d9' }
              ])
            },
            emphasis: {
              focus: 'series',
              barBorderRadius: [50, 50],
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#5755d9' },
                  { offset: 0.7, color: '#7472db' },
                  { offset: 1, color: '#b6b5ff' }
                ]),
                barBorderRadius: 50,
              }
            },
            normal: {
              barBorderRadius: [50, 50, 0 ,0 ]
            }
          }
        ],
        toolbox: {
          // y: 'bottom',
          feature: {
            magicType: {
              type: ['stack']
            },
            dataView: {},
            saveAsImage: {
              pixelRatio: 2
            }
          }
        },
      };

      // Display the chart using the configuration items and data just specified.
      chart.setOption(option);
    }, error => {
    });

  }

  exportXLS() {
    if (!this.pstatus) this.pstatus = 2;

    this.rloading = true;
    const url = 'p/v1/xls/users/'+this.pstatus+'/'+this.s1+'/'+this.s2+'/'+(this.t0 ? 1:0)+'/'+this.selOrgId+'/'+this.selAgentId+'/'+this.selectedServiceId;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+this.token
    })
    this.http.get(url, {headers: headers}).subscribe((res) => {
      this.rloading = false;
      window.open('p/cic/'+this.selOrgId+'_'+this.s1+'_'+this.s2+'.xlsx');
    }, error => {
      this.rloading = false;
      window.open('p/cic/'+this.selOrgId+'_'+this.s1+'_'+this.s2+'.xlsx');
    });
  }

  getOrgStat() {
    const url = 'p/stat/orgs';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+this.token
    })
    this.http.get(url, {headers: headers}).subscribe((res) => {
      this.orgStat = res;
    }, error => {
    });
  }

  getServiceStat() {
    const url = 'p/stat/service';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+this.token
    })
    this.http.get(url, {headers: headers}).subscribe((res) => {
      this.serviceStat = res;
    }, error => {
    });
  }

  getUserStat() {
    const url = 'p/stat/users';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+this.token
    })
    this.http.get(url, {headers: headers}).subscribe((res) => {
      this.userStat = res;
    }, error => {
    });
  }

  getStat() {
    const url = 'p/stat/main';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+this.token
    })
    this.http.get(url, {headers: headers}).subscribe((res) => {
      this.stats = res;
    }, error => {
    });
  }

  getSumOfPercent(array: any, field: any) {
    let total = 0;
    for (let i = 0; i < array.length; i++)
      total += array[i][field] * array[i]['percent']/100;
    return total;
  }

  getSumOf(array: any, field: any) {
    let total = 0;
    for (let i = 0; i < array.length; i++)
      total += array[i][field];
    return total;
  }

  typeName(name: any) {
    if (name == 'Гадаад зорчигчийн даатгал') return 'ГЗД';
    if (name == 'Тээврийн хэрэгслийн даатгал') return 'ТХД';
    return name;
  }

  getInvoiceReportByOrg() {
    this.chart();

    if (this.t1 == 1) {
      this.getInvoiceReportByUser();
      return;
    }
    this.invoiceReps = [];
    this.rloading = true;
    if (!this.pstatus) this.pstatus = 2;
    const url = 'p/v1/invoice/report/'+this.pstatus+'/'+this.s1+'/'+this.s2+'/'+(this.t0 ? 1:0)+'/'+this.selOrgId+'/'+this.selAgentId+'/'+this.selectedServiceId;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+this.token
    })
    this.http.get(url, {headers: headers}).subscribe((res) => {
      this.invoiceReps = res;
      this.rloading = false;
    }, error => {
    });
  }

  getInvoiceReportByUser() {
    this.invoiceReps = [];
    this.rloading = true;
    const url = 'p/v1/invoice/reportUser/'+this.pstatus+'/'+this.s1+'/'+this.s2+'/'+(this.t0 ? 1:0)+'/'+this.selOrgId+'/'+this.selAgentId+'/'+this.selectedServiceId;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+this.token
    })
    this.http.get(url, {headers: headers}).subscribe((res) => {
      this.invoiceReps = res;
      this.rloading = false;
    }, error => {
    });
  }

  getServiceStatValue(sd: any) {
    for (let i = 0; i < this.serviceStat.length; i++) {
      if (this.serviceStat[i].sd == sd) return this.serviceStat[i];
    }

    return {
      sd: 0,
      count: 0,
      amount: 0
    };
  }

  getStatValue(status: any) {
    for (let i = 0; i < this.stats.length; i++) {
      if (this.stats[i].status == status) return this.stats[i];
    }

    return {
      status: 0,
      count: 0,
      amount: 0
    };
  }

  getUserStatValue(role: any) {
    for (let i = 0; i < this.userStat.length; i++) {
      if (this.userStat[i].role == role) return this.userStat[i];
    }

    return {
      role: '',
      count: 0
    };
  }

  getOrgStatValue(name: any) {
    for (let i = 0; i < this.orgStat.length; i++) {
      if (this.orgStat[i].name == name) return this.orgStat[i];
    }

    return {
      name: '',
      count: 0
    };
  }
}

import {Component, OnInit, ViewChild} from '@angular/core';
import { AppComponent } from './app.component';
import { ActivatedRoute } from '@angular/router';
import {HttpClient, HttpEventType, HttpHeaders, HttpResponse} from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {Observable} from "rxjs";

@Component({
  selector: 'reset-root',
  templateUrl: './reset.component.html',
  styleUrls: ['./app.component.css'],
})
export class ResetComponent extends AppComponent implements OnInit {
  public pass: any = {};
  ngOnInit() {
    this.getProfile();
    this.page = '/reset';
    this.pass = {
      old: '',
      newpass: '',
      repass: ''
    };
  }

  resetPassword() {
    this.loading = true;
    const url = 'p/v1/auth/change/pass';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    })
    this.http.post(url, this.pass,{headers: headers}).subscribe((res) => {
      this.loading = false;
      this.pass = {
        old: '',
        newpass: '',
        repass: ''
      };
      this.toastr.success('Амжилттай солигдлоо ', 'Ok');
      this.logout();
    }, error => {
      this.loading = false;
      this.toastr.error(error.statusText, 'Алдаа !');
    });
  }

}

import {Component, OnInit, ViewChild} from '@angular/core';
import { AppComponent } from './app.component';
import { ActivatedRoute } from '@angular/router';
import {HttpClient, HttpEventType, HttpHeaders, HttpResponse} from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {Observable} from "rxjs";
import {UserComponent} from "./user.component";
import {OrgComponent} from "./org.component";

@Component({
  selector: 'create-root',
  templateUrl: './create.component.html',
  styleUrls: ['./app.component.css'],
})
export class CreateComponent extends OrgComponent implements OnInit {
  public apis: any = [];
  public pageNum1: any = 0;
  public apiloading: any = false;
  public api: any = {};
  public insOrgs: any = [];
  public dto: any = [];
  override ngOnInit() {
    this.form = new FormGroup({
    });

    this.initCreate();

    this.getAgentUserList(this.pageNum);
    this.getApiUserList(this.pageNum1);
    this.getOrgList(3);
    this.getInsOrgList(2);
  }

  initCreate() {
    this.user = {
      email: '',
      phone: '',
      regno: '',
      firstname: '',
      lastname: '',
      org_id: 0
    };

    this.api = {
      email: '',
      phone: '',
      regno: '',
      firstname: '',
      lastname: '',
      org_id: 0
    };
  }

  getInsOrgList(otype:any) {
    this.oloading = true;
    this.otype = otype;

    this.insOrgs = [];
    const url = 'p/orgList/'+otype;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+this.token
    });

    this.http.get(url, {headers: headers}).subscribe((res) => {
      this.insOrgs = res;
      this.oloading = false;
    }, error => {
      this.oloading = false;
      this.toastr.error(error.statusText, 'Алдаа !');
    });
  }

  getAgentUsersLike() {
    if (this.search == '') {
      this.getAgentUserList(this.pageNum);
      return;
    }
    this.loading = true;
    const url = 'p/agentUserList/search/'+this.search;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+this.token
    });
    this.users = {
      rows: 0,
      results: []
    };
    this.dto = {
      userDtoList: [],
      rows: 0
    };
    this.http.get(url, {headers: headers}).subscribe((res) => {
      this.dto = res;
      this.users.results = this.dto.userDtoList;
      this.users.rows = this.dto.rows;
      this.loading = false;
    }, error => {
      this.loading = false;
      this.toastr.error(error.statusText, 'Алдаа !');
    });
  }

  getAgentUserList(pageNum:any) {
    this.loading = true;
    this.pages = [];
    const url = 'p/agentUserList/page/'+(pageNum);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+this.token
    });

    this.http.get(url, {headers: headers}).subscribe((res) => {
      this.users = res;
      this.loading = false;
      for (let i = 0; i < this.users.rows / 10; i++)
        this.pages.push({page: i+1});
    }, error => {
     this.loading = false;
      this.toastr.error(error.statusText, 'Алдаа !');
    });
  }

  getApiUserList(pageNum:any) {
    this.apiloading = true;
    const url = 'p/apiUserList/page/'+(pageNum);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+this.token
    });

    this.http.get(url, {headers: headers}).subscribe((res) => {
      this.apis = res;
      this.apiloading = false;
    }, error => {
      this.apiloading = false;
      this.toastr.error(error.statusText, 'Алдаа !');
    });
  }

  override confirmUser(user: any) {
    this.loading = true;
    const url = 'p/user/update';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    })
    this.http.post(url, user,{headers: headers}).subscribe((res) => {
      this.loading = false;
      this.getAgentUserList(this.pageNum);
    }, error => {
      this.loading = false;
      this.toastr.error(error.statusText, 'Алдаа !');
    });
  }

  override editUser(user: any) {
    if (user.status == 2) {
      this.confirmAgent(user);
      return;
    }
    user.status = 2;
  }

  confirmAgent(user: any) {
    this.loading = true;
    const url = 'p/user/update';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    })
    this.http.post(url, user,{headers: headers}).subscribe((res) => {
      this.loading = false;
      this.getAgentUserList(this.pageNum);
    }, error => {
      this.loading = false;
      this.toastr.error(error.statusText, 'Алдаа !');
    });
  }

  override cancelUser(user: any) {
    user.status = 1;
  }

  registerAgent() {
    this.loading = true;
    const url = 'p/v1/auth/agent';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    });

    this.http.post(url, this.user,{headers: headers}).subscribe((res) => {
      this.loading = false;
      if (this.user.id == -1) {
        this.toastr.error('Бүртгэгдсэн байна !', 'Алдаа !');
      }
      this.initCreate();
      this.getAgentUserList(this.pageNum);
    }, error => {
      this.loading = false;
      this.toastr.error(error.statusText, 'Алдаа !');
    });
  }

  registerApi() {
    if (this.api.org_id == 0) {
      this.toastr.error('Байгууллага сонгоно уу !', 'Алдаа !');
      return;
    }
    // if (!this.regexEmail(this.api.email)) {
    //   this.toastr.error('Цахим шуудан алдаатай байна !', 'Алдаа !');
    //   return;
    // }
    this.apiloading = true;
    const url = 'p/v1/auth/apiuser';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    });

    this.http.post(url, this.api,{headers: headers}).subscribe((res) => {
      this.apiloading = false;
      if (this.api.id == -1) {
        this.toastr.error('Бүртгэгдсэн байна !', 'Алдаа !');
      }
      this.initCreate();
      this.getApiUserList(this.pageNum1);
    }, error => {
      this.apiloading = false;
      this.toastr.error(error.statusText, 'Алдаа !');
    });
  }

  override deleteUser(user: any) {
    if (confirm('Устгах уу ?')) {
      this.loading = true;
      const url = 'p/v1/auth/delete/' + user.id;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get(url, {headers: headers}).subscribe((res) => {
        this.getAgentUserList(this.pageNum);
      }, error => {
        this.loading = false;
        this.toastr.error(error.statusText, 'Алдаа !');
      });
    }
  }

  orgView(org_id:any) {
    if (this.orgs) {
      for (let i = 0; i < this.orgs.length; i++)
        if (this.orgs[i].id == org_id) return this.orgs[i].name;
    }

    if (this.insOrgs) {
      for (let i = 0; i < this.insOrgs.length; i++)
        if (this.insOrgs[i].id == org_id) return this.insOrgs[i].name;
    }
    return;
  }
}

<!DOCTYPE html>
<html
  lang="en"
  xmlns:th="http://www.thymeleaf.org"
  xmlns:span="http://www.w3.org/1999/xhtml"
>
  <head>
    <meta charset="UTF-8" />
    <title>qrly.in</title>
    <meta name="viewport" content="width=device-width, initial-scale=1" />
  </head>
  <body>
    <div class="docs-container off-canvas off-canvas-sidebar-show">
      <div class="docs-sidebar off-canvas-sidebar" id="sidebar">
        <div class="docs-brand">
          <a class="docs-logo" href="/home">
            <img src="assets/img/letter-d.png" style="width: 24px;" alt="daatguul">
            <label class="label label-secondary text-bold ml-1">PORTAL</label></a>
        </div>
        <menu-root></menu-root>
      </div>
      <a class="off-canvas-overlay" href="{{page}}#close"></a>
      <div class="off-canvas-content">
        <div class="docs-content" id="content">
          <div id="forms_agent">
            <div class="columns">
              <div class="column col-9">
                <h5 class="s-title">
                  Агент бүртгэх<a class="anchor" href="{{page}}#forms" aria-hidden="true">#</a>
                </h5>
              </div>
              <div class="column col-3 float-right pt-2 mt-2">
                <div class="input-group" style="margin-top: 15px;"><span class="input-group-addon addon-sm">Хайх утга</span>
                  <input class="form-input input-sm" [(ngModel)]="search" type="text" placeholder="">
                  <button class="btn btn-primary input-group-btn btn-sm" (click)="getAgentUsersLike()">Хайх</button>
                </div>
              </div>
            </div>

            <table class="table table-scroll table-striped table-hover text-small">
              <thead class="text-small text-dark">
              <tr>
                <th style="width: 3%;" class="text-right">
                  <span *ngIf="!loading">#</span>
                  <div  *ngIf="loading"
                        class="loading loading-sm float-left"
                        style="margin-top: 5px; margin-left: 20px;"></div>
                </th>
                <th style="width: 10%">РД</th>
                <th style="width: 10%">Эцэг/эх нэр</th>
                <th style="width: 10%">Нэр</th>
                <th style="width: 10%">Утас</th>
                <th style="width: 10%">Цахим шуудан</th>
                <th style="width: 15%">Байгууллага</th>
                <th style="width: 10%">Бүртгэсэн</th>
                <th style="width: 10%;">Үйлдэл</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let user of users.results; let i = index;">
                <td class="text-right">
                  {{i+1}}.
                </td>
                <td><span *ngIf="user.status != 2">{{user.registerno}}</span>
                  <input type="text" class="form-input input-sm" *ngIf="user.status==2" [(ngModel)]="user.registerno" toUppercase/>
                </td>
                <td><span *ngIf="user.status != 2">{{user.lastname}}</span>
                  <input type="text" class="form-input input-sm" *ngIf="user.status==2" [(ngModel)]="user.lastname"  toUppercase/>
                </td>
                <td><span *ngIf="user.status != 2">{{user.firstname}}</span>
                  <input type="text" class="form-input input-sm" *ngIf="user.status==2" [(ngModel)]="user.firstname"  toUppercase/>
                </td>
                <td><span *ngIf="user.status != 2">{{user.phone}}</span>
                  <input type="text" class="form-input input-sm" *ngIf="user.status==2" [(ngModel)]="user.phone"  toUppercase/>
                </td>
                <td><span *ngIf="user.status != 2">{{user.email}}</span>
                  <input type="text" class="form-input input-sm" *ngIf="user.status==2" [(ngModel)]="user.email"  toUppercase/>
                </td>
                <td><span *ngIf="user.status != 2">{{orgView(user.org_id)}}</span>
                  <select [(ngModel)]="user.org_id" class="form-select select-sm" *ngIf="user.status==2">
                    <option *ngFor="let org of orgs" [ngValue]="org.id">{{org.name}}</option>
                  </select>
                </td>
                <td>{{user.created_date | dateAsAgo}}</td>
                <td>
                  <button class="btn btn-sm btn-primary" (click)="editUser(user)"><i class="icon {{user.status != 2 ? 'icon-edit':'icon-check'}}"></i></button>
                  <button class="btn btn-sm btn-error ml-2" *ngIf="user.status == 2" (click)="cancelUser(user)"><i class="icon icon-cross"></i></button>
                  <button class="btn btn-sm btn-error ml-2" (click)="deleteUser(user)"><i class="icon icon-delete"></i></button>
                </td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <td class="text-center text-bold">+</td>
                <td><input type="text" class="form-input input-sm" [(ngModel)]="user.regno" placeholder="Регистр" toUppercase/></td>
                <td><input type="text" class="form-input input-sm" [(ngModel)]="user.lastname" placeholder="Эцэг/эх нэр" toUppercase/></td>
                <td><input type="text" class="form-input input-sm" [(ngModel)]="user.firstname" placeholder="Нэр" toUppercase/></td>
                <td><input type="phone" class="form-input input-sm" [(ngModel)]="user.phone" placeholder="Утас" toUppercase/></td>
                <td><input type="email" class="form-input input-sm" [(ngModel)]="user.email" placeholder="Цахим шуудан"/></td>
                <td>
                  <select [(ngModel)]="user.org_id" class="form-select select-sm">
                    <option *ngFor="let org of orgs" [ngValue]="org.id">{{org.name}}</option>
                  </select>
                </td>
                <td>
                  <button class="btn btn-sm btn-primary float-left" (click)="registerAgent()"><i class="icon icon-check"></i></button>
                  <div *ngIf="gloading">
                    <div
                      class="loading loading-sm float-left"
                      style="margin: 5px; margin-left: 20px;"></div>
                  </div>
                </td>
              </tr>
              </tfoot>
              <tfoot>
                <tr>
                  <td></td>
                  <td>
                    <select class="form-select select-sm" style="width: 50px;" (change)="getAgentUserList(pageNum)" [(ngModel)]="pageNum">
                      <option *ngFor="let item of pages" value="{{item.page-1}}">{{item.page}}</option>
                    </select>
                  </td>
                  <td>

                  </td>
                  <td class="text-bold text-right" colspan="6">Нийт : {{users.rows}} мөр</td>
                </tr>
              </tfoot>
            </table>
          </div>

          <div id="forms_api" class="column col-8">
            <h5 class="s-title">
              API хэрэглэгч бүртгэх<a class="anchor" href="{{page}}#forms" aria-hidden="true">#</a>
            </h5>
            <br>
            <table class="table table-scroll table-striped table-hover text-small">
              <thead class="text-small text-dark">
              <tr>
                <th style="width: 3%;" class="text-right">
                  <span *ngIf="!apiloading">#</span>
                  <div  *ngIf="apiloading"
                        class="loading loading-sm float-left"
                        style="margin-top: 5px; margin-left: 20px;"></div>
                </th>
                <th style="width: 10%">Цахим шуудан</th>
                <th style="width: 15%">Байгууллага</th>
                <th style="width: 10%">Бүртгэсэн</th>
                <th style="width: 10%;">Үйлдэл</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let api of apis; let i = index;">
                <td class="text-right">
                  {{i+1}}.
                </td>
                <td><span *ngIf="api.status != 2">{{api.email}}</span>
                  <input type="text" class="form-input input-sm" *ngIf="api.status==2" [(ngModel)]="api.email"  toUppercase/>
                </td>
                <td><span *ngIf="api.status != 2">{{orgView(api.org_id)}}</span>
                  <select [(ngModel)]="api.org_id" class="form-select select-sm" *ngIf="api.status==2">
                    <option *ngFor="let org of insOrgs" [ngValue]="org.id">{{org.name}}</option>
                  </select>
                </td>
                <td>{{api.created_date | dateAsAgo}}</td>
                <td>
                  <button class="btn btn-sm btn-primary" (click)="editUser(api)"><i class="icon {{api.status != 2 ? 'icon-edit':'icon-check'}}"></i></button>
                  <button class="btn btn-sm btn-error ml-2" *ngIf="user.status == 2" (click)="cancelUser(api)"><i class="icon icon-cross"></i></button>
                  <button class="btn btn-sm btn-error ml-2" (click)="deleteUser(api)"><i class="icon icon-delete"></i></button>
                </td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <td class="text-center text-bold">+</td>
                <td><input type="text" class="form-input input-sm" [(ngModel)]="api.email" placeholder="Цахим шуудан"/></td>
                <td>
                  <select [(ngModel)]="api.org_id" class="form-select select-sm">
                    <option *ngFor="let org of insOrgs" [ngValue]="org.id">{{org.name}}</option>
                  </select>
                </td>
                <td>
                  <button class="btn btn-sm btn-primary float-left" (click)="registerApi()"><i class="icon icon-check"></i></button>
                  <div *ngIf="gloading">
                    <div
                      class="loading loading-sm float-left"
                      style="margin: 5px; margin-left: 20px;"></div>
                  </div>
                </td>
              </tr>
              </tfoot>
            </table>
          </div>

        </div>
      </div>
    </div>
  </body>
</html>

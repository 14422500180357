<!DOCTYPE html>
<html
  lang="en"
  xmlns:th="http://www.thymeleaf.org"
  xmlns:span="http://www.w3.org/1999/xhtml"
>
  <head>
    <meta charset="UTF-8" />
    <title>D-Portal</title>
    <meta name="viewport" content="width=device-width, initial-scale=1" />
  </head>
  <body>
    <div class="docs-container off-canvas off-canvas-sidebar-show">
      <div class="docs-navbar">
        <a class="off-canvas-toggle btn btn-link btn-action" href="{{page}}#sidebar"
          ><i class="icon icon-menu"></i
        ></a>
      </div>
      <div class="docs-sidebar off-canvas-sidebar" id="sidebar">
        <div class="docs-brand">
          <a class="docs-logo" href="/home">
            <img src="assets/img/letter-d.png" style="width: 24px;" alt="daatguul">
            <label class="label label-secondary text-bold ml-1">PORTAL</label></a>
        </div>
        <menu-root></menu-root>
      </div>
      <a class="off-canvas-overlay" href="{{page}}#close"></a>
      <div class="off-canvas-content">
        <div class="docs-content" id="content">
          <h5 class="s-title">
            Харилцагчид<a class="anchor" href="{{page}}#forms" aria-hidden="true">#</a>
          </h5>

          <div class="columns">
            <div class="column col-3 col-xs-12">
              <div class="card">
                <div class="card-body">
                  <div class="empty text-center">
                    <div class="empty-icon">
                      <i *ngIf="!aploading" class="icon icon-plus"></i>
                      <div *ngIf="aploading">
                        <div
                          class="loading loading-sm"
                          style="margin: 5px; margin-left: 20px;"></div>
                      </div>
                    </div>
                    <p class="empty-title h6">Бүлэг үүсгэх</p>

                    <div class="form-group">
                    <input type="text" class="form-input input-sm" [(ngModel)]="group.name" placeholder="Бүлэгийн нэр"/>
                    </div>
                    <div class="form-group">
                      <input class="form-input input-sm" type="file"
                            multiple (change)="selectFile($event)">
                    </div>
                    <div class="empty-action">
                      <button class="btn btn-primary btn-sm" (click)="onUpload()">Шинээр үүсгэх</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>

          <div class="docs-demo columns">
              <div class="column col-4 col-xs-6" *ngIf="selUsers.length == 0">
<!--                <label class="chip text-small float-left pl-2 pr-2 {{seeGroupId == 0? 'active':''}}" (click)="seeGroup(0)">Бүгд</label>-->

<!--                <div class="chip text-small float-left pl-2 pr-2 {{seeGroupId == group.id? 'active':''}}" *ngFor="let group of groups; let i = index;" (click)="seeGroup(group.id)">-->
<!--                  <label>{{group.name}}</label>-->
<!--                  <a href="{{page}}#" class="btn btn-clear" aria-label="Close" role="button" (click)="removeGroup(group.id)"></a>-->
<!--                </div>-->

                <div class="input-group"><span class="input-group-addon addon-sm">Бүлэг</span>
                  <select class="form-select select-sm" style="max-width: 100%;" [(ngModel)]="seeGroupId" (change)="seeGroup(seeGroupId)">
                    <option [ngValue]="0">Бүгд</option>
                    <option *ngFor="let group of groups; let i = index;" [ngValue]="group.id">{{group.name}}</option>
                  </select>
                  <button class="btn btn-error input-group-btn btn-sm" *ngIf="seeGroupId > 0" (click)="removeGroup(seeGroupId)">Бүлэг устгах</button>
                </div>
              </div>
              <div class="column col-4 col-xs-6">
                <div class="input-group" *ngIf="selUsers.length > 0">
                  <span class="input-group-addon addon-sm"><i class="icon icon-group"></i></span>
                  <select class="form-select select-sm" style="max-width: 100%;" [(ngModel)]="agroupId">
                    <option *ngFor="let group of groups; let i = index;" [ngValue]="group.id">{{group.name}}</option>
                  </select>
                  <button class="btn btn-primary input-group-btn btn-sm" (click)="addToGroup(agroupId)">Нэмэх</button>
                  <button class="btn btn-error input-group-btn btn-sm" (click)="cancelToGroup(agroupId)">Цуцлах</button>
                </div>
              </div>
              <div class="column col-4 col-xs-12" *ngIf="selUsers.length == 0">
                  <div class="input-group">
                    <span class="input-group-addon addon-sm"><i class="icon icon-search"></i></span>
                    <input class="form-input input-sm" [(ngModel)]="search" type="text" placeholder="">
                    <button class="btn btn-primary input-group-btn btn-sm" (click)="getUsersLike()">Хайх</button>
                  </div>
              </div>

              <div class="clearfix"></div>
              <div class="divider"></div>
              <table class="table table-striped table-scroll table-hover text-small">
                <thead class="text-small text-dark">
                <tr>
                  <th style="width: 3%;" class="text-right">
                    <span *ngIf="!(loading || kloading)">#</span>
                    <div *ngIf="loading || kloading"
                         class="loading loading-sm float-left"
                         style="margin-top: 5px; margin-left: 20px;"></div>
                  </th>
                  <th style="width: 3%">ГИ</th>
                  <th style="width: 10%">РД</th>
                  <th style="width: 8%">Гадаад ПД</th>
                  <th style="width: 10%">Эцэг/эх нэр</th>
                  <th style="width: 12%">Нэр</th>
                  <th style="width: 5%">Нас</th>
                  <th style="width: 10%">Утас</th>
                  <th style="width: 15%">Цахим шуудан</th>
                  <th style="width: 10%">Бүртгэсэн</th>
                  <th style="width: 17%;">Үйлдэл</th>
                </tr>
                </thead>
                <tbody>
                  <tr *ngIf="!editing">
                    <td class="text-center text-bold">+</td>
                    <td><input type="checkbox" [(ngModel)]="user.fgn" class="form-checkbox" title="Гадаад иргэн бол сонгоно уу !"/></td>
                    <td><input type="text" class="form-input input-sm {{error['regno']}}" [(ngModel)]="user.regno" [maxlength]="10" [minlength]="10" placeholder="Регистр" toUppercase/></td>
                    <td><input type="text" class="form-input input-sm {{error['epassno']}}" [(ngModel)]="user.epassno" [maxlength]="12" [minlength]="8" placeholder="Гадаад ПД" toUppercase/></td>
                    <td><input type="text" class="form-input input-sm {{error['lastname']}}" [(ngModel)]="user.lastname" (keypress)="user.fgn ? isLatin($event) : isCyrillic($event)" placeholder="Эцэг/эх нэр" toUppercase/></td>
                    <td><input type="text" class="form-input input-sm {{error['firstname']}}" [(ngModel)]="user.firstname" (keypress)="user.fgn ? isLatin($event) : isCyrillic($event)" placeholder="Нэр" toUppercase/></td>
                    <td>
                      <input type="number" class="form-input input-sm {{error['age']}}" *ngIf="user.fgn" [(ngModel)]="user.age" placeholder="Нас"/>
                    </td>
                    <td><input type="phone" class="form-input input-sm" [(ngModel)]="user.phone" (keypress)="isPhone($event)" placeholder="Утас" [maxlength]="12" [minlength]="8"/></td>
                    <td><input type="email" class="form-input input-sm {{error['email']}}" [(ngModel)]="user.email" placeholder="Цахим шуудан"/></td>
                    <td></td>
                    <td>
                      <button class="btn btn-sm btn-primary float-left" (click)="registerUser()"><i class="icon icon-check"></i></button>
                      <div *ngIf="gloading">
                        <div
                          class="loading loading-sm float-left"
                          style="margin: 5px; margin-left: 20px;"></div>
                      </div>
                    </td>
                  </tr>
                  <tr *ngFor="let user of users.userDtoList; let i = index;">
                    <td class="text-right">
                      <div class="form-group" *ngIf="seeGroupId == 0">
                        <label class="form-checkbox">
                          <input type="checkbox" id="{{'checked_'+user.id}}" [(ngModel)]="user.checked" (change)="selectUser(user)">
                          <i class="form-icon"></i>
                        </label>
                      </div>
                      <span *ngIf="seeGroupId > 0">{{i+1}}.</span>
                    </td>
                    <td>
                      <i *ngIf="user.status != 2" class="icon icon-people {{user.fgn == 0 ? 'text-gray':''}}"></i>
                      <label class="form-checkbox" *ngIf="user.status==2">
                        <input  type="checkbox" class="form-checkbox" [(ngModel)]="user.fgn" title="Гадаад иргэн бол сонгоно уу !"/>
                        <i class="form-icon"></i>
                      </label>
                    </td>
                    <td><span *ngIf="user.status != 2">{{user.registerno}}</span>
                      <input type="text" class="form-input input-sm {{error['regno']}}" *ngIf="user.status==2" [(ngModel)]="user.registerno" [maxlength]="10" [minlength]="10" toUppercase/>
                    </td>
                    <td><span *ngIf="user.status != 2">{{user.epassno}}</span>
                      <input type="text" class="form-input input-sm {{error['epassno']}}" *ngIf="user.status==2" [(ngModel)]="user.epassno" [maxlength]="12" [minlength]="8" toUppercase/>
                    </td>
                    <td><span *ngIf="user.status != 2">{{user.lastname}}</span>
                      <input type="text" class="form-input input-sm {{error['lastname']}}" *ngIf="user.status==2" (keypress)="user.fgn ? isLatin($event) : isCyrillic($event)" [(ngModel)]="user.lastname"  toUppercase/>
                    </td>
                    <td><span *ngIf="user.status != 2">{{user.firstname}}</span>
                      <input type="text" class="form-input input-sm {{error['firstname']}}" *ngIf="user.status==2" (keypress)="user.fgn ? isLatin($event) : isCyrillic($event)" [(ngModel)]="user.firstname" toUppercase/>
                    </td>
                    <td><span *ngIf="user.status != 2">{{user.fgn == 1 ? user.age : checkAge(user.registerno)}}</span>

                      <input type="number" class="form-input input-sm {{error['age']}}" *ngIf="user.status==2 && user.fgn" [(ngModel)]="user.age" placeholder="Нас"/>

                    </td>
                    <td><span *ngIf="user.status != 2">{{user.phone}}</span>
                      <input type="text" class="form-input input-sm" *ngIf="user.status==2" [(ngModel)]="user.phone" [maxlength]="12" [minlength]="8" toUppercase/>
                    </td>
                    <td><span *ngIf="user.status != 2">{{user.email}}</span>
                      <input type="text" class="form-input input-sm {{error['email']}}" *ngIf="user.status==2" [(ngModel)]="user.email"/>
                    </td>
                    <td>{{user.created_date | dateAsAgo}}</td>
                    <td>
                      <a href="{{page}}#{{modal}}" *ngIf="user.status != 2" class="btn btn-sm btn-primary"  (click)="viewUser(user)"><i class="icon icon-apps"></i></a>
                      <button class="btn btn-sm btn-primary ml-2" *ngIf="user.status == 1" disabled data-tooltip="Аппликейшн ашигладаг"><i class="icon icon-people" alt="Апп ашигладаг"></i></button>

                      <button class="btn btn-sm btn-primary" *ngIf="user.status == 2" (click)="confirmUser(user)"><i class="icon icon-check"></i></button>
                      <button class="btn btn-sm btn-error ml-2" *ngIf="user.status == 2" (click)="cancelUser0(user)"><i class="icon icon-cross"></i></button>

                      <button class="btn btn-sm btn-primary ml-2" *ngIf="user.status == 0" (click)="editUser(user)"><i class="icon icon-edit"></i></button>
                      <button class="btn btn-sm btn-error ml-2" *ngIf="user.status == 0" (click)="deleteUser(user)"><i class="icon icon-delete"></i></button>
                      <button class="btn btn-sm btn-primary ml-2" *ngIf="selGroupId > 0" (click)="selectUser(user); addToGroup(selGroupId);">
                        <i class="icon icon-plus"></i>
                      </button>
                      <button class="btn btn-sm btn-primary ml-2" *ngIf="seeGroupId > 0" (click)="checkUser(user); removeToGroup(seeGroupId);">
                        <i class="icon icon-minus"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="4">
                      <select class="form-select select-sm" style="max-width: 100%;" style="width: 50px;" (change)="getUsers(pageNum)" [(ngModel)]="pageNum">
                        <option *ngFor="let item of pages" value="{{item.page-1}}">{{item.page}}</option>
                      </select>
                    </td>
                    <td colspan="4">
                      Нийт : {{users.rows}} мөр
                    </td>
                  </tr>
                </tfoot>
              </table>

          </div>
        </div>
      </div>
    </div>

    <div class="modal modal-md" id="{{modal}}">
      <a href="{{page}}#close"
        class="modal-overlay"
        aria-label="Close"></a>
      <div class="modal-container">
        <div class="modal-header">
          <a
            href="{{page}}#close"
            class="btn btn-clear float-right"
            aria-label="Close"
          ></a>
          <div class="modal-title h5">Даатгуулагчийн мэдээлэл</div>
        </div>
        <div class="modal-body" style="min-height: 370px;">
          <div class="columns">
            <div class="panel column col-12">
              <div class="panel-header text-center">

              </div>
              <nav class="panel-nav">
                <ul class="tab tab-block">
                  <li class="tab-item {{userTab==1?'active':''}}"><a href="{{page}}#{{modal}}" (click)="userTab=1;">Бүртгэл</a></li>
                  <li class="tab-item {{userTab==2?'active':''}}" (click)="getUserInvoice(2)"><a href="{{page}}#{{modal}}">Нэхэмжлэх</a></li>
                  <li class="tab-item {{userTab==3?'active':''}}" (click)="getUserInvoice(3)"><a href="{{page}}#{{modal}}">Баталгаажсан</a></li>
                </ul>
              </nav>
              <div class="panel-body text-small" *ngIf="userTab==1" style="min-height: 450px;">
                <br>
                <div class="tile tile-centered mt-2">
                  <div class="tile-content">
                    <div class="tile-title text-bold">Регистр дугаар</div>
                    <div class="tile-subtitle">{{selUser.registerno}}</div>
                  </div>
<!--                  <div class="tile-action">-->
<!--                    <button class="btn btn-link btn-action btn-lg tooltip tooltip-left" data-tooltip="Edit E-mail"><i class="icon icon-edit"></i></button>-->
<!--                  </div>-->
                </div>
                <div class="tile tile-centered mt-2">
                  <div class="tile-content">
                    <div class="tile-title text-bold">Гадаад пасспорт дугаар</div>
                    <div class="tile-subtitle">{{selUser.epassno}}</div>
                  </div>
                </div>
                <div class="tile tile-centered mt-2">
                  <div class="tile-content">
                    <div class="tile-title text-bold">Бүтэн нэр</div>
                    <div class="tile-subtitle">{{selUser.lastname}} {{user.firstname}}</div>
                  </div>
                </div>
                <div class="tile tile-centered mt-2">
                  <div class="tile-content">
                    <div class="tile-title text-bold">Утасны дугаар</div>
                    <div class="tile-subtitle">{{selUser.phone}}</div>
                  </div>
                </div>
                <div class="tile tile-centered mt-2">
                  <div class="tile-content">
                    <div class="tile-title text-bold">Цахим шуудан</div>
                    <div class="tile-subtitle">{{selUser.email}}</div>
                  </div>
                </div>
              </div>
              <div class="panel-body text-small" *ngIf="userTab==2" style="min-height: 450px; padding-top: 15px;">
                <br>
                <div class="filter">
                  <div class="filter-body columns">
                    <div class="column col-6 filter-item" *ngFor="let invoice of userInvoices | callback:filterStatus1">
                      <div class="card bg-secondary">
                        <div class="card-header text-center">
                          <figure class="avatar bg-secondary">
                            <img src="assets/img/shield_blue.png">
                          </figure>
                        </div>
                        <div class="card-body">
                          <div class="tile-title text-center text-small">{{invoice.serviceName}}</div>
                          <div class="tile-subtitle text-center text-bold">{{invoice.finalAmount | currency:'$'}}</div>
                          <div class="tile-title text-center text-small">{{invoice.groupName}} - {{invoice.agentName}}</div>
                        </div>
                        <div class="card-footer">
                          <span class="text-small text-gray float-left">{{invoice.createdDate | dateAsAgo}}</span>
                          <a *ngIf="invoice.invoiceNo" class="btn btn-sm btn-primary float-right" href="/list?invoiceNo={{invoice.invoiceNo}}"><i class="icon icon-arrow-right"></i></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="panel-body text-small" *ngIf="userTab==3" style="min-height: 450px; padding-top: 15px;">
                <div class="filter">
                  <div class="filter-body columns">
                    <div class="column col-6 filter-item" *ngFor="let invoice of userInvoices | callback:filterStatus2">
                      <div class="card bg-primary">
                        <div class="card-header text-center">
                          <figure class="avatar bg-secondary">
                            <img src="assets/img/shield_blue.png">
                          </figure>
                        </div>
                        <div class="card-body">
                          <div class="tile-title text-center text-small">{{invoice.serviceName}}</div>
                          <div class="tile-subtitle text-small text-center text-bold">{{invoice.finalAmount | currency:'$'}}</div>
                          <div class="tile-title text-center text-small">{{invoice.groupName}} - {{invoice.agentName}}</div>
                        </div>
                        <div class="card-footer">
                          <span class="text-small text-gray float-left">{{invoice.createdDate | dateAsAgo}}</span>
                          <a *ngIf="invoice.invoiceNo" class="btn btn-sm btn-success float-right" href="p/v1/cert/{{invoice.invoiceNo}}/{{selUser.id}}" target="_blank"><i class="icon icon-people"></i></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="panel-footer">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </body>
</html>

import {Component, Injectable, Pipe, PipeTransform} from '@angular/core';
import {HttpClient, HttpEventType, HttpHeaders, HttpResponse} from '@angular/common/http';
import { AppComponent } from './app.component';
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'list-root',
  templateUrl: './list.component.html'
})
export class ListComponent extends AppComponent {
  public numbers: any;
  public insureType: any = 2;
  public groupUsers: any;
  public searchDate: any = new Date();
  public mode:any = 'disabled';
  public range: any;
  public modal: any;
  public selectedXls: any;
  public selectedFiles: any;
  public progressInfos: any;
  public files: any = [];
  public vehicleTypes: any = [];

  ngOnInit() {
    let yourDate = new Date();
    this.modal = new Date().getTime();
    this.searchDate = yourDate.toISOString().split('T')[0];
    this.pageId = 0;
    this.firstload = false;
    this.status = 1;
    this.selectedServiceId = 1;
    this.getProfile();
    this.getOrgs();
    this.getAgents();
    this.getServices();
    this.getRate();
    this.getServiceCombined();
    this.loadAges();
    this.loadAgeLimits();
    this.selService = {
      name: '',
      packageList: []
    };
    this.invoice = {
      invoiceNo: '',
      unit: 0,
      createDate: 0
    };

    this.properties = [{
      type: '',
      regno: '',
      amount: 0
    }];

    this.route.queryParams.subscribe((params) => {
      let invoiceNo = params['invoiceNo'];
      if (invoiceNo) {
        this.viewInvoice({invoiceNo: invoiceNo});
        this.location.go(this.page + '#' + this.modal);
      }
    });

    this.getInvoices(this.pageNum);
    this.getCountries();
    this.createInvoice(1);
    this.pushAges(this.age3, 3);
    this.initRefrences();
    this.initVechicles();
  }

  initVechicles() {
    this.vehicleTypes = [ {
      id: 1,
      name: 'Авто тээврийн хэрэгсэл'
    }, {
      id: 1,
      name: 'Хүнд даацын'
    }, {
      id: 1,
      name: 'Машин механизм'
    }];
  }

  proctectFilter(items: any[]): any[] {
    return items.filter(item => ((item.packageId < 6 || (item.packageId>=6 && (item.regions.indexOf(this.selItemType) != -1)))));
  }

  combineFilter(items: any[]): any[] {
    return items.filter(item => (('A'+item.type == this.selItemType)));
  }

  selectCombine(combine: any) {
    for (let i = 0; i < this.serviceCombined.length; i++)
      this.serviceCombined[i].state = 0;
    combine.state = 1;

    this.newInvoice.protectId = combine.combineId;
  }

  setInsureType() {
    if (this.insureType == 2) {
      this.owners[0].rd = this.profile.orgDto.regno;
      this.owners[0].fn = this.profile.orgDto.name;
      this.owners[0].em = this.profile.orgDto.email;
      this.owners[0].ph = this.profile.orgDto.phone;
    } else {
      this.owners[0].rd = '';
      this.owners[0].fn = '';
      this.owners[0].em = '';
      this.owners[0].ph = '';
    }
  }

  findUser(age: any, type: any) {
    if (age.rd.length != 10) return;
    const url = 'p/userList/search/'+age.rd;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+this.token
    });

    this.http.get(url, {headers: headers}).subscribe((res) => {
      this.matched = res;
      if (this.matched.rows > 0) {
        age.userId = this.matched.userDtoList[0].id;
        age.fn = this.matched.userDtoList[0].firstname;
        age.ln = this.matched.userDtoList[0].lastname;
        age.ph = this.matched.userDtoList[0].phone;
        age.em = this.matched.userDtoList[0].email;
        age.ep = this.matched.userDtoList[0].epassno;
      }
      this.loading = false;
    }, error => {
      this.loading = false;
      this.toastr.error(error.statusText, 'Алдаа !');
    });
  }

  resetRegion() {
    if (this.selService.id == 3) {
      this.newInvoice.visaType = 0;
    }
  }

  assetSelect(asset: any) {
    for (let i = 0; i < this.selService.packageList.length; i++) {
      let protects = this.selService.packageList[i].protectList;
      for (let j = 0; j < protects.length; j++) {
        if (protects[j].regions.indexOf(this.selItemType) != -1)
          protects[j].state = 0;
      }
    }
    if (this.selService.id == 3) {
      if (asset.id == 6) {
        for (let i = 0; i < this.selService.packageList.length; i++) {
          let protects = this.selService.packageList[i].protectList;
          for (let j = 0; j < protects.length; j++) {
            if (protects[j].regions.indexOf(this.selItemType) != -1)
              protects[j].state = 1;
          }
        }
      } else
      if (asset.id == 7) {
        for (let i = 0; i < this.selService.packageList.length; i++) {
          let protects = this.selService.packageList[i].protectList;
          for (let j = 0; j < protects.length; j++) {
            if (protects[j].regions.indexOf(this.selItemType) != -1 && (protects[j].id == 70 || protects[j].id == 67))
              protects[j].state = 1;
          }
        }
      } else
      if (asset.id == 8) {
        for (let i = 0; i < this.selService.packageList.length; i++) {
          let protects = this.selService.packageList[i].protectList;
          for (let j = 0; j < protects.length; j++) {
            if (protects[j].regions.indexOf(this.selItemType) != -1 && (protects[j].id == 70))
              protects[j].state = 1;
          }
        }
      }
    }
  }

  onUploadPics(idx: any, file: File) {
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      'Authorization': 'Bearer '+this.token
    })

    this.progressInfos[idx] = { value: 0, fileName: file.name };
    let propNo = this.newInvoice.id > 0 ? this.newInvoice.properties[0].regno : this.properties[0].regno;
    this.loading = true;
    this.uploadService.uploadImg(this.selectedFiles[idx], propNo, headers).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {
        } else if (event instanceof HttpResponse) {
          if (event.body.code == 0) {
            this.loading = false;
            this.toastr.success(event.body.msg, 'Ok');
            this.getPropertyPics(propNo);
          }
          else
            this.toastr.error(event.body.msg, 'Алдаа');
        }
      },
      err => {
        this.aploading = false;
        this.loading = false;
        this.toastr.error(err.statusText, 'Алдаа !');
      });
  }

  onUploadXls(files : any) {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer '+this.token
    });
    this.aploading = true;
    this.uploadService.uploadAjd(files, headers).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {
        } else if (event instanceof HttpResponse) {
          if (event.body.code == 0) {
            this.plates = event.body.list;
            this.properties = [];
            this.toastr.success(event.body.msg, 'Ok');
          }
          else
            this.toastr.error(event.body.msg, 'Алдаа');
        }
      },
      err => {
        this.aploading = false;
        this.loading = false;
        this.toastr.error(err.statusText, 'Алдаа !');
      });
  }

  clearXls() {
    this.selectedXls = [];
    this.plates = [];
  }

  selectXlsFile(event: any) {
    this.selectedXls = event.target.files;
  }

  uploadXls() {
    if (this.selectedXls.length == 0) {
      this.toastr.error('Файл сонгоогүй байна !', 'Алдаа !');
      return;
    }
    this.onUploadXls(this.selectedXls[0]);
  }

  selectImageFiles(event: any) {
    this.progressInfos = [];
    this.selectedFiles = event.target.files;
  }

  uploadPics() {
    if (this.selectedFiles.length == 0) {
      this.toastr.error('Зураг сонгоогүй байна !', 'Алдаа !');
      return;
    }
    for (let i = 0; i < this.selectedFiles.length; i++) {
      this.onUploadPics(i, this.selectedFiles[i]);
    }
  }

  override getPropertyPics(propNo: any) {
    const url = 'p/propertyPics/' + propNo;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+this.token
    })
    this.loading = true;
    this.http.get(url, {headers: headers}).subscribe((res) => {
      this.fileInfos = res;
      this.loading = false;
    }, error => {
      //this.logout();
      this.loading = false;
      this.fileInfos = [];
    });
  }

  openImage(img: any) {
    window.open('p/img/'+img.url);
  }

  deleteImage(img: any) {
    if (confirm('Устгах уу ?')) {
      if (this.loading) return;
      const url = 'p/image/delete/' + img.id;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })
      this.loading = true;
      this.http.delete(url, {headers: headers}).subscribe((res) => {
        this.getPropertyPics(this.propNo);
        this.loading = false;
      }, error => {
        this.loading = false;
        // this.logout();
      });
    }
  }

  setPage(pageNum: any) {
    this.pageNum = pageNum;
  }

  listModeChange(mode: any) {
    this.listMode = mode;
  }
  setOrgId(orgId: any) {
    this.selOrgId = orgId;
    this.pageNum = 0;
    this.getInvoices(this.pageNum);
  }

  addAjdOwner() {
    this.owners.push({
      rd: '',
      em: '',
      dl: '',
      ln: '',
      fn: '',
      ph: '',
      tr: 1,
      am: 0,
      ownerType: 2
    });
  }

  removeAjdOwner(item: any) {
    for (let i = 0; i < this.owners.length; i++) {
      if (this.owners[i] == item)
        this.owners.splice(i, 1);
    }
  }

  groupSelect() {
    if (this.newInvoice.groupId > 0) {
      const url = 'p/userList/group/'+this.newInvoice.groupId;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+this.token
      })

      this.gloading = true;
      this.age2 = [];
      this.age1 = [];
      this.age3 = [];
      this.age4 = [];
      this.age5 = [];
      this.age12 = [];
      this.http.get(url, {headers: headers}).subscribe((res) => {
        this.groupUsers = res;
        this.gloading = false;
        for (let i = 0; i < this.groupUsers.length; i++) {
          var u = this.groupUsers[i];
          var ag = u.age;
          var ageType = u.fgn == 0 ? this.checkAgeType(u.registerno) : this.rangeAgeType(u.age);
          if (ageType == 1)
            this.age1.push({
              fn: u.firstname,
              ln: u.lastname,
              rd: u.registerno,
              ep: u.epassno,
              ph: u.phone,
              em: u.email,
              userId: u.id,
              type: 1,
              status: u.status,
              age: ag
            });
          if (ageType == 2)
            this.age2.push({
              fn: u.firstname,
              ln: u.lastname,
              rd: u.registerno,
              ep: u.epassno,
              ph: u.phone,
              em: u.email,
              userId: u.id,
              type: 2,
              status: u.status,
              age: ag
            });
          if (ageType == 3)
            this.age3.push({
              fn: u.firstname,
              ln: u.lastname,
              rd: u.registerno,
              ep: u.epassno,
              ph: u.phone,
              em: u.email,
              userId: u.id,
              type: 3,
              status: u.status,
              age: ag
            });
          if (ageType == 4)
            this.age4.push({
              fn: u.firstname,
              ln: u.lastname,
              rd: u.registerno,
              ep: u.epassno,
              ph: u.phone,
              em: u.email,
              userId: u.id,
              type: 4,
              status: u.status,
              age: ag
            });
          if (ageType == 5)
            this.age5.push({
              fn: u.firstname,
              ln: u.lastname,
              rd: u.registerno,
              ep: u.epassno,
              ph: u.phone,
              em: u.email,
              userId: u.id,
              type: 5,
              status: u.status,
              age: ag
            });

          if (ageType == 12)
            this.age12.push({
              fn: u.firstname,
              ln: u.lastname,
              rd: u.registerno,
              ep: u.epassno,
              ph: u.phone,
              em: u.email,
              userId: u.id,
              type: 12,
              status: u.status,
              age: ag
            });
        }

        this.checkInvoice();
      }, error => {
        // this.logout();
      });
    }
  }

  checkAgeType(regno: any) {
    let age = this.checkAgeDate(regno, this.addDays(new Date(), this.diffDays() - 1));
    if (age >= 0 && age < 2) return 1;
    if (age >= 2 && age < 16) return 2;
    if (age >= 16 && age < 60) return 3;
    if (age >= 60 && age < 70) return 4;
    if (age >= 70 && age < 80) return 5;
    if (age >= 80 && age < 100) return 12;

    return -1;
  }

  rangeAgeType(age: any) {
    if (age >= 0 && age < 2) return 1;
    if (age >= 2 && age < 16) return 2;
    if (age >= 16 && age < 60) return 3;
    if (age >= 60 && age < 70) return 4;
    if (age >= 70 && age < 80) return 5;
    if (age >= 80 && age < 100) return 12;

    return -1;
  }

}


import {Component, OnInit, ViewChild} from '@angular/core';
import { AppComponent } from './app.component';
import { ActivatedRoute } from '@angular/router';
import {HttpClient, HttpEventType, HttpHeaders, HttpResponse} from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {Observable} from "rxjs"
import {CallbackPipe} from "./CallbackPipe";

@Component({
  selector: 'user-root',
  templateUrl: './user.component.html',
  styleUrls: ['./app.component.css'],
})
export class UserComponent extends AppComponent implements OnInit {
  public files: any = [];
  public group:any;
  public search: any = '';
  public pageSel: any = 1;
  public selUsers:any = [];
  public groupUsers:any = [];
  public selGroupId: any = 0;
  public seeGroupId: any = 0;
  public agroupId: any = 0;
  public selUser: any;
  public userTab: any = 1;
  public userInvoices: any=[];
  public modal: any;


  ngOnInit() {
    this.page = '/user';
    this.modal = new Date().getTime();
    this.getUsers(this.pageNum);
    this.getGroups();
    this.newUser();
    this.users = {
      userDtoList: []
    };
  }

  isCyrillic(event: any) {
    const pattern = /[А-Яа-яӨөҮү\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  isLatin(event: any) {

  }

  filterStatus1(user: any) {
    return user.payStatus == 1;
  }

  filterStatus2(user: any) {
    return user.payStatus == 2;
  }

  isPhone(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  newUser() {
    this.error = [];
    this.selUsers = [];
    this.user = {
      regno: '',
      firstname: '',
      lastname: '',
      email: '',
      fgn: 0,
      phone: '',
      role: 'ROLE_USER'
    };

    this.selUser = {
      regno: '',
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      role: 'ROLE_USER'
    };

    this.group = {
      name: '',
      status: 1
    }
  }

  setPage(page:any) {
    if (page == -1) return;
    this.pageNum = page;
  }

  getUsers(pageNum:any) {
    this.loading = true;
    const url = 'p/userList/page/'+(pageNum);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+this.token
    });

    this.pages = [];
    this.http.get(url, {headers: headers}).subscribe((res) => {
      this.users = res;
      for (let i = 0; i < this.users.rows / this.users.size; i++)
        this.pages.push({page: i+1});

      this.loading = false;
    }, error => {
      this.loading = false;
      this.toastr.error(error.statusText, 'Алдаа !');
    });
  }

  getUserInvoice(tab: any) {
    this.userTab = tab;
  }

  getUsersLike() {
    if (this.search == '') {
      this.getUsers(this.pageNum);
      return;
    }
    this.loading = true;
    const url = 'p/userList/search/'+this.search;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+this.token
    });

    this.http.get(url, {headers: headers}).subscribe((res) => {
      this.users = res;
      this.loading = false;
    }, error => {
      this.loading = false;
      this.toastr.error(error.statusText, 'Алдаа !');
    });
  }


  deleteUser(user: any) {
    if (confirm('Устгах уу ?')) {
      this.loading = true;
      const url = 'p/user/delete/' + user.id;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get(url, {headers: headers}).subscribe((res) => {
        if (this.seeGroupId > 0)
          this.seeGroup(this.seeGroupId);
        else
          this.getUsers(this.pageNum);
      }, error => {
        this.loading = false;
        this.toastr.error(error.statusText, 'Алдаа !');
      });
    }
  }
  editUser(user: any) {
    this.editing = true;
    user.status = 2;
  }

  cancelUser(user: any) {
    this.editing = false;
    user.status = 1;
    this.getUsers(this.pageNum);
  }

  cancelUser0(user: any) {
    this.editing = false;
    user.status = 0;
    this.getUsers(this.pageNum);
  }

  confirmUser(user: any) {
    user.fgn = user.fgn == true ? 1:0;
    user.registerno = user.registerno.replaceAll(' ', '');
    user.registerno = user.registerno.trim();
    user.email = user.email ? user.email.trim() : '';

    if (user.fgn == 0) {
      if (user.registerno.length != 10) {
        this.error['regno'] = 'is-error';
        return;
      }

      if (!this.regexRegno(user.registerno)) {
        this.toastr.error('Регистрын дугаарыг кирил үсгээр бичнэ үү !', 'Алдаа !');
        this.error['regno'] = 'is-error';
        return;
      }
    }

    if (user.lastname.length == 0) {
      this.error['lastname'] = 'is-error';
      return;
    }

    if (user.firstname.length == 0) {
      this.error['firstname'] = 'is-error';
      return;
    }

    if (user.epassno.length < 7) {
      this.error['epassno'] = 'is-error';
      return;
    }

    if (user.email.length > 0 && !this.regexEmail(user.email)) {
      this.error['email'] = 'is-error';
      return;
    }
    this.error = [];
    this.loading = true;
    const url = 'p/user/update';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    })
    this.http.post(url, user,{headers: headers}).subscribe((res) => {
      this.loading = false;
      this.editing = false;
      this.toastr.success('Амжилттай шинэчлэгдлээ !', 'OK');
      this.getUsers(this.pageNum);
    }, error => {
      this.loading = false;
      this.toastr.error(error.statusText, 'Алдаа !');
    });
  }


  registerUser() {
    this.user.regno = this.user.regno.replaceAll(' ', '');
    this.user.regno = this.user.regno.trim();
    this.user.email = this.user.email.trim();

    if (this.user.fgn == 0) {
      if (this.user.regno.length != 10) {
        this.error['regno'] = 'is-error';
        return;
      }

      if (!this.regexRegno(this.user.regno)) {
        this.error['regno'] = 'is-error';
        return;
      }
    }

    if (this.user.lastname.length == 0) {
      this.error['lastname'] = 'is-error';
      return;
    }

    if (this.user.firstname.length == 0) {
      this.error['firstname'] = 'is-error';
      return;
    }

    if (this.user.epassno.length < 7) {
      this.error['epassno'] = 'is-error';
      return;
    }

    if (this.user.email.length > 0 && !this.regexEmail(this.user.email)) {
      this.error['email'] = 'is-error';
      return;
    }

    this.gloading = true;
    const url = 'p/user/save';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    })
    if (this.seeGroupId > 0)
      this.user.groupId = this.seeGroupId;
    this.http.post(url, this.user,{headers: headers}).subscribe((res) => {
      this.gloading = false;
      if (this.seeGroupId > 0)
        this.seeGroup(this.seeGroupId);
      else
        this.getUsers(this.pageNum);
      if (this.user.id == -1) {
        this.toastr.error('Бүртгэгдсэн байна !', 'Алдаа !');
        return;
      } else
        this.toastr.success('Амжилттай бүртгэгдлээ !', 'OK');
      this.newUser();
    }, error => {
      this.gloading = false;
      this.loading = false;
      this.toastr.error(error.statusText, 'Алдаа !');
    });
  }

  onUpload() {
    if (!this.group.name) {
      this.toastr.error('Бүлгийн нэр оруулна уу !', 'Алдаа');
      return;
    }
    const headers = new HttpHeaders({
      'Authorization': 'Bearer '+this.token
    });
    this.aploading = true;
    this.uploadService.upload(this.files.length > 0 ? this.files[0]:null, this.group.name, headers).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {
        } else if (event instanceof HttpResponse) {
          if (event.body.code == 0) {
            this.toastr.success(event.body.msg, 'Ok');
            this.getGroups();
            this.group.name = '';
          }
          else
            this.toastr.error(event.body.msg, 'Алдаа');
        }
      },
      err => {
        this.aploading = false;
        this.loading = false;
        this.toastr.error(err.statusText, 'Алдаа !');
      });
  }

  selectFile(event: any) {
    this.files = event.target.files;
  }

  createGroup() {
    this.aploading = true;
    const url = 'p/group';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    })
    this.http.post(url, this.group,{headers: headers}).subscribe((res) => {
      this.getGroups();
      this.group.name = '';
    }, error => {
      this.aploading = false;
      this.loading = false;
      this.toastr.error(error.statusText, 'Алдаа !');
    });
  }

  viewUser(user: any) {
    this.userTab = 1;
    this.selUser = user;
    const url = 'p/v1/invoice/user/'+this.selUser.registerno;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+this.token
    })

    this.http.get(url, {headers: headers}).subscribe((res) => {
      this.userInvoices = res;
      this.invoiceLoading = false;
    }, error => {
      this.invoiceLoading = false;
      this.toastr.error(error.statusText, 'Алдаа !');
    });
  }

  selectUser(user: any) {
    for (let i = 0; i < this.selUsers.length; i++) {
      if (this.selUsers[i].userId == user.id) {
        this.selUsers.splice(i, 1);
        return;
      }
    }
    if (user.checked) {
      this.selUsers.push({
        userId: user.id
      });
    }
  }

  checkUser(user: any) {
    for (let i = 0; i < this.selUsers.length; i++) {
      if (this.selUsers[i].userId == user.id) {
        this.selUsers.splice(i, 1);
        return;
      }
    }
    this.selUsers.push({
      userId: user.id
    });
  }

  removeGroup(groupId: any) {
    if (confirm('Устгах уу ?')) {
      this.loading = true;
      const url = 'p/group/delete/' + groupId;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.post(url, this.selUsers, {headers: headers}).subscribe((res) => {
        this.getGroups();
      }, error => {
        this.loading = false;
        this.toastr.error(error.statusText, 'Алдаа !');
      });
    }
  }

  cancelToGroup(groupId: any) {
    this.selUsers = [];
    this.getUsers(this.pageNum);
  }

  addToGroup(groupId: any) {
    if (groupId == 0) {
      this.toastr.error('Бүлэг сонгоогүй байна !', 'Алдаа !');
      return;
    }
    if (this.selUsers.length == 0) {
      this.toastr.error('Харилцагч сонгоогүй байна !', 'Алдаа !');
      return;
    }
    for (let i = 0; i < this.selUsers.length; i++) {
      this.selUsers[i].groupId = groupId;
    }

    this.loading = true;
    const url = 'p/group/add';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+this.token
    })

    this.http.post(url, this.selUsers, {headers: headers}).subscribe((res) => {
      // this.getGroups();
      this.loading = false;
      this.toastr.success('Амжилттай !', 'Ok');
      this.selUsers = [];
      this.seeGroup(groupId);
    }, error => {
      this.toastr.error(error.statusText, 'Алдаа !');
      this.loading = false;
    });
  }

  removeToGroup(groupId: any) {
    if (confirm('Бүлэгээс хасах уу !')) {
      if (this.selUsers.length == 0) {
        this.toastr.error('Харилцагч сонгоогүй байна !', 'Алдаа !');
        return;
      }
      for (let i = 0; i < this.selUsers.length; i++) {
        this.selUsers[i].groupId = groupId;
      }

      this.loading = true;
      this.gloading = true;
      const url = 'p/groupUsers/delete';
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.post(url, this.selUsers, {headers: headers}).subscribe((res) => {
        this.loading = false;
        this.selUsers = [];
        this.seeGroup(groupId);
      }, error => {
        this.loading = false;
        this.toastr.error(error.statusText, 'Алдаа !');
      });
    }
  }

  isGrouped(user: any) {
    for (let i = 0; i < this.groupUsers.length; i++) {
      let g = this.groupUsers[i];
      if (g.userId == user.id)
        return true;
    }
    return false;
  }

  selGroup(group:any) {
    this.selGroupId = group.id;
    this.seeGroupId = 0;
    const url = 'p/groupUsers/'+this.selGroupId;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+this.token
    })

    this.http.get(url, {headers: headers}).subscribe((res) => {
      this.groupUsers = res;
      this.loading = false;
    }, error => {
      this.loading = false;
      this.toastr.error(error.statusText, 'Алдаа !');
    });
  }

  deleteGroup(group: any) {

  }

  seeGroup(groupId:any) {
    this.seeGroupId = groupId;
    this.selGroupId = 0;
    if (groupId == 0) {
      this.getUsers(this.pageNum);
      return;
    }
    this.loading = true;
    const url = 'p/userList/group/'+groupId+'/'+this.pageNum;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+this.token
    })

    this.http.get(url, {headers: headers}).subscribe((res) => {
      this.users = res;
      this.loading = false;
      this.gloading = false;
    }, error => {
      this.loading = false;
      this.gloading = false;
      this.toastr.error(error.statusText, 'Алдаа !');
    });
  }
}

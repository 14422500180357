<!DOCTYPE html>
<html
  lang="en"
  xmlns:th="http://www.thymeleaf.org"
  xmlns:span="http://www.w3.org/1999/xhtml"
>
  <head>
    <meta charset="UTF-8" />
    <title>D-Portal</title>
    <meta name="viewport" content="width=device-width, initial-scale=1" />
  </head>
  <body>
    <div class="docs-container off-canvas off-canvas-sidebar-show">
      <div class="docs-navbar">
        <a class="off-canvas-toggle btn btn-link btn-action" href="{{page}}#sidebar"
          ><i class="icon icon-menu"></i
        ></a>
      </div>
      <div class="docs-sidebar off-canvas-sidebar" id="sidebar">
        <div class="docs-brand">
          <a class="docs-logo" href="/home">
            <img src="assets/img/letter-d.png" style="width: 24px;" alt="daatguul">
            <label class="label label-secondary text-bold ml-1">PORTAL</label></a>
        </div>
        <menu-root></menu-root>
      </div>
      <a class="off-canvas-overlay" href="{{page}}#close"></a>
      <div class="off-canvas-content">
        <div class="docs-content" id="content">
          <h5 class="s-title">
            Нууц үг солих<a class="anchor" href="{{page}}#forms" aria-hidden="true">#</a>
          </h5>

          <div class="docs-demo columns">
            <div class="column col-3 col-sm-3">

              <form class="form-horizontal" action="{{page}}#forms">
                <div class="form-group">
                  <label class="form-label label-sm" for="old">Хуучин нууц үг</label>
                  <input [ngModelOptions]="{ standalone: true }" class="form-input input-sm"
                         type="password" id="old" #old="ngModel" [(ngModel)]="pass.old" autocomplete="off"
                         placeholder="">
                </div>
                <div class="form-group">
                  <label class="form-label label-sm" for="newpass">Шинэ нууц үг</label>
                  <input [ngModelOptions]="{ standalone: true }"  class="form-input input-sm"
                         #password="ngModel" type="password" id="newpass"
                         [(ngModel)]="pass.newpass" autocomplete="off" placeholder="">
                </div>
                <div class="form-group">
                  <label class="form-label label-sm" for="repass">Дахин бичих</label>
                  <input [ngModelOptions]="{ standalone: true }"  class="form-input input-sm"
                         #password="ngModel" type="password" id="repass"
                         [(ngModel)]="pass.repass" autocomplete="off" placeholder="">
                </div>
                <div class="form-group">
                </div>
                <div class="form-group">
                  <button
                    class="btn btn-primary btn-sm float-left"
                    type="submit"
                    (click)="resetPassword()">
                    Солих
                  </button>
                  <div
                    style="margin-left: 20px;"
                    class="loading loading-sm"
                    *ngIf="loading"></div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

  </body>
</html>
